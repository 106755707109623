import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { LocalCacheService } from '../../services/localCache.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  agentName: string = '';
  isPASUser: boolean = false;
  PASUrl: string = '';

  constructor(private globalService: GlobalService, private localCacheService: LocalCacheService) {
    this.PASUrl = environment.PASUrl;
  }
  ngOnInit(): void {
    var agentGuid = this.globalService.getQueryParamInfo('AgentGuid') || this.localCacheService.getSessionStorage('agentGuid');
    this.agentName = this.globalService.getQueryParamInfo('AgentName') || this.localCacheService.getSessionStorage('agentName');
    if (this.agentName && agentGuid) {
      this.isPASUser = true;
    }
  }

  clearSession(){
    sessionStorage.clear();
    location.href = this.PASUrl;
  }
}
