import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-refunds',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './refunds.component.html',
  styleUrl: './refunds.component.scss',
})
export class RefundsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Refunds  > World2Cover Travel Insurance');
    this.metaService.updateTag({
      name: 'description',
      content: `World2Cover travel insurance gives you 21 days cooling-off period to make sure your policy is the right policy for you.`,
    });
  }
}
