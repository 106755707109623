import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-how-to-claim',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './how-to-claim.component.html',
  styleUrl: './how-to-claim.component.scss',
})
export class HowToClaimComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Claims | World2Cover Australia');
    this.metaService.updateTag({
      name: 'description',
      content: `An Online claim can usually be lodged in less than 10 minutes. You can follow our step-by-step process to lodge your claim and then follow your claim status. World2Cover recommends travel insurance policyholders lodge their claim as soon as possible within 30 days completing their trip.`,
    });
  }
}
