import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
})
export class ErrorPageComponent {
  public errorMessage: string | any;
  public returnPage: string | any;
  constructor(private router: Router, private titleService: Title) {
    this.titleService.setTitle('Not found > World2Cover Travel Insurance');
  }

  ngOnInit(): void {
    this.errorMessage = '';
    sessionStorage.clear();
  }

  goToStepOne(): void {
    this.router.navigate(['']);
  }
}
