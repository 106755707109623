import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { PDSService } from '../../../services/pds.service';
import { LocalCacheService } from '../../../services/localCache.service';

@Component({
  selector: 'app-cruise-travel-insurance',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './cruise-travel-insurance.component.html',
  styleUrl: './cruise-travel-insurance.component.scss',
})
export class CruiseTravelInsuranceComponent {
  tmdLink: string = '';
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private pdsService: PDSService,
    private localCacheService: LocalCacheService,
  ) {
    this.titleService.setTitle(
      'Cruise Travel Insurance | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content:
        'Cruise insurance can provide added peace of mind to travellers in covering a variety of unforeseen circumstances. Whether travelling internationally or staying in  Australia, World2Cover travel insurance has a policy to fit most needs. Get a quote today to check out your options!',
    });
  }

  ngOnInit() {
    const currentLatestTMD =
      this.localCacheService.getSessionStorage('lastestTMD');
    if (!currentLatestTMD) {
      this.pdsService.getLatestTMD().subscribe((data) => {
        if (data && data.tmd && data.tmd.length > 0) {
          data.tmd.forEach((obj: any) => {
            if (obj.isActive) {
              this.tmdLink = obj.url;
            }
          });
        }
      });
    } else {
      this.tmdLink = currentLatestTMD?.url;
    }
  }
}
