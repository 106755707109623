import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';
import { APP_INITIALIZER } from '@angular/core';

import { routerOptions, routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from '../../services/in-memory-data.service';
import { EnvironmentService } from '../../../app/services/environment.service';
import { httpHeaderInterceptor } from '../../interceptors/http-header.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([httpHeaderInterceptor])),
    importProvidersFrom([
      RouterModule.forRoot(routes, routerOptions)  // Enables scroll restoration
    ]),
    provideAnimationsAsync(),
    provideHttpClient(),
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    importProvidersFrom(
      HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
        dataEncapsulation: false,
        rootPath: 'api/',
        passThruUnknownUrl: true,
      }),
    ),
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EnvironmentService],
      multi: true,
    }
  ],
};

export function initializeApp(environmentService: EnvironmentService) {
  return (): Promise<void> => {
    return new Promise((resolve) => {
      environmentService.getEnvConfig();
      resolve();
    });
  };
}
