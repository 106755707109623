import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-travel-alerts',
  standalone: true,
  imports: [
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './travel-alerts.component.html',
  styleUrl: './travel-alerts.component.scss',
})
export class TravelAlertsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle(
      'Travel Alerts | World2Cover Travel Insurance Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content: `World2Cover travel insurance alerts for smart travellers in Australia`,
    });
  }
}
