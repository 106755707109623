import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { WordingService } from '../../../services/wording.service';
import { Utility } from '../../../utils/utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-information-template',
  standalone: true,
  imports: [FooterComponent, CommonHeaderComponent, CommonModule],
  templateUrl: './information-template.component.html',
  styleUrl: './information-template.component.scss',
})
export class InformationTemplateComponent {
  constructor(
    private wordingService: WordingService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
  ) {}
  pageTitle: string = '';
  pageContent: SafeHtml = '';
  pageTitleImg: string = '';

  ngOnInit() {
    this.wordingService
      .getInformationTemplateWordings()
      .subscribe((information) => {
        console.log(information);
        const pageName = Utility.getUrlParameter('pageName');
        const pageInformation = information.find(
          (info) => info.pageName === pageName,
        );
        this.pageTitle = pageInformation?.pageTitle || '';
        this.titleService.setTitle(this.pageTitle);
        this.pageContent =
          this.sanitizer.bypassSecurityTrustHtml(
            pageInformation?.pageContent,
          ) || '';
        this.pageTitleImg = pageInformation?.pageTitleImg || '';
      });
  }
}
