import { Component } from '@angular/core';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Contact Us | World2Cover Australia');
    this.metaService.updateTag({
      name: 'description',
      content: `Contact Us for any question or feedback. Our travel insurance experts' team will help you for your quote, purchase or policy update as per lodging a claim. Our 24/7 assistance phone number is available for any medical emergency. Compare and select the best travel insurance for you. Get a quote now!`,
    });
  }
}
