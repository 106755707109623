import { Component } from '@angular/core';
import { FooterComponent } from '../../footer/footer.component';
import { RouterLink } from '@angular/router';
import { SegmentService } from '../../../services/segment.service';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [FooterComponent, RouterLink],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
})
export class FaqComponent {
  constructor(private segmentService: SegmentService) {}

  toggleCollapse(event: Event, index: string): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById(index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.classList.toggle('collapsed', isExpanded);
      button.setAttribute('aria-expanded', (!isExpanded).toString());
    }
  }

  triggerSegmentEvent(field: string) {
    let eventNameTitle: any;
    switch (field) {
      case 'faqCovidPds':
        eventNameTitle = 'homepage_faq_covid19_pds';
        break;
      case 'faqInsuranceCoverPds1':
        eventNameTitle = 'homepage_faq_insurance_cover_pds_1';
        break;
      case 'faqInsuranceCoverPds2':
        eventNameTitle = 'homepage_faq_insurance_cover_pds_2';
        break;
      case 'faqInsuranceNotCoverPds1':
        eventNameTitle = 'homepage_faq_insurance_not_cover_pds_1';
        break;
      case 'faqInsuranceNotCoverPds2':
        eventNameTitle = 'homepage_faq_insurance_not_cover_pds_2';
        break;
    }

    const travelDetails = {
      eventName: eventNameTitle,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(travelDetails);
  }
}
