<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'TravelInsurance'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/ltx-travel-insurance-for-families-banner.png"
      alt="Travel Insurance for Families"
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Insurance for Families</h1>
      <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3 class="icon">Who is covered with family travel insurance?</h3>
            <p>
              World2Cover family travel insurance is tailored to cover families
              of any size, which includes a maximum of two adults plus their
              declared dependents (up to the age of 25 who are financially
              dependent, not working full time and travelling with you for your
              entire trip), per policy. Remember, any dependent must be listed
              on the Certificate of Insurance to be included on a family travel
              insurance policy. If you have already purchased a World2Cover
              travel insurance policy and forgot to include your dependents this
              can easily be fixed, please give us a call before you leave on
              <a href="tel:0292257599">02 9225 7599</a>.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">How much does family travel insurance cost?</h3>
            <p>
              The premium you pay for family travel insurance is based on many
              factors, such as which policy you choose, what countries you will
              be travelling to, the length of your family holiday, the age of
              the adult travellers, the excess and any options you chose.
            </p>
            <p>
              When travelling with two adults, pricing through World2Cover
              includes a 10% discount on the base premium<sup>♣</sup> for the youngest
              adult on the policy. Additionally, declared dependents up to the
              age of 25 are included at no additional cost (unless they have an
              existing medical condition and require a medical assessment)<sup>&#64;</sup>
              on all World2Cover travel insurance policies.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">What is covered with family travel insurance?</h3>
            <p>
              Family travel insurance through World2Cover is designed to meet
              the needs of families by providing multiple plans and a full list
              of the benefits available. It’s important to note the policy
              conditions, limits and sub-limits that apply to our World2Cover
              policies. These can be found in the Product Disclosure Statement
              <a target="_blank" href="/pds">(PDS)</a>.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Why should I consider family travel insurance?</h3>
            <p>
              Medical travel insurance is available within all World2Cover
              travel policies except our domestic policy. World2Cover family
              travel insurance is designed with families in mind to give you
              better peace of mind, so you can enjoy your holiday comfortably!
              World2Cover’s family insurance plans provide a level of cover for
              the whole family, not just the kids, so you can be protected from
              the unexpected. Visit <a href="http://www.world2cover.com.au" target="_blank"
              >www.world2cover.com.au</a> to look at our plans.
            </p>
            <p>
              To find out more about the benefits of travel insurance, visit the
              <a href="https://www.dfat.gov.au/" target="_blank"
                >Department of Foreign Affairs and Trade website</a
              >.
            </p>
          </div>

          <!--  -->
          <div class="py-3">
             
            <p class="desclaimer">
              <small>
                Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438
                291 AFSL 246 548) is the issuer of World2Cover Travel Insurance.
                The 'World2Cover Travel Insurance - Combined Financial Services
                Guide and Product Disclosure Statement' (PDS) which includes the
                policy wording, is available <a target="_blank" href="/pds">here</a>. Any
                financial advice set out above is general in nature only, and
                does not take into account your objectives, financial situation
                or needs. Because of this, you should, before acting on the
                advice, consider the appropriateness of the advice, having
                regards to your objectives, financial situation and needs. You
                should consider the PDS in deciding whether to acquire, or to
                continue to hold the policies.
              </small>
            </p>
            <p class="desclaimer" style="line-height: 10px;">
              <small>&#64; dependents aged 25 & under covered for no additional cost
                – provided they are financially dependent on their parents, not
                working full time, do not require a medical assessment and are
                travelling with you the whole time<br/>
                ♣ base premium is before any applicable medical loadings
              </small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>

  <app-footer></app-footer>
</div>
