import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-whats-covered',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './whats-covered.component.html',
  styleUrl: './whats-covered.component.scss',
})
export class WhatsCoveredComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle(
      'What does travel insurance cover? | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content: `At World2Cover we provide a range of travel insurance options to best fit your travel plans. Whether you're travelling alone, with a friend or with the whole family, we can cover you.`,
    });
  }
}
