<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'WhatsCovered'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/ltx-domestic-cover-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Domestic Cover</h1>
      <p>
        Take a long weekend to see more of Australia with added peace of mind
        with our Domestic travel insurance policy.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <p>
              World2Cover domestic travel insurance is for those times when you
              want to simply go on a stay-cation of sorts or while travelling
              throughout Australia. Even though you are staying in the country,
              it can be important to have some protection for your flights, car
              rentals and belongings. Whether you’re travelling to a different
              city or town, the outback or the beach, World2Cover domestic
              travel insurance can help to provide you with some
              cover<sup>#</sup>.
            </p>
          </div>
          <div class="page-content-info">
            <h3>What’s covered with domestic travel insurance?</h3>
            <p>
              When it comes to domestic travel insurance, World2Cover provides
              you with added peace of mind on a wide range of benefits. From
              cancellation fees and lost deposits to car rental excess cover, we
              might have the cover for you.
            </p>
            <p>
              Your Domestic Single Trip cover includes Limited COVID-19
              benefits<sup>~</sup>, please read our
              <a href="/pds" target="_blank">Product Disclosure Statement.</a>
            </p>
            <p>
              Our new for old luggage benefit is one of the many offerings on
              our domestic policy. New for old means that we don’t depreciate
              your luggage at time of claim. For example, we can choose to
              replace your damaged luggage<sup>#</sup>, with brand new
              luggage<sup>#</sup> up to the maximum amounts and specific limits
              of cover. Also remember that a pair or set of items is treated as
              one item (e.g. a pair of earrings, a camera body and its standard
              lens and accessories, or a set of golf clubs or a drone with its
              camera).
            </p>
          </div>

          <div class="page-content-info">
            <h3>
              Summary of covered benefits<sup><small>#</small></sup>
            </h3>
            <div class="card">
              <div class="card-body">
                <ul class="check-circle">
                  <li>Cancellation fees and lost deposits<sup>#</sup></li>
                  <li>
                    COVID-19 Cancellation fees and lost deposits, additional
                    expenses<sup>#</sup> and special events, travel delay<sup
                      >#</sup
                    >
                    (Up to $3,500 per adult combined limit)
                  </li>
                  <li>Travel agent fees</li>
                  <li>Lost, stolen or damaged luggage<sup>#</sup></li>
                  <li>Luggage delay</li>
                  <li>Personal money</li>
                  <li>Emergency expenses<sup>#</sup></li>
                  <li>Accidental Death* &amp; Accidental Disability*</li>
                  <li>Rental Vehicle Excess<sup>#</sup>&nbsp;</li>
                  <li>Loss of income<sup>#</sup></li>
                  <li>Legal liability^<sup>~</sup> (up to $10,000,000)</li>
                </ul>
                <div class="bg-light p-3 rounded font-12">
                  # terms and conditions, exclusions, limits and sub-limits
                  apply <br />
                  ^ This cover is per policy <br />
                  ~ Policy criteria and conditions apply <br />
                  * This cover is per person listed in Your Policy
                </div>
                <p class="mt-2">
                  Note: Our domestic policy does not cover medical costs within
                  Australia.
                </p>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Who does domestic travel insurance cover?</h3>
            <p>
              Domestic <a href="/travel-insurance">travel insurance</a> offers
              coverage for singles, couples and families. Singles include one
              adult between the ages of 18 and 75 and their declared dependents.
              Family cover includes any two adults named on the policy
              travelling together and their declared dependents. Dependents who
              are not working full-time, are aged 25 and under and travelling
              with you for the entire trip can be listed on the single and
              family travel insurance package. This is included at no additional
              cost, unless they have an existing medical condition to declare
              (in which case an additional premium may be required).
            </p>
            <p>
              Cover is available to Australian residents under two conditions:
              your trip begins and ends in Australia and you purchase your
              policy ahead of your trip. Temporary residents may also obtain
              cover under certain conditions, please refer to our Product
              Disclosure Statement for the detailed definition.
              <br />
              All our World2Cover policies offer you the opportunity to apply
              for <a href="/existing-medical">existing medical conditions.</a>
            </p>
          </div>

          <div class="page-content-info">
            <h3>How much does domestic travel insurance cost?</h3>
            <p>
              Domestic travel insurance costs depend on a variety of factors
              including the age of the travellers, how long your trip is and
              options you chose. You can vary your excess, add approved existing
              medical conditions or Ski and Winter sports cover. If you lodge a
              claim on our domestic travel insurance package, the standard
              excess is only $200 or you can reduce your excess to $100 or $0 by
              paying extra. All these can affect the price you pay.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Why consider World2Cover?</h3>
            <p>
              World2Cover offers a specially designed level of cover to assist
              with your domestic travel insurance needs. Enjoy the added peace
              of mind with our Aussie based customer service and instant quotes.
              You have heaps of places to go, people to see and adventures to
              experience. Take advantage of our cover because, as we know, you
              have the world to cover. Give us a call on
              <a href="tel:+61 2 9225 7599">02 9225 7599</a> or get an online
              quote today!
            </p>
          </div>

          <div class="page-content-info">
            <p class="font-12">
              <span
                >Tokio Marine &amp; Nichido Fire Insurance Co Ltd (ABN 80 000
                438 291 AFSL 246 548) is the issuer of World2Cover Travel
                Insurance. The 'World2Cover Travel Insurance - Combined
                Financial Services Guide and Product Disclosure Statement' (PDS)
                which includes the policy wording, is available
                <a href="/pds" target="_blank">here</a>. Any financial advice
                set out above is general in nature only, and does not take into
                account your objectives, financial situation or needs. Because
                of this, you should, before acting on the advice, consider the
                appropriateness of the advice, having regards to your
                objectives, financial situation and needs. You should consider
                the PDS in deciding whether to acquire, or to continue to hold
                the policies.&nbsp;</span
              >
            </p>
            <p class="desclaimer" style="line-height: 10px">
              <small
                ># terms and conditions, exclusions, limits and sub-limits
                apply<br />
                * This cover is per person listed in Your Policy. <br />
                ~ Policy criteria and conditions apply.
              </small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
