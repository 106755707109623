import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { PDSService } from '../../../services/pds.service';

@Component({
  selector: 'app-basics-cover',
  standalone: true,
  imports: [
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
    CommonModule,
  ],
  templateUrl: './basics-cover.component.html',
  styleUrl: './basics-cover.component.scss',
})
export class BasicsCoverComponent implements OnInit {
  sanitizedHtml: SafeHtml | null = null;
  constructor(
    private pdsService: PDSService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.titleService.setTitle(
      'Travel Insurance - Basic Cover | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content:
        'World2Cover offers specialised travel insurance to fit your needs, even if you’re on a budget. Give us a call at 02 9225 7599 or visit our website to purchase insurance for your holiday!',
    });
  }

  ngOnInit(): void {
    let href: string = '';
    switch (environment.autoClub.toUpperCase()) {
      case 'W2C':
        href = `<a  target="_blank" href='/pds'>here</a>`;
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(href);
        break;
      case 'RACV':
      case 'RACQ':
      case 'RACT':
      case 'RAA':
      case 'RAC':
      case 'AANT':
        this.loadPdsLinkForOtherClubs();
        break;
    }
  }

  loadPdsLinkForOtherClubs() {
    this.pdsService.getLatestPDS().subscribe((data) => {
      const href = `<a rel="noopener noreferrer" target="_blank" href="${
        data && data.pds && data.pds.length > 0 ? data.pds[0].previewUrl : '#'
      }">here</a>`;
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(href);
    });
  }
}
