<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>
  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/what-does-travel-insurance-cover.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>What does travel insurance cover?</h1>
      <p>
        Learn about the 3 international levels of cover for World2Cover travel
        insurance, along with additional options to customise our insurance to
        meet your needs.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <p>
              World2Cover travel insurance provides a wide range of benefits to
              help give you added peace of mind on your trip, whether you’re
              travelling solo, with a friend or the whole family. Plus, with the
              added options to reduce your excess from $200 to $100 or $0
              (except for Basics cover only to $100),
              <a href="/cruise-travel-insurance">cover a cruise</a>
              or even
              <a href="/ski-cover">winter sports</a>, you have greater
              flexibility at your disposal.
            </p>
            <p>
              We offer three levels of cover for your international travelling
              needs:
            </p>
            <ol class="text-gray">
              <li>
                Our
                <a href="/basics-cover">basics</a>
                cover provides simple but fundamental cover.
              </li>
              <li>
                The
                <a href="/essentials-cover">essentials package</a>
                is the ABC of cover; you can be sure that you have some cover
                for the “essentials”.
              </li>
              <li>
                Lastly, with
                <a href="/top-cover">top cover</a>, you can take advantage of
                the highest level of cover we provide.
              </li>
            </ol>
            <p>
              For avid travellers, the
              <a href="/annual-multi-trip-cover">annual multi-trip insurance</a>
              option may save you money as it can cover multiple trips over 12
              months (as long as each trip is no longer than 30, 45 or 60 days
              depending on what option is selected) instead of paying for
              multiple single trip policies.
            </p>
            <p>
              Our International Single Trip Top and Domestic covers include
              Limited COVID-19 benefits<sup>~</sup>. For more details please
              refer to our
              <a target="_blank" href="/pds">Product Disclosure Statement</a>.
            </p>
            <h6 class="text-gray">
              Compare our policy benefits and get a quote today. Happy
              travelling!
            </h6>
            <p class="desclaimer py-2">
              <small
                >Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438
                291 AFSL 246 548) is the issuer of World2Cover Travel Insurance.
                The 'World2Cover Travel Insurance - Combined Financial Services
                Guide and Product Disclosure Statement' (PDS) which includes the
                policy wording, is available
                <a target="_blank" href="/pds">here</a>. Any financial advice
                set out above is general in nature only, and does not take into
                account your objectives, financial situation or needs. Because
                of this, you should, before acting on the advice, consider the
                appropriateness of the advice, having regards to your
                objectives, financial situation and needs. You should consider
                the PDS in deciding whether to acquire, or to continue to hold
                the policies.</small
              >
            </p>
            <p class="desclaimer">
              <small>~ Policy criteria and conditions apply</small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
