<div class="container-lg my-1 experience pt-sm-5 pt-3 pb-3 pb-sm-5">
  <div class="row align-items-end">
    <div
      class="col-md-10 col-lg-8 col-10 p-sm-3 p-0 offset-lg-3 offset-sm-2 offset-1"
    >
      <div class="row align-items-center mb-1">
        <div class="col-md-7 col-lg-6">
          <h2 class="display-5 fw-bold text-light text-left mb-3">
            Go, Experience and Adventure!
          </h2>
        </div>
        <div class="col-md-5">
          <!-- <a
              class="btn btn-light text-info fw-bold btn-lg px-3 px-lg-5 py-2"
              href="#"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#get_quote"
              >Get a Quote
            </a> -->
          <a
            class="btn btn-light text-info fw-bold btn-lg px-3 px-lg-5 py-2"
            href="#quoteMenu"
            >Get a Quote
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
