<div class="card theme-search">
  <app-common-header></app-common-header>
  <section [ngClass]="partnercode ? 'maincobrandhero' : 'mainhero'">
    <div class="container-fluid herooverlay"></div>
    <div class="container">
      <div class="herocontent row align-items-center text-center">
        <div class="col-md-12">
          <div *ngIf="partnercode && autoClub === 'W2C'">
            <h1 class="display-5 fw-bold mt-10 text-light herotitle">
              {{ titleText }}
            </h1>
            <div class="main-heading text-center" style="min-height: 28px;">
              <div *ngIf="affiliate?.title">
                <h4 *ngIf="promo" class="mt-10 text-light" [ngClass]="textPaddingLogo()"> 
                  Get {{promoResponse?.discountRate}}% off {{affiliate?.title}}
                </h4>
                <h4 *ngIf="!promo" class="mt-10 text-light" [ngClass]="textPaddingLogo()"> 
                  Buy {{affiliate?.title}}
                </h4>
              </div>
            </div>
            <div class="enterimages">
              <img 
                [src]="affiliate?.logo"
                [ngClass]="checkMobileCoBrand()"
              />
            </div>
          </div>
          <div *ngIf="!partnercode">
            <h1 class="display-5 fw-bold mt-10 text-light herotitle">
              {{ titleText }}
            </h1>
            <p class="h4 fw-light text-light mb-2 herosubs">
              {{ subtitleText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-country-search></app-country-search>
  <app-awards></app-awards>
  <app-coverage></app-coverage>
  <app-w2c-offering></app-w2c-offering>
  <app-get-quote-panel></app-get-quote-panel>
  <!-- <app-covid-coverage></app-covid-coverage> -->
  <!--<app-customer-reviews></app-customer-reviews>-->
  <app-faq></app-faq>
</div>
