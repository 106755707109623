<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'WhatsCovered'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/ltx-existing-medical-conditions-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption existing-medical-condition-title">
      <h1>Existing Medical Conditions</h1>
      <p>
        World2Cover makes it a priority to cover multiple existing medical
        conditions on your international trip. For more information on existing
        medical conditions and your policy, please visit our website.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <p>
              When you’re travelling, all you should have on your mind is
              embarking on exciting adventures. You should not have to worry
              about if your existing medical condition is covered under your
              policy. Fortunately, World2Cover automatically covers a wide range
              of existing medical conditions. Making sure you’re covered for
              your existing medical condition can be a priority for you, so
              we’ve made it one of our priorities as well. If you have a
              condition that is not automatically covered, you must complete a
              medical assessment to determine if we can provide you with cover
              for your existing medical condition.
            </p>
          </div>
          <div class="page-content-info">
            <h3>What is considered an existing medical condition?</h3>
            <p>
              An <i><strong>Existing Medical Condition</strong></i> means any
              medical or physical condition, disorder, disease, disability or
              illness, including any <i><strong>Mental Illness</strong></i
              >, which at the <i><strong>Relevant Time</strong></i
              >, were aware of, or a reasonable person in the circumstances
              could be expected to have been aware of, and at the
              <i><strong>Relevant Time</strong></i
              >:
            </p>

            <ol class="text-gray">
              <li>
                is chronic, ongoing, terminal, or has affected or involved one
                or more of the following:
                <ol
                  style="
                    padding-inline-start: 35px;
                    list-style-type: lower-alpha;
                  "
                >
                  <li>
                    heart, circulatory system, lungs or respiratory system,
                    brain, kidneys, liver, or cancer;
                  </li>
                  <li>
                    surgery involving the back, neck, joints, or abdomen; or
                  </li>
                </ol>
              </li>
              <li>
                In the last 12 months had:
                <ol style="list-style-type: lower-alpha">
                  <li>
                    presented symptoms which would have caused an ordinarily
                    prudent person to seek medical opinion or treatment;
                  </li>
                  <li>become exacerbated or complicated; or</li>
                  <li>
                    been diagnosed, treated or treatment was recommended by a
                    <i><strong>Treating Doctor</strong></i
                    >.
                  </li>
                </ol>
              </li>
            </ol>

            <p>
              An existing condition is any medical condition you have and know
              about before you go on your trip. Your cover may be impacted due
              to an existing medical condition.
            </p>
          </div>

          <div class="page-content-info">
            <h3>What existing medical conditions are covered?</h3>
            <p>
              Not all existing medical conditions are covered, however,
              World2Cover’s policy automatically includes some existing medical
              conditions, subject to certain criteria. If you want cover for an
              existing medical condition(s) not automatically included, these
              must be assessed to determine whether they can be covered
            </p>
            <p>
              Some <i>Existing Medical Conditions</i> are automatically
              included, where You, at the <i>Relevant Time</i>:
            </p>
            <ol type="a" class="text-gray">
              <li>
                have not required hospitalisation or treatment by any
                <i><strong>Treating Doctor</strong></i> within the last 12
                months (unless a different time-period is specifically listed in
                the list below) for the
                <i><strong>Existing Medical Condition</strong></i
                >;
              </li>
              <li>
                are not awaiting the outcome of any investigation, tests,
                surgery or other treatment for the
                <i><strong>Existing Medical Condition</strong></i
                >; and
              </li>
              <li>
                meet any additional criteria set out in the
                <i>Existing Medical Conditions we automatically cover</i> listed
                below.
              </li>
            </ol>
            <p>
              Some of the existing medical conditions we can cover include
              allergies (limited to rhinitis, chronic sinusitis, eczema, food
              intolerance, and hay fever, however this excludes any Anaphylaxis
              as part of any such condition), cataracts, congenital blindness
              and deafness, diabetes<sup>♦</sup>, glaucoma, hypertension<sup>♦</sup>, migraines, and
              sleep apnoea. For a full list of covered conditions, please refer
              to our
              <a href="/pds" target="_blank">Product Disclosure Statements</a>.
            </p>
            <p class="font-12">
              ♦ Diabetes Mellitus (Type 1 and Type 2), Hypertension,
              Hypercholesterolaemia and Hyperlipidaemia are risk factors of
              cardiovascular disease. If you have a history of cardiovascular
              disease, and it is an existing medical condition, cover for these
              conditions is also excluded.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Which existing medical conditions are not covered?</h3>
            <p>
              <i><strong>We</strong></i> do not cover all
              <i><strong>Existing Medical Conditions</strong></i> under this
              policy. If <i><strong>You</strong></i> require cover for an
              <i><strong>Existing Medical Condition</strong></i> not listed in
              the
              <i
                ><strong
                  >‘Existing Medical Conditions We automatically cover’</strong
                ></i
              >
              section above, <i><strong>You</strong></i> may apply for coverage
              by completing <i><strong>Our</strong></i> medical assessment and
              if accepted, pay any additional premium which may be payable for
              coverage of that <i><strong>Existing Medical Condition</strong></i
              >, which will be specifically listed on your
              <i><strong>Certificate of Insurance</strong></i
              >.
            </p>
            <p>
              If a disclosed
              <i><strong>Existing Medical Condition</strong></i> is not
              accepted, or <i><strong>You</strong></i> chose not to take out
              cover for that <i><strong>Existing Medical Condition</strong></i
              >, this will be noted on
              <i><strong>Your Certificate of Insurance</strong></i
              >.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Which existing medical conditions need to be assessed?</h3>
            <p>
              If <i><strong>You</strong></i> have an
              <i><strong>Existing Medical Condition</strong></i> that is not
              automatically covered above and <i><strong>You</strong></i> want
              cover for this
              <i><strong>Existing Medical Condition, You</strong></i> will need
              to complete <i><strong>Our</strong></i> online medical assessment
              so that <i><strong>We</strong></i> can assess whether:
            </p>
            <ol class="text-gray">
              <li>
                <i><strong>We</strong></i> can cover the
                <i><strong>Existing Medical Condition</strong></i> – in which
                case additional Premium may be payable and the
                <i><strong>Existing Medical Condition</strong></i> will be
                listed on <i><strong>Your Certificate of Insurance</strong></i
                >; or
              </li>
              <li>
                <i><strong>We</strong></i> can’t cover the
                <i><strong>Existing Medical Condition</strong></i> – in which
                case, the
                <i><strong>Existing Medical Condition</strong></i> that has not
                been accepted will be listed on
                <i><strong>Your Certificate of Insurance</strong></i
                >.
              </li>
            </ol>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <div class="page-content-info mb-0">
                <h3>
                  Is pregnancy an existing medical condition for travel
                  insurance?
                </h3>
                <p>
                  <strong><i>Our</i></strong> policies provide cover for
                  pregnancy in limited circumstances.
                </p>
                <div class="pt-2">
                  <b>What is covered</b>
                </div>
                <p>
                  Cover is included automatically up to the end of the 25th week
                  of pregnancy for:
                </p>
                <ol type="a" class="text-gray">
                  <li>Single non-complicated pregnancies;</li>
                  <li>unexpected pregnancy complications; and</li>
                  <li>
                    childbirth which was accelerated by accidental injury in
                    limited circumstances.
                  </li>
                </ol>
                <p>Please see further information below.</p>
                <p>
                  <strong><i>You</i></strong> will need to apply for cover if at
                  the <strong><i>Relevant Time</i></strong
                  >, You know <strong><i>You</i></strong> are pregnant and are
                  aware of, or a reasonable person in the circumstances could be
                  expected to have been aware of, any of the following:
                </p>
                <ol class="text-gray" type="i">
                  <li>
                    there have been complications with this or a previous
                    pregnancy,
                  </li>
                  <li>
                    <i><strong>You</strong></i> are expecting a multiple
                    pregnancy (such as triplets or twins), or
                  </li>
                  <li>
                    <i><strong>You</strong></i> have an
                    <i><strong>Existing Medical Condition</strong></i> which
                    could have an adverse impact on
                    <i><strong>Your</strong></i> health.
                  </li>
                </ol>
                <b>Pregnancy Complications</b>
                <p>
                  Pregnancy complications are considered
                  <strong><i>Existing Medical Conditions</i></strong> and need
                  to be disclosed and assessed if
                  <strong><i>You</i></strong> want cover for these conditions
                  whilst on <strong><i>Your Trip</i></strong
                  >. Pregnancy complications include those that occur during
                  pregnancy or may be caused by
                  <strong><i>Existing Medical Conditions</i></strong> that
                  already existed prior to the pregnancy, such as previous high
                  risk of miscarriage, gestational diabetes, hyperemesis (severe
                  morning sickness) or pre-eclampsia. Please refer to the
                  <i>Existing Medical Conditions We need to assess</i> section
                  above.
                </p>
                <div class="pt-2">
                  <b>What is not covered</b>
                </div>
                <p>There is no cover for:</p>
                <ol type="a" class="text-gray">
                  <li>
                    pregnancy complications occurring from the 26th week of
                    gestation , or as described in the section above (<strong
                      >Pregnancy Complications</strong
                    >) unless such complications are specifically accepted by
                    <i><strong>Us</strong></i> and noted on
                    <i><strong>Your Certificate of Insurance</strong></i
                    >;
                  </li>
                  <li>
                    childbirth at any stage of the pregnancy, other than as a
                    result of an
                    <i><strong>Accident</strong></i> occurring prior to the end
                    of the 25th week of <strong><i>Your</i></strong> pregnancy
                    which causes <strong><i>You</i></strong> to give birth
                    prematurely;
                  </li>
                  <li>
                    the health or care of a newborn child, irrespective of the
                    stage of pregnancy when the child is born; or
                  </li>
                  <li>regular antenatal care.</li>
                </ol>
                <p style="margin-top: 15px">
                  <strong><i>We</i></strong> recommend that
                  <strong><i>You</i></strong> contact
                  <strong><i>Your</i></strong> Treating Doctor and obtain
                  written confirmation that <strong><i>You</i></strong> are fit
                  to travel before commencing
                  <strong><i>Your</i></strong> planned
                  <strong><i>Trip</i></strong
                  >. Please see the Changes in
                  <strong><i>Your</i></strong> health section below, which
                  outlines conditions to <strong><i>Our</i></strong> cover.
                </p>
                <p style="margin-top: 15px">
                  If <strong><i>You</i></strong> are unsure whether
                  <strong><i>You</i></strong> need to complete a medical
                  assessment for <strong><i>Your</i></strong> pregnancy, please
                  call <a href="tel:0292257599">02 9225 7599</a> for additional
                  assistance.
                </p>
                <p>
                  Please refer to <i>‘General Exclusions’</i> in the PDS which
                  apply to all sections of cover.
                </p>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Additional Information</h3>
            <p>
              If you find the topic of existing medical conditions to be
              confusing, please give us a call
              <a href="tel:0292257599">02 9225 7599</a> for additional
              assistance. At World2Cover, we strive to be a reliable resource
              for you as you navigate the journey to your destination. Happy
              travelling!
            </p>
            <p class="font-12">
              Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438 291
              AFSL 246 548) is the issuer of World2Cover Travel Insurance. The
              'World2Cover Travel Insurance - Combined Financial Services Guide
              and Product Disclosure Statement' (PDS) which includes the policy
              wording, is available
              <span *ngIf="sanitizedHtml" [innerHTML]="sanitizedHtml"></span>.
              Any financial advice set out above is general in nature only, and
              does not take into account your objectives, financial situation or
              needs. Because of this, you should, before acting on the advice,
              consider the appropriateness of the advice, having regards to your
              objectives, financial situation and needs. You should consider the
              PDS in deciding whether to acquire, or to continue to hold the
              policies.
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
