<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>COVID-19 FAQs</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-content-info">
            <h3>
              COVID-19 FAQs – For all policies purchased on or after 1st March
              2022
            </h3>
            <p>
              Please note that these FAQs should be read in conjunction with the
              Product Disclosure Statement (PDS) which contain the full terms
              and conditions, limits and exclusions of this policy. You should
              consider the PDS before making a decision about whether to
              purchase a policy.
            </p>
            <p>
              We have outlined the most commons scenarios that could be raised
              by our customers below. Please contact us if You have any
              questions or require any further information or clarification on
              cover. Customers always have a right to lodge a claim for
              consideration under their policy, which will be considered in line
              with policy terms and conditions and their individual
              circumstances.
            </p>
            <p>
              All capitalised terms have corresponding meaning as set out in the
              policy terms and conditions, unless otherwise indicated.
            </p>
          </div>

          <div class="page-content-info">
            <!-- Accordion -->
            <div class="card rounded-40 mb-3 shadow border-0">
              <div class="faqs-accorion accordion" id="faqsAccordion">
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title rounded-top-10"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_01"
                      aria-expanded="false"
                      aria-controls="faqsContent_01"
                    >
                      Am I covered if I need to quarantine once I enter a
                      region/country based on the government or state
                      regulations?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_01"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          All policies have a General Exclusion relating to
                          claims directly or indirectly arising from any
                          government or public health authority mandatory
                          quarantine or isolation order imposed on You related
                          to border, region or territory travel in response to
                          COVID-19. Please check with local government and
                          public health authorities prior to Your departure.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_02"
                      aria-expanded="false"
                      aria-controls="faqsContent_02"
                    >
                      Am I covered for medical expenses if I am diagnosed with
                      COVID-19 during my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic - No medical cover is included on this plan.
                        </p>
                        <p>
                          International - Yes, the Top Cover plan includes
                          unlimited* medical expenses, for reasonable costs you
                          incur due to COVID-19. There is no cover under the
                          Essentials Cover or Basic Cover plans.
                        </p>
                        <p>
                          Annual Multi-Trip - No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_03"
                      aria-expanded="false"
                      aria-controls="faqsContent_03"
                    >
                      Am I covered for funeral and emergency expenses if I die
                      from COVID-19 during my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – There is no medical cover included on this
                          plan.
                        </p>
                        <p>
                          International – Yes Cover is available on the Top
                          cover plan up to a maximum limit of $20,000 per
                          person. There is no cover under the Essentials Cover
                          or Basic Cover plans.
                        </p>
                        <p>
                          Annual Multi-Trip - No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_04"
                      aria-expanded="false"
                      aria-controls="faqsContent_04"
                    >
                      What if I am diagnosed with COVID-19 on my trip and I need
                      to quarantine are my emergency/additional expenses
                      covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – Yes only on the plan that indicates covid
                          cover included under Section 23 COVID-19 Additional
                          Expenses.
                        </p>
                        <p>
                          International – Yes only on the plan that indicates
                          covid cover included the Top Cover plan. There is no
                          cover under the Essentials Cover or Basic Cover plans.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_05"
                      aria-expanded="false"
                      aria-controls="faqsContent_05"
                    >
                      Am I covered if I am deemed a close contact due to a
                      COVID-19 case and I need to quarantine before continuing
                      on with my trip?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-3">
                        <p>
                          Domestic – Yes only on the plan that indicates covid
                          cover included.
                        </p>
                        <p>
                          International – Yes, only on the Top Cover plan, up to
                          a total combined limit of $3,500 per listed adult. If
                          you have selected either the Essentials Cover of Basic
                          Cover plans, then no COVID-19 benefits are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_06"
                      aria-expanded="false"
                      aria-controls="faqsContent_06"
                    >
                      What other emergency expenses do You cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – No cover for emergency expenses under
                          Section 21. Refer to Section 23 COVID-19 Additional
                          Expenses.
                        </p>
                        <p>
                          International – On the Top Cover plan that indicates
                          covid cover included we cover all reasonable emergency
                          expenses of any insured event that we have accepted
                          cover for under Section 21A. If you have selected
                          either the Essentials Cover of Basic Cover plans, then
                          no COVID-19 benefits are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_07"
                      aria-expanded="false"
                      aria-controls="faqsContent_07"
                    >
                      Am I covered for additional expenses and/or cancellation
                      fees apply if my relative or business partner dies from
                      COVID-19 and I need to cancel my trip or cut my trip short
                      and come home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_07"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Domestic – Yes, there are limited COVID-19 benefits
                        available, up to a total combined limit of $3,500 per
                        listed adult. only on the plan that indicates covid
                        cover included.
                      </p>
                      <p>
                        International – Yes only if you have selected the plan
                        the Top Cover plan, yes there are limited COVID-19
                        benefits available, up to a total combined limit of
                        $3,500 per listed adult. If you have selected either the
                        Essentials Cover of Basic Cover plans, then no COVID-19
                        benefits are available. Annual Multi-Trip – No, no
                        COVID-19 benefits are available on this plan.
                      </p>
                    </div>
                  </div>
                </div>




                

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_077"
                      aria-expanded="false"
                      aria-controls="faqsContent_077"
                    >
                    Am I covered for COVID-19 if I am travelling on a multi-night Cruise?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_077"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Limited COVID-19 benefits are available only if you have selected ‘Going on an Ocean Cruise’ option and purchased the Top Cover plan. This cover includes unlimited** benefits for COVID-19 Overseas Medical and Hospital and Emergency Expenses**. Cover is also available for Cancellation Fees and Lost Deposits; Additional Expenses and Special Events; and Travel Delay, to a maximum combined limit of $3,500 per adult (maximum of two adults per policy). Declared dependents are included in the total combined limit for COVID-19 benefits per declared adult.
                      </p>
                    </div>
                  </div>
                </div>




                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_08"
                      aria-expanded="false"
                      aria-controls="faqsContent_08"
                    >
                      Am I covered for loss of enjoyment of my cruise or
                      holiday, if I am required to isolate or quarantine due to
                      COVID-19 whilst on my holiday?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_08"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          No, all policies have a General Exclusion for any
                          Consequential loss which includes loss of enjoyment
                          and as such there is no cover for loss of enjoyment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_09"
                      aria-expanded="false"
                      aria-controls="faqsContent_09"
                    >
                      Am I covered for my medical or evacuation costs for
                      Australian Cruising?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_09"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        If You select ‘Australian Cruise’ as Your country of
                        destination, You will be offered International policy
                        cover, which provides medical and evacuation benefits
                        whilst at sea subject to policy terms and conditions.
                        Please note that this is an optional extra that will be
                        subject to an additional fee.
                      </p>
                      <p>
                        However, no cover is available for medical and
                        evacuation expenses whilst in an Australian port, as we
                        do not cover medical expenses incurred in Australia (as
                        we are prevented from paying by reason of statutory
                        legislation/government regulation). Additionally, no cover is available for medical and evacuation expenses related to COVID-19 unless you have purchased Top Cover.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_10"
                      aria-expanded="false"
                      aria-controls="faqsContent_10"
                    >
                      Am I covered for cancellation fees and lost deposits apply
                      if I am diagnosed with COVID-19 and I need to cancel my
                      trip or cut my trip short and/or come home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_10"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – Yes, there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult.
                        </p>
                        <p>
                          International – If you have selected the Top Cover
                          plan, yes there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult. If you have selected either the
                          Essentials Cover or Basic Cover plans, then no
                          COVID-19 benefits are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_11"
                      aria-expanded="false"
                      aria-controls="faqsContent_11"
                    >
                      Am I covered if my travelling companion is diagnosed with
                      COVID-19 and I need to cut my trip short and/or come home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_11"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – Yes, there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult.
                        </p>
                        <p>
                          International – If you have selected the Top Cover
                          plan, yes there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult. If you have selected the Essentials
                          Cover or Basic Cover plans, then no COVID-19 benefits
                          are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_12"
                      aria-expanded="false"
                      aria-controls="faqsContent_12"
                    >
                      Am I covered if my travelling companion is diagnosed with
                      COVID-19 and my trip is cancelled?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_12"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Domestic – Yes, there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult.
                        </p>
                        <p>
                          International – If you have selected the Top Cover
                          plan, yes there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult. If you have selected the Essentials
                          Cover or Basic Cover plans, then no COVID-19 benefits
                          are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_13"
                      aria-expanded="false"
                      aria-controls="faqsContent_13"
                    >
                      Am I covered if I am not vaccinated and it is a condition
                      of the transport provider that I must be vaccinated before
                      boarding?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_13"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        No, our policies do not provide cover if You do not
                        comply with the ticket conditions of the transport
                        provider as our policies have a General Exclusion for
                        failure to obtain the relevant travel documents.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_14"
                      aria-expanded="false"
                      aria-controls="faqsContent_14"
                    >
                      Am I covered if I am not vaccinated and it is a condition
                      of the country that I must be vaccinated before I enter?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_14"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        No, our policies do not provide cover if You do not
                        comply with the government regulations of the country
                        You are travelling to as our policies have a General
                        Exclusion for breaking any Government prohibition, laws
                        or regulations.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_15"
                      aria-expanded="false"
                      aria-controls="faqsContent_15"
                    >
                      Do cancellation fees apply if I am a permanent employee of
                      the healthcare industry and my employer cancels my leave
                      due to COVID-19?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_15"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Domestic – Yes, there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult.
                        </p>
                        <p>
                          International – If you have selected the Top Cover
                          plan, yes there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult. If you have selected the Essentials
                          Cover or Basic Cover plans, then no COVID-19 benefits
                          are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_16"
                      aria-expanded="false"
                      aria-controls="faqsContent_16"
                    >
                      Am I covered under Special Events if my pre-arranged
                      special event is delayed because of COVID-19, which is
                      outside of my control and I need to make alternative
                      arrangements to get to my destination on time?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_16"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Domestic – Yes, there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult.
                        </p>
                        <p>
                          International – If you have selected the Top Cover
                          plan, yes there are limited COVID-19 benefits
                          available, up to a total combined limit of $3,500 per
                          listed adult. If you have selected the Essentials
                          Cover or Basic Cover plans, then no COVID-19 benefits
                          are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, there are no COVID-19 benefits
                          are available on this plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_17"
                      aria-expanded="false"
                      aria-controls="faqsContent_17"
                    >
                      Do I have cover under Travel Delay if my schedule
                      transport is delayed for more than 6 hours due to COVID-19
                      related delays and I need to pay additional expenses?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_17"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Domestic – Yes, cover is available with a daily limit of
                        $1,000 per adult and a maximum limit of $3,500 per
                        listed adult.
                      </p>
                      <p>
                        International – If you have selected the Top Cover plan
                        cover is available with a daily limit of $1,000 per
                        adult and a maximum limit of $3,500 per listed adult. If
                        you have selected the Essentials Cover or Basic Cover
                        plans, then no COVID-19 benefits are available. <br />
                      </p>
                      <p>
                        Annual Multi-Trip – No, no COVID-19 benefits are
                        available on this plan.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqsContent_18"
                      aria-expanded="false"
                      aria-controls="faqsContent_18"
                    >
                      Are there additional COVID-19 benefits available if my
                      trip destination is within Australia or New Zealand?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_18"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Domestic – Yes, as outlined below, to the total
                          combined limit of $3,500 per listed adult.
                        </p>
                        <p>
                          International – Yes, if you have selected the Top
                          Cover plan, there are additional COVID-19 benefits, as
                          outlined below, to the total combined limit of $3,500
                          per listed adult. If you have selected the Essentials
                          Cover or Basic Cover plans, then no COVID-19 benefits
                          are available.
                        </p>
                        <p>
                          Annual Multi-Trip – No, no COVID-19 benefits are
                          available on this plan.
                        </p>
                        <p><b>Cancellation Fees and Lost Deposits</b></p>
                        <p class="ps-1">
                          If the person you’re due to stay with in Australia or
                          New Zealand is diagnosed with COVID-19, or directed by
                          a local public health authority into a period of
                          quarantine and you are unable to stay with them.
                        </p>
                        <p><b>Additional Expenses</b></p>
                        <p class="ps-1">
                          If you can no longer stay with the person you planned
                          to stay within Australia or New Zealand because they
                          are diagnosed with COVID-19 and directed to enter a
                          period of quarantine; or If your pre-paid
                          accommodation in Australia or New Zealand is shutdown
                          or closes as a result of COVID-19.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <p>
              <small
                >**unlimited means that generally there is no cap on the maximum
                dollar amount which may be paid out of this benefit, subject to
                the specific terms and conditions, sub-limits and exclusion
                apply to this benefit. This benefit covers reasonable overseas
                medical and hospital costs as a result of an injury (including
                arising from a terrorist act) or illness occurring which first
                shows itself during your period of insurance. Benefits may be
                paid up to 12 months from the time you received treatment for
                the injury or illness, but only for reasonable expenses incurred
                during that time. All medical treatments must be provided by
                your treating doctor or our consulting medical officer. You must
                notify us as soon as practicable of your admittance to
                hospital.</small
              >
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
