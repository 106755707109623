import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-israel-faqs',
  standalone: true,
  imports: [
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './israel-faqs.component.html',
  styleUrl: './israel-faqs.component.scss',
})
export class IsraelFaqsComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('israel-faqs');
  }
}
