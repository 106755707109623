import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss',
})
export class FaqsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Travel Insurance FAQ - World2Cover Australia');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Find answers to the most popular questions about travel insurance in Australia. Learn how World2Cover travel insurance has your back when you travel.',
    });
  }

  toggleCollapse(event: Event, index: string): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById(index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.classList.toggle('collapsed', isExpanded);
      button.setAttribute('aria-expanded', (!isExpanded).toString());
    }
  }
}
