import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'ageFormat',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  transform(dateOfBirth: string): string {
    const birthDate = moment(dateOfBirth, 'DD/MM/yyyy').toDate();
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }
}
