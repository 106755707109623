<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'HowToClaim'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/how-to-claim-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>How to Claim</h1>
      <p>
        An Online claim can usually be lodged in less than 10 minutes directly
        on our website.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3>Lodge a Travel Insurance Claim Online</h3>
            <p>
              The quickest way to lodge a claim with World2Cover is using our
              online claim lodgement link available 24/7 and follow the steps
              below:
            </p>
            <ol class="text-gray">
              <li>
                Register by utilising the email address you used to purchase
                your policy
              </li>
              <li>
                Once registered you will receive an email to guide you to set up
                your password
              </li>
              <li>
                If you have previously registered a claim just login using your
                email and password
              </li>
              <li>
                Once you have logged in, complete your claim details by
                answering all sections
              </li>
              <li>
                Once submitted, your claim will be assessed within 10 working
                days and we will let you know if we require any further
                information/documentation from you
              </li>
            </ol>

            <div>
              <a
                target="_blank"
                href="https://webapps.tokiomarine.com.au/WTCC/Account/Register"
                class="btn btn-outline-info btn-lg px-5 me-2"
                >Register</a
              >
              <a
                target="_blank"
                href="https://webapps.tokiomarine.com.au/WTCC/Account/Login"
                class="btn btn-info btn-lg px-5 text-white"
                >Sign in</a
              >
            </div>
          </div>

          <div class="page-content-info">
            <h3>What are the other options to lodge a Claim?</h3>
            <ul class="link">
              <li>
                By phone:
                <ol class="text-gray">
                  <li>
                    Please call us: Phone:
                    <a href="tel:+61292257599">+61 2 9225 7599</a> or within
                    Australia <a href="tel:0280551682">02 8055 1682</a>
                  </li>
                </ol>
              </li>
              <li>
                Opening Hours:
                <ol class="text-gray">
                  <li>Monday to Friday 8.00am-9.00pm AEST</li>
                  <li>Saturday to Sunday 9.00am-6.00pm AEST</li>
                </ol>
              </li>
              <li>
                By email:
                <a href="mailto:travelclaims@world2cover.com.au"
                  >travelclaims&#64;world2cover.com.au</a
                >
              </li>
              <li>
                By mail: Write to us at World2Cover Claims, GPO Box 4616, Sydney
                NSW 2001
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>How to make a Travel Insurance Claim?</h3>
            <p>
              With World2Cover, you can claim online, over the phone or by mail.
              Before lodging a claim, we recommend you gather all the
              information you can to support your claim. This includes your
              policy number, travel itinerary, details of the incident
              (including time and place), supporting documents (e.g.
              incident/loss reports, invoices, receipts, medical reports,
              boarding passes, etc.) and your bank account details (for the
              payment of your claim). Our online claim process allows you to
              attach your scanned documents directly into your secure account.
            </p>
          </div>

          <div class="page-content-info">
            <h3>When should I lodge a Claim?</h3>
            <p>
              The sooner, the better. At World2Cover we suggest you submit your
              claim within 30 days of your return whilst the details of the
              event are still fresh in your mind. You can lodge a claim before
              collecting all documentation however we cannot fully assess your
              claim until we have all the information we need. We are available
              to help you through the process though it is always quicker if you
              attach all your documentation when you lodge your claim.
            </p>
          </div>

          <div class="page-content-info">
            <h3>What to know before lodging a Claim?</h3>
            <p>
              Before submitting your claim, you can refer to the Product
              Disclosure Statement to see what you can claim and what terms
              &amp; conditions apply.
            </p>
          </div>

          <div class="page-content-info">
            <h3>How long does a claim need to be process?</h3>
            <p>
              <strong
                >Once all required documentation has been provided your claim
                will be assessed within 10 business days.</strong
              >
            </p>
            <p>
              We will assess your claim within
              <strong>10 business days</strong> of you notifying us and you
              providing us with all the necessary supporting documentation. If
              we need any additional information, a written notification or
              phone call will be made to You within
              <strong>10 business days</strong>.
            </p>
          </div>

          <div class="page-content-info">
            <h3>What to know about World2Cover and claims?</h3>
            <ol class="text-gray">
              <li>
                When you purchase your travel insurance, World2Cover gives you
                the option to reduce your excess from $200 to $100 to $0 for all
                covers except for the International Single Trip Basics cover
                from $200 to $100 only. The excess amount you have chosen is the
                amount that will be deducted, per incident, from the assessed
                claimable amount on your claim.
              </li>
              <li>
                <strong
                  >Old for New: Your policy provides coverage for the loss,
                  theft or damage of your luggage<sup>#</sup> as per the policy
                  terms &amp; conditions.Our policy provides new for old
                  replacement coverage up to the maximum amounts and specific
                  limits of coverage</strong
                >
              </li>
            </ol>
          </div>

          <div class="page-content-info">
            <h3>What is a Travel Insurance Claim?</h3>
            <p>
              A travel insurance claim is a formal request by a policyholder
              (you) to an insurance company for coverage or compensation for a
              loss or expense which has been incurred during the policy period.
            </p>
            <p>
              Travel insurance is an insurance product covering unforeseen
              circumstances which may occur after your policy purchase or during
              your trip. Depending on the type of travel insurance purchased
              (Domestic or International) and the level of cover (Top,
              Essentials, Basic), you may be covered for various events such as
              emergency medical expenses while overseas, trip cancellation, lost
              luggage, flight delays, public liability, and other
              expenses<sup>~</sup>.
            </p>
          </div>

          <div class="page-content-info">
            <h3>
              What are the steps to lodge a Travel Insurance Claim online?
            </h3>
            <ol class="text-gray">
              <li>
                Register by utilising the email address you used to purchase
                your policy
              </li>
              <li>
                Once registered you will receive an email to guide you to set up
                your password
              </li>
              <li>
                If you have previously registered a claim just login using your
                email and password
              </li>
              <li>
                Once you have logged in, complete your claim details by
                answering all sections
              </li>
              <li>
                Once submitted, your claim will be assessed within 10 working
                days and we will let you know if we require any further
                information/documentation from you
              </li>
            </ol>
          </div>

          <div class="page-content-info">
            <h3>What if I am not happy with my claims results?</h3>
            <p>
              After your claim has been assessed by our team you will receive
              written confirmation confirming the outcome of your claim. <br />
              If you do not agree with the outcome of your claim you are
              entitled to make a complaint to us about any aspect of your
              relationship with us either by phone, by email or in writing via
              post.
            </p>
            <p>
              We are committed to resolving any complaint or dispute fairly and
              as quickly as possible. If you are dissatisfied with our service
              in any way, please contact us and we will do our best to resolve
              your concerns as soon as reasonably possible. If we are unable to
              or if you are still not satisfied, our Customer Complaints Team
              will review your complaint and provide you with a response.
            </p>
            <p>
              When you make a complaint, please provide us with as much
              information as possible. You can contact us to make a complaint,
              or if you require assistance to lodge a complaint, using the
              contact details provided below:
            </p>
            <p>
              Post: GPO Box 4616, SYDNEY NSW 2001 <br />
              Email: Complaints&#64;tokiomarine.com.au <br />
              Phone: (02) 9225 7599
            </p>
            <p>
              If you are not satisfied with our response to your complaint, or
              we have taken more than 30 days to respond to you from the date
              you first made your complaint, you may be eligible to escalate the
              matter to the Australian Financial Complaints Authority (AFCA) if
              your matter is within the jurisdiction as set out in their Rules.
              AFCA is an independent external disputes resolution provider who
              can assess your matter free of charge and can issue a binding
              outcome on us.
            </p>
            <p>
              You can contact the Australian Financial Complaints Authority:
            </p>
            <p>
              Online:
              <a
                rel="noopener noreferrer"
                href="https://www.afca.org.au/"
                target="_blank"
                >www.afca.org.au</a
              >
              <br />
              Email: <a href="mailto:info@afca.org.au">info&#64;afca.org.au</a>
              <br />
              Phone: <a href="tel:1800931678">1800 931 678 (local call cost) </a
              ><br />
              Mail: Australian Financial Complaints Authority GPO Box 3
              Melbourne VIC 3001 <br />
            </p>
          </div>

          <div class="page-content-info">
            <h3>Interpreting Services</h3>
            <p>
              We are pleased to offer assistance in the following ways if you
              need help understanding any aspect of your travel insurance policy
              or claim in your language.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Contact Translating and Interpreting Services</h3>
            <p>
              Translating and Interpreting Services (TIS) are available 24/7 and
              their interpreters speak over 160 languages. They're available
              over the phone and in person.
            </p>
            <p>
              Book an
              <span style="text-decoration: underline"
                ><a href="https://www.tisnational.gov.au"
                  >appointment with TIS</a
                ></span
              >, or call them on 131 450. This is a free service.
            </p>
            <p class="font-12">
              Tokio Marine &amp; Nichido Fire Insurance Co Ltd (ABN 80 000 438
              291 AFSL 246 548) is the issuer of World2Cover Travel Insurance.
              The 'World2Cover Travel Insurance - Combined Financial Services
              Guide and Product Disclosure Statement' (PDS) which includes the
              policy wording, is available
              <a  target="_blank" href="/pds">here</a>. Any financial advice set out above is
              general in nature only, and does not take into account your
              objectives, financial situation or needs. Because of this, you
              should, before acting on the advice, consider the appropriateness
              of the advice, having regards to your objectives, financial
              situation and needs. You should consider the PDS in deciding
              whether to acquire, or to continue to hold the policies.
            </p>
            <p class="desclaimer" style="line-height: 10px">
              <small
                ># terms and conditions, exclusions, limits and sub-limits
                apply<br />
                ~ Policy criteria and conditions apply.
              </small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
