<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'TravelInsurance'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/travel-medical-insurance.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Medical Insurance</h1>
      <p>
        World2Cover travel medical insurance provides the extra protection
        international travellers may need, from emergency expenses to overseas
        dentist and hospital visits.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3 class="icon">International travel medical insurance</h3>
            <p>
              Travel comes with many unforeseeable challenges. Medicare doesn’t
              cover sickness, injuries and other emergency situations when you
              are abroad, leaving you out of luck unless you are able to claim
              on travel insurance.
            </p>
            <p>
              Travel medical insurance is a great option for those who wish to
              add an extra layer of protection and security to their trips.
              Travel insurance is available, whether you are going on vacation
              or visiting family.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">
              What is covered with international travel medical insurance?
            </h3>
            <p>
              Each World2Cover international travel insurance policy can provide
              cover for overseas medical expenses with $unlimited** cover for
              Medical on each level of cover including Annual Multi-Trip (AMT).
              Our international travel insurance policy also includes levels of
              cover for hospital and dentist visits, overseas funerals, and
              other emergencies. While this is included in each of our policies,
              the amount of coverage may vary between policies.
            </p>
            <p>
              Our International Single Trip Top cover policy includes limited
              COVID-19 benefits.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">
              Travel insurance with existing medical conditions
            </h3>
            <p>
              Our policies do not cover all existing medical conditions,
              however, a wide range of existing medical conditions are included
              automatically. Visit our
              <a href="/existing-medical">Existing Medical Conditions</a>
              FAQs page for a full list of the 38 covered existing medical
              conditions.
            </p>
            <p>
              We offer limited coverage for pregnancy. Single, non-complicated
              pregnancies are automatically covered, however, coverage is only
              provided for unexpected complications before the 26th week. There
              is no cover for childbirth unless this is accelerated by
              accidental injury before the 26th week. All other pregnancies
              require a health assessment to determine their coverage
              eligibility.
            </p>
            <p>
              If you have any existing medical condition that is not
              automatically covered, or does not meet the criteria, you can
              complete an assessment online or over the phone with one of our
              agents, to determine if we are able to provide cover for your
              existing medical condition. An extra premium may apply depending
              on the outcome of your assessment. Cover for any declared
              condition is subject to this being accepted by us and by you and
              listed on your Certificate of Insurance. If you do not declare an
              existing medical condition that is not listed as one that we cover
              automatically, you run the risk of a claim which arises from your
              existing medical condition being declined.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Who does travel medical insurance cover?</h3>
            <p>
              All of our travel medical insurance plans include cover for the
              listed travellers declared on the policy.
            </p>
          </div>
          <div class="page-content-info">
            <h3 class="icon">How much does travel medical insurance cost?</h3>
            <p>
              Medical travel insurance is available within all World2Cover
              travel policies except our domestic policy. These policies may
              contain other areas of coverage in addition to the medical
              coverage, such as luggage, cancellations or loss of income.
            </p>
            <p>
              These policies will all be tailored to fit your specific needs,
              meaning pricing may vary between applicants. Factors taken into
              consideration include destination(s), travel dates, ages of
              travellers and the policy you select. The only way to determine
              what you will pay for your medical insurance is to obtain an
              insurance quote.
            </p>
          </div>

          <!--  -->
          <div class="py-0">
            <p class="mt-2 desclaimer">
              <small
                >Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438 291
                AFSL 246 548) is the issuer of World2Cover Travel Insurance. The
                'World2Cover Travel Insurance - Combined Financial Services
                Guide and Product Disclosure Statement' (PDS) which includes the
                policy wording, is available
                <a target="_blank" href="/pds">here</a>. Any financial advice
                set out above is general in nature only, and does not take into
                account your objectives, financial situation or needs. Because
                of this, you should, before acting on the advice, consider the
                appropriateness of the advice, having regards to your
                objectives, financial situation and needs. You should consider
                the PDS in deciding whether to acquire, or to continue to hold
                the policies.</small
              >
            </p>
            <p class="desclaimer" style="line-height: 10px;">
              <small
                >** $unlimited means that generally there is no cap on the
                maximum dollar amount which may be paid out of this benefit,
                subject to the specific terms and conditions, sub-limits and
                exclusion that apply to this benefit. This benefit covers
                reasonable overseas medical and hospital costs as a result of an
                injury (including arising from a terrorist act) or illness
                occurring which first shows itself during your period of
                insurance. Benefits may be paid up to 12 months from the time
                you received treatment for the injury or illness, but only for
                reasonable expenses incurred during that time. All medical
                treatments must be provided by your treating doctor or our
                consulting medical officer. You must notify us as soon as
                possible of your admittance to hospital.<br />
                Policy criteria and conditions apply.</small
              >
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
