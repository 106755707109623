import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-review-policy',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './review-policy.component.html',
  styleUrl: './review-policy.component.scss',
})
export class ReviewPolicyComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Review of Policy Terms');
  }
}
