import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Privacy Policy');
    this.metaService.updateTag({
      name: 'description',
      content: `At World2Cover travel insurance we care about your privacy. We are committed to the safe and careful use of your personal information`,
    });
  }
}
