<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/privacy-policy-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Tokio Marine Australasia Privacy Policy</h1>
      <p>
        World2Cover travel insurance is committed to protecting your privacy,
        security is important to protect both you and us.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3>Overview</h3>
            <p>
              Tokio Marine is committed to upholding your privacy and protecting
              your personal information. We are bound by and our policies comply
              with the
              <i>Privacy Act 1988 (Cth)</i> and the Australian Privacy
              Principles (APP), along with any other applicable privacy laws and
              codes.
            </p>
            <p>
              This Privacy Policy will help you understand how we collect and
              handle your personal information in order to provide products and
              services and operate our business. This section provides a summary
              of the key provisions of our Privacy Policy. For more detailed
              information see below.
            </p>
          </div>

          <div class="page-content-info">
            <h3>More Details On How We Handle Your Personal Information</h3>
            <h3>What is Personal Information:</h3>
            <p>
              Personal information is information or an about an identified
              individual, or an individual who is reasonably identifiable;
            </p>
            <ul class="text-gray">
              <li>whether the information or opinion is true or not; and</li>
              <li>
                whether the information or opinion is recorded in a material
                form or not.
              </li>
            </ul>
            <h3>Collection and Handling of your Information:</h3>
            <p>
              We may collect your information when you interact with us, visit
              our websites, use our products or services or apply for a job with
              us. Depending on the circumstances, information we collect may
              include your name, contact details (address, phone and fax number,
              email address), date of birth, gender and payment details. We take
              all reasonable steps to ensure that information you provide us is
              stored securely in physical or electronic form on our systems or
              those of our third party service providers' systems, both locally
              and offshore (including cloud based services).
            </p>
            <h3>Use:</h3>
            <p>We may use your information:</p>
            <ul class="text-gray">
              <li>
                to provide products and services to you and respond to your
                queries;
              </li>
              <li>
                to market products or services to you if you've given us your
                consent to do so
              </li>
              <li>to assess job applications;</li>
              <li>
                to comply with laws, regulations and requirements, including our
                internal policies; or
              </li>
              <li>to improve our products, services and marketing.</li>
            </ul>
            <h3>Sharing Your Information:</h3>
            <p>
              We may share your information with others who help us provide
              services to you or who act for us (such as insurance brokers or
              professional advisers), with other companies in the Tokio Marine
              Group locally and overseas, and with other third parties if you
              consent to us sharing your information with them. Sometimes we are
              required to share your information with local or overseas law
              enforcement agencies, industry bodies, regulators, authorities or
              external dispute resolution bodies such as the Australian
              Financial Complaints Authority (AFCA) in the event of a complaint.
            </p>
            <p>
              Accessing Or Correcting Your Information: You can request access
              to the information we store about you or ask us to correct or
              update your information. We may charge a fee for access.
            </p>
            <h3>Opting Out:</h3>
            <p>
              You may opt out of direct marketing at any time. Information on
              how to do this is provided below.
            </p>
            <p>
              We may update this Privacy Policy from time to time. We encourage
              you to periodically review this Privacy Policy on our website so
              that you will be aware of our current privacy practices. You can
              contact us for more information at
              <a href="mailto:Privacy@tokiomarine.com.au"
                >Privacy&#64;tokiomarine.com.au</a
              >
            </p>
          </div>

          <div class="page-content-info">
            <h3>More Details On How We Handle Your Personal Information</h3>
            <h3>Who We Are:</h3>
            <p>
              We are Tokio Marine Management (Australasia) Pty Ltd ABN 69 001
              488 455 (TMMA), the managing agent in Australia for Tokio Marine
              &amp; Nichido Fire Insurance Co., Ltd ABN 80 000 438 291.
              References to Tokio Marine Group include all related group
              companies owned or under the control of Tokio Marine Holdings,
              Inc. (Related Companies).
            </p>
          </div>

          <div class="page-content-info">
            <h3>Types of Personal Information we collect</h3>
            <p>
              We collect Personal Information about our customers and
              prospective customers, our employees and contractors, (including
              candidates that apply for a role with us), and contact details of
              persons from our business partners, suppliers and service
              providers in a number of different ways including the following:
            </p>
            <ul class="text-gray">
              <li>
                when you apply for or purchase our products or services — your
                name, contact details (address, phone and fax number, email
                address), date of birth, gender and payment details. We may also
                collect information that is specific to a particular product,
                such as your car registration for car insurance; or
              </li>
              <li>
                when you apply for a job with us — your name, contact details
                (address, phone and fax number, email address), date of birth,
                gender, education and employment history, professional
                associations, resume and references, background and police
                checks. If you are subsequently employed, we may also collect
                financial details such as your tax file number and bank account
                details.
              </li>
            </ul>
            <p>
              We may also collect the following information where needed from
              time to time:
            </p>
            <ul class="text-gray">
              <li>
                proof of identity information, such as your driver licence or
                other similar identity information;
              </li>
              <li>
                information regarding your preferences regarding our products
                and services;
              </li>
              <li>
                details regarding the transactions you undertake in respect of
                our business; or
              </li>
              <li>
                other information necessary to provide you with information
                regarding our products or services or undertake any transactions
                or dealings with you.
              </li>
            </ul>
            <p>
              You may be able to deal with us without providing personal
              information (such as when making an enquiry). However, if you
              don't give us information then we may be unable to provide
              products or services, or your use/access to these products or
              services may be limited.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Sensitive Information</h3>
            <p>
              On occasion we may also collect other kinds of Personal
              Information as permitted or required by law including sensitive
              information. ‘Sensitive Information’ is a subset of personal
              information and may include among other things; information or an
              opinion about your religious beliefs, criminal record or
              membership of professional or trade associations. The kinds of
              Sensitive Information we may collect generally include the
              following; (where it is relevant to underwriting an insurance
              policy or dealing with, managing, or processing a claim):
            </p>
            <ul class="text-gray">
              <li>criminal record;</li>
              <li>health information;</li>
              <li>past claims history; or</li>
              <li>driving history, certifications and insurance details.</li>
            </ul>
            <p>
              We will only collect and hold such Sensitive Information where
              reasonably necessary to perform our engagement and with your
              consent.
            </p>
          </div>

          <div class="page-content-info">
            <h3>How do we collect this information</h3>
            <p>
              Unless it is not reasonable or practicable under the
              circumstances, we will collect information directly from you when
              you use our website, or when you contact us through telephone,
              email or online. We may also collect your personal information
              from:
            </p>
            <ul class="text-gray">
              <li>
                your insurance broker, your legal advisors, or your family
                member who applies for a policy that covers you or is instructed
                by you to deal with us;
              </li>
              <li>
                policy holders or others who are authorised or noted on the
                policy as having a legal interest in it, including where you are
                an insured person under the policy but not the policy holder or
                another insured where the policy is issued in joint names;
              </li>
              <li>
                third parties who you have asked to provide your Personal
                Information to us;
              </li>
              <li>our Related Companies;</li>
              <li>
                our agents, authorised representatives, distributors and
                referrers;
              </li>
              <li>
                our service providers (which may also include overseas service
                providers);
              </li>
              <li>other insurers and reinsurers;</li>
              <li>
                people who are involved in a claim or assist us in assessing,
                investigating, processing or settling claims, including another
                party involved in the claim or third parties claiming under your
                policy, your travel consultant/agent, travel service providers,
                airlines, hotels, providers of transportation, providers of
                medical and non-medical assistance and services, your employer;
              </li>
              <li>
                statutory authorities or government departments, for example,
                law enforcement;
              </li>
              <li>
                external dispute resolution bodies, for example, AFCA, the
                Office of Information Privacy Commissioner (OAIC) or the Human
                Rights Commission;
              </li>
              <li>
                third party recruitment agencies and service providers,
                background or police checks, LinkedIn, other third parties you
                have authorised to deal with us, and from referees you have
                given us permission to contact;
              </li>
              <li>our legal or other advisers;</li>
              <li>
                social media and other virtual communities and networks where
                people create, share or exchange information;
              </li>
              <li>
                clubs, associations, member loyalty or rewards program providers
                and other relevant organisations;
              </li>
              <li>
                publicly available sources of information such as the internet
                and telephone directories;
              </li>
              <li>
                data partners, analytic consultants and other similar
                organisations;
              </li>
              <li>
                agents or subcontractors of any of the above third parties; or
              </li>
              <li>
                any other organisation or person where you have consented to
                them providing your personal information to us or consented to
                us obtaining your personal information from them.
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>
              Are there any other ways we can collect your personal information?
            </h3>
            <p>
              We may also collect information by online means when you visit our
              public websites or affiliated websites (such as those administered
              by agents offering Tokio Marine products or services) through the
              use of cookie technology. You can control and/or delete cookies as
              you wish. You can delete all cookies that are already on your
              computer and you can set most browsers to prevent them from being
              placed. If you do this, however, you may have to manually adjust
              some preferences every time you visit a site and some services and
              functionalities may not work. We may otherwise collect personal
              information from Tokio Marine or affiliated websites to fulfil
              your request for products and services and to improve your online
              experience. We always endeavour to limit the amount of information
              collected to support the intended purpose of the collection.
            </p>
            <p>
              We may ask you for some or all of the following types of
              information when you register for events, request services, manage
              accounts, access various content and features or directly visit
              our websites or affiliate websites:
            </p>
            <ul class="text-gray">
              <li>
                contact information, such as name, e-mail address, postal
                address, phone number and mobile number; username, password,
                password reminder questions and password answers;
              </li>
              <li>
                communication preferences, such as which newsletters you would
                like to receive
              </li>
              <li>search queries; or</li>
              <li>
                contact information about others when you refer a friend to a
                particular site or service; (this information is used solely to
                facilitate requested communications).
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>If you provide information about others</h3>
            <p>
              If you provide us with information about other individuals (such
              as employees, dependents etc.) where appropriate, you should
              provide these individuals with a copy of this Privacy Policy
              beforehand or ensure they are otherwise made aware of how their
              information will be used by Tokio Marine.
            </p>
          </div>

          <div class="page-content-info">
            <h3>How can your personal information be used?</h3>
            <p>
              We use your personal information in order to provide you with
              products and services as detailed below. Your information may be
              used for the following purposes:
            </p>
            <ul class="text-gray">
              <li>
                to provide information, products or services you requested;
              </li>
              <li>
                to determine your eligibility and process applications for
                products and services that you have requested;
              </li>
              <li>
                to provide information and services as requested by clients;
              </li>
              <li>
                to understand and assess your ongoing needs and offer products
                and services to meet those needs;
              </li>
              <li>
                to carry out client communication, service, billing and
                administration;
              </li>
              <li>to administer claims and complaints;</li>
              <li>to conduct data analysis, surveys and other evaluations;</li>
              <li>
                to obtain and update credit information with appropriate third
                parties, such as credit reporting agencies, where transactions
                are made on credit;
              </li>
              <li>
                to execute monitoring and training for quality assurance
                purposes and security issues;
              </li>
              <li>to develop new services;</li>
              <li>to market products and services;</li>
              <li>
                to conduct processing necessary to fulfil other contractual
                obligations;
              </li>
              <li>
                complying with, and assisting our Related Companies, agents,
                business partners, distributors and insurance advisers in
                complying with, any applicable law, code (including the General
                Insurance Code of Practice) or regulation, and complying with
                any requests from courts, government departments, law
                enforcement agencies and regulators, including but not limited
                to the Australian Prudential Regulatory Authority (APRA) and the
                Australian Securities and Investment Commission (ASIC); and
              </li>
              <li>
                other purposes communicated to you at the time we collected your
                personal information or as required by law.
              </li>
            </ul>
            <p>
              Your personal information will also be used by us to consider your
              applications for employment, (if applicable), and manage the
              recruitment processes. If you are not successful in obtaining a
              position with us, we may retain your application and personal
              information in order to contact you if a suitable position becomes
              available in the future.
            </p>
            <p>
              We will only use and disclose your Sensitive Information for the
              purpose it was collected or for any other directly related purpose
              that you would reasonably expect us to use it for to the extent
              permissible under law. With your consent, we may use or disclose
              your information for additional purposes from time to time.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Who do we share your personal information with?</h3>
            <p>
              We may disclose your information to other companies within the
              Tokio Marine Group and the affiliates or third-party service
              providers to assist us in providing, managing and administering
              our services and products including where we outsource certain
              functions. This may include:
            </p>
            <ul class="text-gray">
              <li>
                insurance services and insurance products - business partners,
                including insurers, reinsurers, insurance agents, insurance
                brokers, other insurance intermediaries, insurance reference
                bureaus, medical and health service providers, case managers and
                rehabilitation consultants, fraud detection agencies,
                investigators, other advisers such as loss adjusters, lawyers,
                auditors, actuaries and accountants and others involved in the
                claim handling process;
              </li>
              <li>
                banking and finance products - business partners, including
                credit and fraud reporting agencies, debt collection agencies,
                insurers and reinsurers, any Authorised Representatives;
              </li>
              <li>authorised service providers;</li>
              <li>
                service providers engaged for outsourced functions such as
                market research, direct marketing, claims handling and
                recruitment;
              </li>
              <li>
                external IT service providers, infrastructure and other third
                parties where required by law; and
              </li>
              <li>
                entities related to the Tokio Marine Group for the purpose of
                offering you other products and services, (where you have not
                elected to opt-out of receiving such information).
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>Sharing Personal Information Overseas</h3>
            <p>
              In accordance with the purposes for which we have collected your
              personal information, we may disclose personal information to a
              party located outside Australia such as our affiliated entities,
              business partners and off shore service providers. The countries
              where these recipients are located can change from time to time
              but may include Japan, New Zealand, Singapore, Thailand, United
              Kingdom, the United States of America and India.
            </p>
            <p>
              Disclosure may also be made to government, law enforcement,
              dispute resolution, statutory or regulatory bodies in relevant
              countries, or as required by law.
            </p>
            <p>
              We will make every reasonable effort to ensure that we only have
              business dealings with third parties that value privacy and
              information security the same way as us and will take reasonable
              steps to ensure overseas recipients provide the same level of
              protection for your information using appropriate safeguards such
              as standard contractual clauses.
            </p>
          </div>
          <div class="page-content-info">
            <h3>Can your information be used for direct marketing?</h3>
            <p>
              Unless you notify us otherwise, we may use your personal
              information to let you know about products and services from
              across the Tokio Marine Group or our affiliates and business
              partners that we think may be of interest to you to the extent
              permitted by law. If you would prefer not to receive marketing you
              can contact us at
              <a href="mailto:Privacy@tokiomarine.com.au"
                >Privacy&#64;tokiomarine.com.au</a
              >
              to confirm your preference.
            </p>
          </div>
          <div class="page-content-info">
            <h3>How do we hold your information?</h3>
            <p>
              We may hold your Personal Information in a number of ways,
              including:
            </p>
            <ul class="text-gray">
              <li>
                in our secure computer systems or databases, which may involve
                storing data on storage or computer systems provided by third
                party suppliers;
              </li>
              <li>in paper records; or</li>
              <li>in telephone recordings.</li>
            </ul>
            <p>
              Where it has been collected from our or your agent, or our service
              providers, they may also hold copies of your personal information.
            </p>
          </div>
          <div class="page-content-info">
            <h3>Protecting your stored information</h3>
            <p>
              While we store your personal information, we take all reasonable
              steps to protect it by way of appropriate physical, electronic,
              and procedural safeguards. For example; we implement and impose
              security measures for access to our systems, operate security
              measures for our buildings, impose confidentiality requirements on
              our employees, provide training on keeping personal information
              secure and keep personal information only for as long as we
              require it for our business needs or as required by law.
            </p>
          </div>
          <div class="page-content-info">
            <h3>Accessing and correcting information</h3>
            <p>
              We take reasonable steps to ensure your personal information that
              we collect, hold and disclose is accurate, up to date and
              complete. However, we also rely on you to let us know of any
              changes or corrections required to your information. You should
              contact us at any time to update your personal information or
              advise us that the information we hold is not accurate, up to date
              or complete. If for any reason we cannot provide you with access,
              we will provide you with the reason for refusal. In all cases you
              will be asked to verify who you are before your personal
              information is provided.
            </p>
            <p>
              We may also charge you a fee for our reasonable costs of
              retrieving and supplying the information to you.
            </p>
          </div>
          <div class="page-content-info">
            <h3>Privacy Complaints</h3>
            <p>
              If you have any questions or wish to raise a complaint about a
              breach of this Privacy Policy, you may contact our Privacy Officer
              directly on the contact details below. We will respond to your
              question, suggestion or complaint as soon as possible.
            </p>
            <p><strong>Tokio Marine Privacy Officer Contact Details</strong></p>
            <p>
              <strong>Attn: Privacy Officer</strong><br />
              Tokio Marine &amp; Nichido Fire Insurance Co. Ltd<br />
              GPO Box 4616<br />
              Sydney NSW 2001<br />
              Telephone: <a href="tel:02 9232 2833">02 9232 2833</a><br />
              Email:
              <a href="mailto:Privacy@tokiomarine.com.au"
                >Privacy&#64;tokiomarine.com.au</a
              >
            </p>
            <p>
              If you are not satisfied with our response to your enquiry or
              complaint you can contact the Privacy Officer and request that it
              be reviewed by the dispute resolution team who will endeavour to
              resolve your dispute within 30 days from when you first notified
              us. If you are still not satisfied with the outcome of your
              complaint to us, you can contact the Australian Financial
              Complaints Authority (AFCA) by sending a complaint form to:
            </p>
            <p>
              Post: Australian Financial Complaints Authority <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GPO Box 3 <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Melbourne VIC
              3001<br />
              Phone: <a href="tel:1800931678">1800 931 678</a><br />
              Email: <a href="mailto:info@afca.org.au">info&#64;afca.org.au</a
              ><br />
              Web:
              <a
                rel="noopener noreferrer"
                href="https://www.afca.org.au/"
                target="_blank"
                >www.afca.org.au</a
              >
            </p>
            <p>
              Alternatively, you can contact the Office of the Australian
              Information Commissioner <strong>(OAIC)</strong> by sending a
              complaint form to:
            </p>
            <p>
              Post: Office of the Australian Information Commissioner <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GPO Box 5218
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sydney NSW 2001<br />
              Fax: <a href="tel:02 6123 5145">02 6123 5145</a><br />
              Email:
              <a href="mailto:enquiries@oaic.gov.au"
                >enquiries&#64;oaic.gov.au</a
              ><br />
              Web:
              <a
                rel="noopener noreferrer"
                href="https://www.oaic.gov.au/"
                target="_blank"
                >www.oaic.gov.au</a
              >
            </p>
            <p>
              Please note the OAIC requires any complaint must first be made to
              the respondent organisation. The law also allows 30 days for the
              respondent organisation to deal with the complaint before a person
              may make a complaint to the OAIC.
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
