<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'WhatsCovered'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/ltx-essentials-cover-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Essentials Cover</h1>
      <p>
        Our essentials cover can help to provide you with a peace of mind for
        costs related to medical emergency, travel delays, lost luggage and more
        while traveling overseas.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <p>
              When packing for your trip, it is crucial you don’t forget the
              essentials. Your essentials may include some form of
              identification, toiletries, medications, and ample clothing. But
              what about <a href="/travel-insurance">travel insurance</a>?
              Making sure you purchase the right amount of cover for your trip
              can be crucial. World2Cover’s essentials travel insurance package
              provides cover that could be just as important as remembering to
              pack your toothbrush and your swimmers.
            </p>
          </div>
          <div class="page-content-info">
            <h3>What is covered with essentials travel insurance?</h3>
            <p>
              When it comes to essentials travel insurance, World2Cover provides
              you with added peace of mind on a wide range of items. From
              cancellation fees and lost deposits to overseas medical expenses,
              we have a level of cover that might be right for you.
            </p>
            <p>
              Our new for old policy feature is one of the many offered benefits
              on our essential cover policy. New for old means that we keep the
              same level of value of your damaged items intact. For example, if
              you’re entitled to a replacement of damaged luggage, we will
              replace it with brand new luggage<sup>#</sup> up to the maximum
              amounts and specific limits of coverage. Please note that a pair
              or set of items is treated as one item (e.g. a pair of earrings, a
              camera body and its standard lens and accessories, or a set of
              golf clubs or a drone with its camera).
            </p>
          </div>

          <div class="page-content-info">
            <h3>
              Summary of covered items<sup><small>#</small></sup>
            </h3>
            <div class="card">
              <div class="card-body">
                <ul class="check-circle">
                  <li>$unlimited** Overseas medical and hospital expenses</li>
                  <li>
                    $unlimited** emergency medical &amp; evacuation expenses
                  </li>
                  <li>Cash in Hospital<sup>#</sup> (up to $3,500)</li>
                  <li>Overseas Dental<sup>#</sup> (up to $2,000)</li>
                  <li>24/7 global Medical emergency assistance</li>
                  <li>Emergency expenses<sup>#</sup> (up to $25,000)</li>
                  <li>Luggage<sup>#</sup> loss, theft, damage or delayed</li>
                  <li>Travel delay (up to $1,500)</li>
                  <li>Travel documents &amp; personal money</li>
                  <li>Rental vehicle excess<sup>#</sup></li>
                  <li>Accidental Death* &amp; Funeral expenses Overseas*</li>
                  <li>Legal liability^<sup>~</sup> (up to $10,000,000)</li>
                  <li>
                    38 Listed existing medical conditions covered<sup>~</sup>
                  </li>
                  <li>
                    Optional Ski and Winter Sports<sup>#</sup> such as
                    recreational skiing and snowboarding may be purchased for an
                    additional premium
                  </li>
                </ul>

                <div class="bg-light p-3 rounded font-12">
                  # terms and conditions, exclusions, limits and sub-limits
                  apply<br />
                  <span
                    >** $unlimited means that generally there is no cap on the
                    maximum dollar amount which may be paid out of this benefit,
                    subject to the specific terms and conditions, sub-limits and
                    exclusion apply to this benefit. This benefit covers
                    reasonable overseas medical and hospital costs as a result
                    of an injury (including arising from a terrorist act) or
                    illness occurring which first shows itself during your
                    period of insurance. Benefits may be paid up to 12 months
                    from the time you received treatment for the injury or
                    illness, but only for reasonable expenses incurred during
                    that time. All medical treatments must be provided by your
                    treating doctor or our consulting medical officer. You must
                    notify us as soon as possible of your admittance to
                    hospital.&nbsp;</span
                  >&nbsp;<br />
                  * This cover is per person listed in Your Policy<br />
                  ^ This cover is per policy<br />
                  ~ Policy criteria and conditions apply
                </div>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Who does essentials travel insurance cover?</h3>
            <p>
              Essentials travel insurance offers coverage for both singles and
              families. Singles include one adult between the ages of 18 and 75
              and their declared dependents.
              <a href="/family-travel-insurance">Family cover</a> includes any
              two adults named on the policy travelling together and their
              declared dependents. Dependents who are not working full-time, are
              aged 25 and under and travelling with you for the entire trip can
              be listed on the single and family travel insurance package. This
              is included at no additional cost, unless they have an existing
              medical condition to declare (in which case an additional premium
              may be required).
            </p>
            <p>
              Cover is available to Australian residents under two conditions:
              your trip begins and ends in Australia and you purchase your
              policy ahead of your trip. Temporary residents may also obtain
              cover under certain stipulations, please refer to our
              <a href="/pds" target="_blank">Product Disclosure Statement.</a>
            </p>
            <p class="font-12">Policy criteria and conditions apply</p>
          </div>

          <div class="page-content-info">
            <h3>How much does essentials travel insurance cost?</h3>
            <p>
              Travel insurance costs depend on a variety of factors including
              where you are travelling, the duration of your holiday, and more.
              If you lodge a claim on our essentials travel insurance package,
              the standard excess is $200 AUD or you can reduce your excess to
              $100 or $0 by paying extra. Excess is charged on a per incident
              basis instead of per person. Get personalised pricing instantly
              online or give our customer service team a call at
              <a href="tel:02 9225 7599">02 9225 7599.</a>
            </p>
          </div>

          <div class="page-content-info">
            <h3>Cooling-off period and extending your cover</h3>
            <p>
              You are able to cancel your policy at no cost to you 21 days from
              the date of issue, provided the trip has not started and you don’t
              want to make a claim or exercise any other right under the policy.
            </p>
            <p>
              Your trip can be extended free of charge if your departure or
              return is delayed due to a reason covered under the policy. For
              more information on your policy, please visit the
              <a href="/pds" target="_blank">Product Disclosure Statement</a>
              section on our website.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Emergency expenses</h3>
            <p>
              World2Cover travel insurance can provide cover for expenses
              related to accommodation, meals and travel expenses<sup>#</sup> if
              your trip is disrupted due to serious injury, death in your or
              travelling companion’s family, natural disaster, civil unrest or
              riots, or other reason you can claim for under this section.
              Please visit the
              <a href="/pds" target="_blank">Product Disclosure Statement</a>
              section 4 on our website.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Why consider World2Cover?</h3>
            <p>
              World2Cover offers specially designed levels of cover to assist
              with your travel insurance needs and can help to provide you with
              peace of mind. We strive to provide superior customer service,
              travel insurance packages, and instant quotes. You have heaps of
              places to go, people to see, and adventures to experience. Take
              advantage of our packages because, as we know, you have the world
              to cover. Give us a call at
              <a href="tel:02 9225 7599">02 9225 7599</a> or get an instant
              online quote today!
            </p>
            <p class="desclaimer" style="line-height: 10px">
              <small
                ># terms and conditions, exclusions, limits and sub-limits
                apply<br />
              </small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
