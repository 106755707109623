import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-travel-medical-insurance',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './travel-medical-insurance.component.html',
  styleUrl: './travel-medical-insurance.component.scss',
})
export class TravelMedicalInsuranceComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle(
      'Travel Medical Insurance | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content:
        'World2Cover travel medical insurance provides the extra protection international travelers need, from emergency expenses to overseas dentist and hospital visits. Receive a free quote today to review which policy is right for you!',
    });
  }
}
