<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/contact-us-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Contact Us</h1>
      <p>
        World2Cover provide a range of contact options depending on whether
        you’re travelling, or back home in Australia.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3>Policies and General Enquiries</h3>
            <ul class="link list-group">
              <li>
                <strong>Opening Hours:</strong>
                <ol class="text-gray">
                  <li>Monday to Friday 8.00am-9.00pm AEST</li>
                  <li>Saturday to Sunday 9.00am-6.00pm AEST</li>
                </ol>
              </li>
              <li>
                <strong>Phone: </strong>
                <a href="tel:0292257599">02 9225 7599</a>
              </li>
              <li>
                <strong>Email us at: </strong>
                <a href="mailto:travelservice@world2cover.com.au"
                  >travelservice&#64;world2cover.com.au</a
                >
              </li>
              <li>
                <strong>Head Office: </strong>
                <span class="post-text">
                  <span>Tokio Marine Management (Australasia) Pty Ltd</span
                  ><br />
                  <span>Level 17, 60 Margaret Street,</span><br />
                  <span>Sydney, NSW 2000, Australia</span>
                </span>
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>Lodge a Claim Online</h3>
            <p>
              The quickest way to lodge a claim with World2Cover is using our
              online claim lodgement link available 24/7 and follow the steps
              below:
            </p>
            <ol class="text-gray">
              <li>
                Register by utilising the email address you used to purchase
                your policy
              </li>
              <li>
                Once registered you will receive an email to guide you to set up
                your password
              </li>
              <li>
                If you have previously registered a claim just login using your
                email and password
              </li>
              <li>
                Once you have logged in, complete your claim details by
                answering all sections
              </li>
              <li>
                Once submitted, your claim will be assessed within 10 working
                days and we will let you know if we require any further
                information/documentation from you
              </li>
            </ol>

            <div>
              <a
                target="_blank"
                href="https://webapps.tokiomarine.com.au/WTCC/Account/Register"
                class="btn btn-outline-info btn-lg px-5 me-2"
                >Register</a
              >
              <a
                target="_blank"
                href="https://webapps.tokiomarine.com.au/WTCC/Account/Login"
                class="btn btn-info btn-lg px-5 button-text-color"
                >Login</a
              >
            </div>
          </div>

          <div class="page-content-info">
            <h3>Claims Enquiries</h3>
            <ul class="link list-group">
              <li>
                <strong>Opening Hours:</strong>
                <ol class="text-gray">
                  <li>Monday to Friday 8.00am-9.00pm AEST</li>
                  <li>Saturday to Sunday 9.00am-6.00pm AEST</li>
                </ol>
              </li>
              <li>
                <strong>Phone: </strong
                ><a href="tel:0292257599">02 9225 7599</a>
              </li>
              <li>
                <strong>Email us at: </strong>
                <a href="mailto:travelclaims@world2cover.com.au"
                  >travelclaims&#64;world2cover.com.au</a
                >
              </li>
              <li>
                <strong>Write to us at: </strong
                ><span class="post-text"
                  >World2Cover Claims<br />
                  GPO Box 4616<br />
                  Sydney NSW 2001</span
                >
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>Emergency Medical Assistance Overseas (24/7)</h3>
            <p>
              In case of a Medical emergency overseas, please contact us as soon
              as possible at:
            </p>
            <h5 class="fw-semibold">From Japan:</h5>
            <p>
              <span class="fw-semibold">Call</span>
              <a href="tel:08008009117" class="font-20 mx-2 fw-semibold"
                >0800 800 9117</a
              >
              (toll free) or if mobile access is restricted call
              <a href="tel:0362285881" class="font-20 mx-2 fw-semibold"
                >03 6228 5881</a
              >
              (local number in Japan)
            </p>
            <h5 class="fw-semibold">All other overseas destinations:</h5>
            <p>
              <a href="tel:+61280551683" class="font-20 mx-2 fw-semibold"
                >+61 2 8055 1683</a
              >
              (reverse charges accepted from the overseas operator)
            </p>
          </div>

          <div class="page-content-info">
            <h3>Complaints</h3>
            <p>
              You are entitled to make a complaint to Us about any aspect of
              Your relationship with Us.
            </p>
            <p>
              We are committed to resolving any complaint or dispute fairly and
              as quickly as possible. If you are dissatisfied with our service
              in any way, please contact us and we will do our best to resolve
              your concerns as soon as reasonably possible. If we are unable to
              or if you are still not satisfied, our Customer Complaints Team
              will review your complaint and provide you with a response.
            </p>
            <p>
              When you make a complaint, please provide us with as much
              information as possible. You can contact us to make a complaint,
              or if you require assistance to lodge a complaint, using the
              contact details provided below:
            </p>
            <p>
              <strong>Post: </strong
              ><span class="post-text"
                >Tokio Marine & Nichido Fire Insurance Co. Ltd<br />
                GPO Box 4616<br />
                SYDNEY NSW 2001</span
              >
              <br />
              <strong>Email: </strong>
              <a href="mailto:Complaints@tokiomarine.com.au"
                >Complaints&#64;tokiomarine.com.au</a
              ><br />
              <strong>Phone: </strong> <a href="tel:0292257599">02 9225 7599</a>
            </p>
            <p>
              If you are not satisfied with our response to your complaint, or
              we have taken more than 30 days to respond to you from the date
              you first made your complaint, you may be eligible to escalate the
              matter to the Australian Financial Complaints Authority (AFCA) if
              your matter is within the jurisdiction as set out in their Rules.
              AFCA is an independent external disputes resolution provider who
              can assess your matter free of charge and can issue a binding
              outcome on us.
            </p>
            <p>If Your problem is not resolved</p>
            <p>
              If You disagree with Our decision, You can appeal to the
              Australian Financial Complaints Authority (AFCA). We will advise
              You how to do this and provide all relevant assistance.
            </p>
            <p>
              AFCA is an independent industry dispute resolution scheme. The
              decisions made by AFCA are binding on Us provided You agree. You
              do not have to accept any decision that We or AFCA makes. You
              always have the option of seeking other solutions.
            </p>
            <p>
              You can contact the Australian Financial Complaints Authority:
            </p>
            <p>
              <strong>Online: </strong>
              <a
                rel="noopener noreferrer"
                href="https://www.afca.org.au/"
                target="_blank"
                >www.afca.org.au</a
              >
              <br />
              <strong> Email: </strong><span> </span
              ><a href="mailto:info@afca.org.au">info&#64;afca.org.au</a> <br />
              <strong>Phone: </strong><span> </span
              ><a href="tel:1800931678">1800 931 678 (local call cost) </a
              ><br />
              <strong>Mail: </strong
              ><span class="post-text"
                >Australian Financial Complaints Authority<br />
                GPO Box 3 <br />Melbourne VIC 3001</span
              >
              <br />
            </p>
          </div>

          <div class="page-content-info">
            <h3>Media Enquiries</h3>
            <ul class="link list-group">
              <li>
                <strong>Phone: </strong>
                <a href="tel:0292257599">02 9225 7599</a>
              </li>
              <li>
                <strong>Email: </strong>
                <a href="mailto:travelservice@world2cover.com.au"
                  >travelservice&#64;world2cover.com.au</a
                >
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>Send Us an Email</h3>
            <p>
              For any travel insurance comments or if you want to share your
              experience with us please send us an email at
              <a href="mailto:travelservice@world2cover.com.au">
                travelservice&#64;world2cover.com.au</a
              >
            </p>
            <p>
              Please see the
              <span style="text-decoration: underline"
                ><a href="/pds" target="_blank"
                  >Product Disclosure Statement</a
                ></span
              >
              for full terms and conditions and exclusions and limits that
              apply.
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
