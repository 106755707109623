import { Component } from '@angular/core';

@Component({
  selector: 'app-w2c-offering',
  standalone: true,
  imports: [],
  templateUrl: './w2c-offering.component.html',
  styleUrl: './w2c-offering.component.scss'
})
export class W2cOfferingComponent {

}
