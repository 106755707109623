<div>
  <!-- Modal Expired Quote -->
  <div class="modal show" [ngClass]="{ mobileBg: isMobile }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <span>
            This quote is no longer valid due to a platform update. Please
            arrange a new quote through
            <a href="/">www.world2cover.com.au</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
