<div>
  <div class="card theme-steps">
    <section class="header position-fixed w-100">
      <app-header></app-header>
    </section>
    <!-- End Header Part -->
    <!-- Start Page Header Title Part -->
    <section class="page-header-title payment-title-section">
      <div class="page-title-overlay"></div>
      <img
        src="assets/images/w2c/ltx-payment-receipt-banner.png"
        alt=""
        class="w-100 page-title-image"
      />
      <div class="page-header-title-caption">
        <h1>Payment Receipt</h1>
      </div>
    </section>
    <!-- End Page Ttile  -->

    <!-- Main Content -->
    <main class="page-content py-5">
      <!-- Payment Loading Spinner -->
      <!-- <section id="payment-loading" style="height: 100vh;" >
		<div class="container mt-3 p-3 ">
			<div class="row text-center">
				<div class="col-md-12 p-4">
					<h3>Payment Gateway</h3>
					<div class="gateway-icon">
						<img src="assets/icons/w2c/circle-notch.png" alt="">
					</div>
					<h4 class="fw-bold ">We are processing your payments...</h4>
					<p class="offc label mb-1">Do not close this window or refresh or click back, else your transaction may fail</p>
				</div>
			</div>						
		</div>
	</section> -->

      <section id="loading" style="height: 100vh" *ngIf="isPaymentLoading">
        <div class="container mt-3 p-3">
          <div class="row text-center">
            <div class="col-md-12 p-4">
              <h3>Payment Gateway</h3>
              <div class="gateway-icon">
                <img src="assets/icons/w2c/circle-notch.png" alt="" />
              </div>
              <h4 class="fw-bold">We are processing your payments...</h4>
              <p class="offc label mb-1">
                Do not close this window or refresh or click back, else your
                transaction may fail
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="payment-success" *ngIf="!isPaymentLoading">
        <!-- <section id="payment-success"> -->
        <div class="container">
          <div *ngIf="!agentGuid" class="row mt-md-4 mb-4">
            <div class="col">
              <div class="d-flex align-items-center bg-light rounded py-2">
                <div class="p-3">
                  <img src="assets/icons/w2c/celebration.png" alt="" />
                </div>
                <h3 class="ms-3" style="font-weight: 700; font-size: 24px">
                  Thank you for purchasing World2Cover Travel Insurance
                </h3>
              </div>
            </div>
          </div>
          <div *ngIf="agentGuid" class="row mt-md-4 mb-4">
            <div class="col mx-2">
              <div class="row align-items-center bg-light rounded py-2">
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <div class="p-3">
                    <img src="assets/icons/w2c/celebration.png" alt="" />
                  </div>
                  <h3 class="ms-3" style="font-weight: 700; font-size: 24px">
                    Thank you for purchasing World2Cover Travel Insurance
                  </h3>
                </div>
                <div class="col-12 col-lg-3 pe-3" style="text-align: right;">
                  <a
                    *ngIf="agentGuid"
                    class="btn btn-info btn-lg view-policy"
                    [href]="goAdminLink"
                    target="_blank"
                    ><img src="assets/icons/w2c/open-link.png" alt="" width="24" />
                    View Policy For Admin</a>
                </div>
              </div>
            </div>
          </div>

          <div class="row g-0">
            <div class="col-lg-7 pe-md-4">
              <div class="page-content-info">
                <p>
                  Your certificate of insurance and payment receipt is provided
                  here and a copy has been sent to:
                </p>
                <p class="fw-bold">
                  <a
                    class="text-dark"
                    href="mailto:{{
                      travelerDetails?.contactAndAddressDetails.emailId
                    }}"
                    >{{ travelerDetails?.contactAndAddressDetails.emailId }}</a
                  >
                </p>
              </div>

              <div class="page-content-info">
                <p>
                  If you have any questions about your policy feel free to call
                  us on <a href="tel:0292257599">02 9225 7599</a> and
                  quote your policy number. If you have a Hotmail, Bigpond,
                  Gmail or Outlook account the policy email may be blocked by
                  your firewall or email provider. Please check your spam folder
                  and contact us if you have not received your insurance
                  documentation within 24 hours.
                </p>
              </div>

              <div class="page-content-info">
                <h3>Emergency Assistance</h3>
                <p>
                  In the event you need emergency medical assistance when
                  travelling you can call our worldwide emergency assistance
                  number:
                </p>
                <div class="card mb-3 rounded-10">
                  <div class="card-body">
                    <p class="fw-bold text-dark mb-2">For Japan call:</p>
                    <p>
                      <a href="tel:08008009117" class="font-20 fw-bold"
                        >0800-800-9117</a
                      >
                      <span class="text-gray"> (toll free)</span>
                    </p>
                    <p class="text-gray">
                      or if mobile access is restricted call
                    </p>
                    <p>
                      <a href="tel:0362285881" class="font-20 fw-bold"
                        >03 6228-5881</a
                      >
                    </p>
                  </div>
                </div>

                <div class="card mb-3 rounded-10">
                  <div class="card-body">
                    <p class="fw-bold text-dark mb-2">
                      For all other countries dial reverse charge (collect) via
                      the local operator
                    </p>
                    <p>
                      <a href="tel:+61280551683" class="font-20 fw-bold"
                        >+61 2 8055 1683</a
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="page-content-info">
                <h3>Pre-existing Medical Conditions</h3>
                <p>
                  There are a limited number of Pre-existing Medical Conditions
                  covered under this policy. If you have a Pre-existing Medical
                  Condition you must review the
                  <a target="_blank" href="/pds"
                    >Combined Product Disclosure Statement and Financial Service
                    Guide</a
                  >
                  to make sure that the policy is right for you. if you have any
                  questions please give us a call on
                  <a href="tel:0292257599">02 9225 7599</a>. <br />
                  Any specific pre-existing medical conditions you have listed
                  and we have offered cover for which you have accepted by
                  paying any applicable premium, will be shown on your
                  Certificate of Insurance. Any pre-existing medical conditions
                  you have listed that we have not offered cover for, or you
                  have not accepted cover for, will also be shown on your
                  Certificate of Insurance.
                </p>
              </div>

              <div class="page-content-info">
                <h3>High Risk Countries</h3>
                <p>
                  This policy excludes travel to any country with the advice of
                  "Do not travel on the
                  <a target="_blank" href="https://www.smartraveller.gov.au/"
                    >Smartraveller Website.</a
                  >
                </p>
              </div>
            </div>

            <!-- Aside Part / Sidebar -->
            <div class="col-lg-5">
              <div class="page-sidebar">
                <div class="p-4 bg-light rounded-10">
                  <img class="mainlogo" src="assets/icons/w2c/svg-logo.png" />
                  <h3 class="my-3" style="font-weight: 700; font-size: 24px">
                    Certificate of Insurance
                  </h3>
                  <table class="table table-borderless">
                    <tr>
                      <td>Policy Number</td>
                      <td class="fw-bold">{{ policyNumber }}</td>
                    </tr>
                    <tr>
                      <td>Policy Type</td>
                      <td class="fw-bold">{{ quoteDetail?.productAlias }}</td>
                    </tr>
                    <tr>
                      <td>Travel Dates</td>
                      <td class="fw-bold">
                        <p
                          class="fw-semibold mb-1"
                          style="padding-left: 0 !important"
                        >
                          {{ quoteDetail?.fromDate
                          }}<span
                            ><img
                              src="assets/icons/w2c/chevron_right.png" /></span
                          >{{ quoteDetail?.toDate }}
                          <span
                            *ngIf="quoteDetail?.isSingleTrip"
                            class="text-gray ms-1"
                            >{{ travelDays }} days</span
                          >
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Travellers Insured</td>
                      <td class="fw-bold">
                        <ng-container
                          *ngFor="let traveler of insuredTravelerDetails"
                        >
                          {{ traveler.name }}
                          <span class="text-gray">aged {{ traveler.age }}</span>
                          <br />
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>Destination</td>
                      <td class="fw-bold">{{ countryNames }}</td>
                    </tr>
                    <tr>
                      <td>Trip Type</td>
                      <td class="fw-bold">{{ quoteEnquiry?.tripType === 'Single' ? 'Single Trip' : 'Annual Multi Trip' }}</td>
                    </tr>
                    <tr>
                      <td>No. Dependents</td>
                      <td class="fw-bold">
                        {{ quoteEnquiry?.dependentsCount }}
                      </td>
                    </tr>
                    <tr>
                      <td>Cruise</td>
                      <td class="fw-bold">
                        {{ quoteDetail?.isCruise ? 'Yes' : 'No' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ski & Winter Sports</td>
                      <td class="fw-bold">
                        {{ quoteDetail?.isSking ? 'Yes' : 'No' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Your Excess</td>
                      <td class="fw-bold">{{ quoteDetail?.excess }}</td>
                    </tr>
                  </table>
                </div>

                <!-- Spacer -->
                <div class="my-4"></div>

                <div class="p-4 bg-light rounded-10">
                  <h3 class="my-3" style="font-weight: 700; font-size: 24px">
                    Tax Invoice
                  </h3>
                  <p class="fw-bold">{{ formattedInvoiceDate }}</p>
                  <hr />
                  <table class="table table-borderless">
                    <tr>
                      <td>{{ quoteDetail?.productAlias }} Policy</td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums.product | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr *ngIf="!quoteDetail?.isSingleTrip">
                      <td>{{ quoteDetail?.multiTripLength }}
                        Max Length Trip
                      </td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums?.multiTripLength | number : '1.2-2'}}
                      </td>
                    </tr>
                    <tr>
                      <td>Cruise</td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums.cruise | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ski & Winter Sports</td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums.skiing | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Pre-ex Medical Conditions</td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums.medical | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ selectedCover?.selectedPrice?.excess }} Excess</td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums.excess | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr *ngIf="quoteDetail?.premiums.promoCodeDiscount < 0">
                      <td>Promo Discount</td>
                      <td class="fw-bold">
                        -${{
                          -quoteDetail?.premiums.promoCodeDiscount
                            | number : '1.2-2'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>GST</td>
                      <td class="fw-bold">
                        ${{ quoteDetail?.premiums.gst | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Stamp Duty</td>
                      <td class="fw-bold">
                        ${{
                          quoteDetail?.premiums.stampDuty | number : '1.2-2'
                        }}
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td>Total</td>
                      <td class="fw-bold">
                        ${{
                          quoteDetail?.premiums.totalCost | number : '1.2-2'
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- Footer Area -->
    <app-footer></app-footer>
  </div>
</div>
