import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-domestic-cover',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './domestic-cover.component.html',
  styleUrl: './domestic-cover.component.scss',
})
export class DomesticCoverComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle(
      'Domestic Travel Insurance | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content: `World2Cover offers specialised domestic travel insurance in Australia to protect you for cancellations costs, travel delays, lost luggage and more. Visit our website to purchase insurance for your domestic trip or give our expert team a call at 02 9225 7599 today!`,
    });
  }
}
