<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'FAQs'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/travel-insurance-faqs-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Insurance FAQs</h1>
      <p>
        Find answers to the most popular questions about travel insurance in
        Australia.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <p>
              Let’s face it, the topic of travel insurance can be complex.
              Whether you’ve never needed it before or you’ve purchased it and
              have questions, we are here to help. Browse our frequently asked
              questions for answers to your most pressing inquiries and topics
              you haven’t even thought of yet.
            </p>
            <p>
              These FAQ's should be read in conjunction with the
              <a target="_blank" href="/pds"
                >World2Cover Travel Insurance Combined Financial Services Guide
                and Product Disclosure Statement (PDS)</a
              >
              available from World2Cover and attached
              <a target="_blank" href="/pds">here</a>. You should consider the
              PDS before making a decision about whether to acquire or to
              continue to hold this insurance.
            </p>
            <p>
              World2Cover Travel Insurance is a tradename of Tokio Marine &amp;
              Nichido Fire Insurance Co. Ltd. (Tokio Marine &amp; Nichido) ABN
              80 000 438 291, AFSL 246 548.
            </p>
          </div>

          <div class="page-content-info">
            <!-- Accordion -->
            <div class="card mb-3 shadow border-0" style="border-radius: 40px">
              <div class="faqs-accorion accordion" id="faqsAccordion">
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed rounded-top-10"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_01"
                      (click)="toggleCollapse($event, 'faqsContent_01')"
                    >
                      COVID-19 FAQs
                    </div>
                  </h2>
                  <div
                    id="faqsContent_01"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          These FAQs should be read in conjunction with the
                          Product Disclosure Statement (PDS). You should
                          consider the
                          <a target="_blank" href="/pds">PDS</a> for the terms,
                          limits, conditions and exclusions before making a
                          decision about whether to acquire or to continue to
                          hold this insurance.
                        </p>
                        <p>
                          As a standard exclusion under all policies, the
                          country you are travelling must not be subject to a Do
                          Not Travel advice on the
                          <a
                            target="_blank"
                            href="https://www.smartraveller.gov.au/"
                            >Smartraveller website</a
                          >
                          at the time you purchased the policy. This is
                          regardless of any exemption you may have received to
                          travel and your ability to purchase a policy.
                        </p>
                        <p>
                          We have outlined the most commons scenarios here.
                          Please contact us if you have any questions or require
                          any further information. Customers always have a right
                          to lodge a claim for consideration against the terms
                          and conditions of the policy and the individual
                          circumstances.
                        </p>
                        <h5>
                          Am I covered if I need to quarantine once I enter a
                          region/country based on the government or state
                          regulations?
                        </h5>
                        <p>
                          No Cover is available under any of our policies. All
                          policies have a general exclusion relating to claims
                          directly or indirectly arising from any government or
                          public health authority mandatory quarantine or
                          isolation order imposed on you related to border,
                          region or territory travel in response to COVID-19.
                        </p>

                        <h5>
                          Am I covered for medical expenses if I am diagnosed
                          with COVID-19 during my trip?
                        </h5>
                        <ul>
                          <li>
                            Domestic – No medical cover is included on this
                            plan.
                          </li>
                          <li>
                            International – Yes, the Top Cover plan includes
                            unlimited** overseas medical expenses, for reasonable
                            costs you incur due to COVID-19. There is no cover
                            under the Essentials Cover or Basic Cover plans.
                          </li>
                          <li>
                            Annual Multi-Trip – No, no COVID-19 benefits are
                            available on this plan.
                          </li>
                        </ul>

                        <h5>
                          Am I covered for funeral and emergency expenses if I
                          die from COVID-19 during my trip?
                        </h5>

                        <ul>
                          <li>
                            Domestic – There is no medical cover included on
                            this plan.
                          </li>
                          <li>
                            International – Yes Cover is available on the Top
                            cover plan up to a maximum limit of $20,000 per
                            person. There is no cover under the Essentials Cover
                            or Basic Cover plans.
                          </li>
                          <li>
                            Annual Multi-Trip – No, no COVID-19 benefits are
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          What if I am diagnosed with COVID-19 on my trip and I
                          need to quarantine are my emergency/additional
                          expenses covered?
                        </h5>

                        <ul>
                          <li>Domestic – Yes only on the plan that indicates covid cover included under Section 23 COVID-19 Additional Expenses, subject to limits and sub-limits.</li>
                          <li>
                            International – Yes only on the Top Cover plan, subject to limits and sub-limits. There is no cover under the Essentials Cover or Basic Cover plans.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          Am I covered whilst on my trip if I am deemed a close
                          contact due to a COVID-19 case and I need to
                          quarantine before continuing on with my trip?
                        </h5>
                        <ul>
                          <li>Domestic – Yes</li>
                          <li>
                            International – Yes, only on the Top Cover plan, up
                            to a total combined limit of $3,500 per listed
                            adult. If you have selected either the Essentials
                            Cover or Basic Cover plans, then no COVID-19
                            benefits are available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>What other emergency expenses do you cover?</h5>
                        <ul>
                          <li>
                            Domestic – No cover for emergency expenses under
                            Section 21. Refer to Section 23 COVID-19 Additional
                            Expenses.
                          </li>
                          <li>
                            International – On the Top Cover plan that indicates
                            covid cover included we cover all reasonable
                            emergency expenses of any insured event that we have
                            accepted cover for under Section 21A. If you have
                            selected either the Essentials Cover or Basic Cover
                            plans, then no COVID-19 benefits are available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          Am I covered for additional expenses and/or
                          cancellation fees apply if my relative or business
                          partner dies from COVID-19 and I need to cancel my
                          trip or cut my trip short and come home?
                        </h5>
                        <ul>
                          <li>
                            Domestic – Yes, there are limited COVID-19 benefits
                            available, up to a total combined limit of $3,500
                            per listed adult.
                          </li>
                          <li>
                            International - Yes, only on the Top Cover plan.
                            There are limited COVID-19 benefits available, up to
                            a total combined limit of $3,500 per listed adult.
                            If you have selected either the Essentials Cover or
                            Basic Cover plans, then no COVID-19 benefits are
                            available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          Am I covered for COVID-19 if I am travelling on a
                          multi-night Cruise?
                        </h5>
                        <p>
                          Limited COVID-19 benefits are available only if you
                          have selected ‘Going on an Ocean Cruise’ option and purchased
                          the Top Cover plan. This cover includes unlimited**
                          benefits for COVID-19 Overseas Medical and Hospital
                          and Emergency Expenses**. Cover is also available for
                          Cancellation Fees and Lost Deposits; Additional
                          Expenses and Special Events; and Travel Delay, to a
                          maximum combined limit of $3,500 per adult (maximum of
                          two adults per policy). Declared dependents are
                          included in the total combined limit for COVID-19
                          benefits per declared adult.
                        </p>
                        <h5> 
                          Am I covered for loss of enjoyment of my cruise or holiday, if I am required to isolate or quarantine due to COVID-19 whilst on my holiday?
                        </h5>
                        <p>
                          No, all policies have a General Exclusion for any Consequential loss which includes loss of enjoyment and as such there is no cover for loss of enjoyment.
                        </p>
                        <h5>
                          Am I covered for my medical or evacuation costs for Australian Cruising?
                        </h5>
                        <p>
                          If You select ‘Australian Cruise’ as Your country of destination, You will be offered International policy cover, which provides medical and evacuation benefits whilst at sea subject to policy terms and conditions. Please note that this is an optional extra that will be subject to an additional fee.
                        </p>
                        <p>
                          However, no cover is available for medical and evacuation expenses whilst in an Australian port  as we do not cover medical expenses incurred in Australia (as we are prevented from paying by reason of statutory legislation/government regulation). Additionally, no cover is available for medical and evacuation expenses related to COVID-19 unless you have purchased Top Cover.
                        </p>
                        <h5>
                          Am I covered for cancellation fees and lost deposits
                          apply if I am diagnosed with COVID-19 and I need to
                          cancel my trip or cut my trip short and/or come home?
                        </h5>
                        <ul>
                          <li>
                            Domestic – Yes, there are limited COVID-19 benefits
                            available, up to a total combined limit of $3,500
                            per listed adult.
                          </li>
                          <li>
                            International - If you have selected the Top Cover
                            plan, there are limited COVID-19 benefits available,
                            up to a total combined limit of $3,500 per listed
                            adult. If you have selected either the Essentials
                            Cover or Basic Cover plans, then no COVID-19
                            benefits are available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          Am I covered if my travelling companion is diagnosed
                          with COVID-19 and I need to cut my trip short and/or
                          come home?
                        </h5>
                        <ul>
                          <li>
                            Domestic – Yes, there are limited COVID-19 benefits
                            available, up to a total combined limit of $3,500
                            per listed adult.
                          </li>
                          <li>
                            International - If you have selected the Top Cover
                            plan, there are limited COVID-19 benefits available,
                            up to a total combined limit of $3,500 per listed
                            adult. If you have selected either the Essentials
                            Cover or Basic Cover plans, then no COVID-19
                            benefits are available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          Am I covered if my travelling companion is diagnosed
                          with COVID-19 and my trip is cancelled?
                        </h5>
                        <ul>
                          <li>
                            Domestic – Yes, there are limited COVID-19 benefits
                            available, up to a total combined limit of $3,500
                            per listed adult.
                          </li>
                          <li>
                            International - If you have selected the Top Cover
                            plan, there are limited COVID-19 benefits available,
                            up to a total combined limit of $3,500 per listed
                            adult. If you have selected the Essentials Cover or
                            Basic Cover plans, then no COVID-19 benefits are
                            available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>
                        <h5>
                          Am I covered if I am not vaccinated and it is a
                          condition of the transport provider that I must be
                          vaccinated before boarding?
                        </h5>
                        <p>
                          No policies provide cover if you do not comply with
                          the ticket conditions of the transport provider.
                        </p>
                        <h5>
                          Am I covered if I am not vaccinated and it is a
                          condition of the country that I must be vaccinated
                          before I enter?
                        </h5>
                        <p>
                          No policies provide cover if you do not comply with
                          the government regulations of the country you are
                          travelling to.
                        </p>

                        <h5>
                          Do cancellation fees apply if I am a permanent
                          employee of the healthcare industry and my employer
                          cancels my leave due to COVID-19?
                        </h5>

                        <ul>
                          <li>
                            Domestic – Yes, there are limited COVID-19 benefits
                            available, up to a total combined limit of $3,500
                            per listed adult.
                          </li>
                          <li>
                            International - If you have selected the Top Cover
                            plan, there are limited COVID-19 benefits available,
                            up to a total combined limit of $3,500 per listed
                            adult. If you have selected the Essentials Cover or
                            Basic Cover plans, then no COVID-19 benefits are
                            available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>

                        <h5>
                          Am I covered under Special Events if my pre-arranged
                          special event is delayed because of COVID-19, which is
                          outside of my control and I need to make alternative
                          arrangements to get to my destination on time?
                        </h5>

                        <ul>
                          <li>
                            Domestic – Yes, there are limited COVID-19 benefits
                            available, up to a total combined limit of $3,500
                            per listed adult.
                          </li>
                          <li>
                            International - If you have selected the Top Cover
                            plan, there are limited COVID-19 benefits available,
                            up to a total combined limit of $3,500 per listed
                            adult. If you have selected the Essentials Cover or
                            Basic Cover plans, then no COVID-19 benefits are
                            available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>

                        <h5>
                          Do I have cover under Travel Delay if my scheduled
                          transport is delayed for more than 6 hours due to
                          COVID-19 related delays and I need to pay additional
                          expenses?
                        </h5>

                        <ul>
                          <li>
                            Domestic – Yes, cover is available with a daily
                            limit of $1,000 per adult and a maximum limit of
                            $3,500 per listed adult.
                          </li>
                          <li>
                            International - If you have selected the Top Cover
                            plan, cover is available with a daily limit of
                            $1,000 per adult and a maximum limit of $3,500 per
                            listed adult. If you have selected the Essentials
                            Cover or Basic Cover plans, then no COVID-19
                            benefits are available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>

                        <h5>
                          Are there additional COVID-19 benefits available if my
                          trip destination is within Australia or New Zealand?
                        </h5>

                        <ul>
                          <li>
                            Domestic – Yes, as outlined below, to the total
                            combined limit of $3,500 per listed adult.
                          </li>
                          <li>
                            International - Yes, if you have selected the Top
                            Cover plan, there are additional COVID-19 benefits,
                            as outlined below, to the total combined limit of
                            $3,500 per listed adult. If you have selected the
                            Essentials Cover or Basic Cover plans, then no
                            COVID-19 benefits are available.
                          </li>
                          <li>
                            Annual Multi-Trip – There are no COVID-19 benefits
                            available on this plan.
                          </li>
                        </ul>

                        <p><b>Cancellation Fees and Lost Deposits</b></p>
                        <p>
                          If the person you’re due to stay with in Australia or
                          New Zealand is diagnosed with COVID-19, or directed by
                          a local public health authority into a period of
                          quarantine and you are unable to stay with them.
                        </p>

                        <p><b>Additional Expenses</b></p>
                        <p>
                          If you can no longer stay with the person you planned
                          to stay within Australia or New Zealand because they
                          are diagnosed with COVID-19 and directed to enter a
                          period of quarantine; or
                        </p>
                        <p>
                          If your pre-paid accommodation in Australia or New
                          Zealand is shutdown or closes as a result of COVID-19.
                        </p>
                        <p>
                          <small style="font-size: 10px"
                            >** unlimited means that generally there is no cap on
                            the maximum dollar amount which may be paid out of
                            this benefit, subject to the specific terms and
                            conditions, sub-limits and exclusion apply to this
                            benefit. This benefit covers reasonable overseas
                            medical and hospital costs as a result of an injury
                            (including arising from a terrorist act) or illness
                            occurring which first shows itself during your
                            period of insurance. Benefits may be paid up to 12
                            months from the time you received treatment for the
                            injury or illness, but only for reasonable expenses
                            incurred during that time. All medical treatments
                            must be provided by your treating doctor or our
                            consulting medical officer. You must notify us as
                            soon as practicable of your admittance to hospital.
                            <br />
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_02"
                      (click)="toggleCollapse($event, 'faqsContent_02')"
                    >
                      World2Cover Travel Insurance Refund Scheme
                    </div>
                  </h2>
                  <div
                    id="faqsContent_02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <h5>
                          <b
                            >Refunds for travel insurance policies impacted by
                            COVID-19</b
                          >
                        </h5>
                        <p>
                          <i
                            >These FAQs are for customers who have received an
                            email in relation to a travel insurance policy for
                            travel between 31/01/2020 and 31/10/2021 dates only.
                            If you have not received an email and are wishing to
                            enquire about a refund or any other aspect of your
                            policy, please see our general FAQs or contact us on
                            <a href="tel:0292257599"> 02 9225 7599</a>.</i
                          >
                        </p>
                        <h5>Why did I receive this email?</h5>
                        <p>
                          The COVID-19 pandemic caused widespread disruption to
                          domestic and international travel. This included
                          border closures and Do Not Travel directives from
                          governments that prevented travellers from embarking
                          on trips they had planned. These disruptions could
                          have meant that you did not require coverage under the
                          travel insurance policy you purchased for part or all
                          of your travel.
                        </p>
                        <p>
                          Customers who purchased a travel insurance product
                          distributed by World2Cover but were unable to travel
                          due to a travel ban imposed by the Australian
                          Government or border closures by State or Territory
                          Governments, may be eligible for a full or partial
                          refund of their premium. This is in line with
                          expectations issued by the Australian Securities and
                          Investments Commission (ASIC).
                        </p>
                        <p>
                          World2Cover and Tokio Marine encourage all customers
                          who purchased a travel policy that included coverage
                          during a travel ban period and may not have received
                          the benefit of the policy, to check their eligibility
                          for a premium refund.
                        </p>

                        <h5>
                          Who is Tokio Marine and why are they involved in this
                          process?
                        </h5>
                        <p>
                          World2Cover Travel insurance is issued by Tokio Marine
                          &amp; Nichido Fire Insurance Co. Ltd (Tokio Marine)
                          ABN 80 000 438 291, AFSL 246548
                        </p>
                        <p>
                          Tokio Marine underwrites World2Cover Travel Insurance
                          and provides the global emergency assistance and
                          claims handling services offered in our policies and
                          as detailed in the accompanying PDS. Tokio Marine will
                          be managing the refunds process in their system and
                          processing any refunds to World2Cover customers.
                        </p>

                        <h5>Am I eligible for a refund?</h5>
                        <p>To find out if you are eligible for a refund:</p>
                        <ol>
                          <li>
                            Click the link in the email you were sent to take
                            you to the fully secured refund calculation portal
                            where you will be prompted to complete an
                            identification check.
                          </li>
                          <li>
                            Answer a series of questions to determine if you are
                            eligible for a refund; and
                          </li>
                          <li>
                            If you are eligible for any kind of refund, you will
                            be advised on the portal and requested to provide
                            your bank details so that payment can be processed
                            (you will need your BSB and Account Number) within
                            3-6 weeks.
                          </li>
                        </ol>

                        <h5 class="pt-2">Frequently Asked Questions</h5>
                        <dl class="ps-3 pt-3" style="color: #54575c">
                          <dt>Q: How do I know if I am eligible?</dt>
                          <dd>
                            <b>A:</b> This offer is only available if you
                            purchased a World2Cover Travel Insurance product
                            underwritten by Tokio Marine (World2Cover Travel
                            Insurance Policy) and were unable to travel due to
                            your travel dates falling into the international ‘do
                            not travel ban’ period, issued by the Australian
                            government, or for domestic border closures imposed
                            by state or territory governments.
                          </dd>

                          <dt>Q: What if I have travelled?</dt>
                          <dd>
                            <b>A:</b> If you have not been prevented from
                            travelling due to an Australian government issued
                            travel ban, you will not be entitled to a refund as
                            you would have had the benefit of the travel policy,
                            subject to terms and conditions.
                          </dd>

                          <dt>
                            Q: I already made a claim and have been successful,
                            can I still apply for a refund?
                          </dt>
                          <dd>
                            <b>A:</b> If you have made a claim under your
                            World2Cover Travel Insurance Policy and have been
                            successful, you will not be entitled to a refund.
                          </dd>

                          <dt>
                            Q: What if I need to make a claim under the Policy?
                          </dt>
                          <dd>
                            <b>A:</b> If you intend to make a claim under your
                            World2Cover Travel Insurance Policy, you will not be
                            entitled to a refund unless it is determined that
                            your policy was not effective on the basis of the
                            travel ban. In other words, if you have a claim made
                            on a valid policy in operation, you should not apply
                            for a premium refund as you had the benefit of the
                            policy.
                          </dd>

                          <dt>Q: How is my refund calculated?</dt>
                          <dd>
                            <b>A:</b> The amount of your refund will be
                            calculated based on the duration for which you did
                            not have the benefit of your World2Cover Travel
                            Insurance Policy via the eligibility question set.
                            If you have any queries or concerns with your
                            assessment, please email us at
                            <a href="mailto:travelservice@world2cover.com.au"
                              >travelservice&#64;world2cover.com.au</a
                            >
                            we will forward your questions to Tokio Marine for
                            response.
                          </dd>

                          <dt>Q: How long will the refund take?</dt>
                          <dd>
                            <b>A:</b> Payments will be processed within 3-6
                            weeks where possible of you completing the refund
                            request and will be deposited into your nominated
                            bank account. Your bank statement will show the
                            deposit from Tokio Marine.
                          </dd>

                          <dt>Q: I still have questions, who can I contact?</dt>
                          <dd>
                            <b>A:</b> If you still have questions, please
                            contact our team by emailing
                            <a href="mailto:travelservice@world2cover.com.au"
                              >travelservice&#64;world2cover.com.au</a
                            >
                            and we will reply as quickly as possible.
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_38"
                      (click)="toggleCollapse($event, 'faqsContent_38')"
                    >
                      Refund following Pricing Promises Remediation
                    </div>
                  </h2>
                  <div
                    id="faqsContent_38"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <h5>
                          How do I know if my insurance policies are eligible
                          for a refund?
                        </h5>
                        <p>
                          If you are eligible for a refund, we will contact you
                          on the contact details you supplied when you purchased
                          your policy.
                        </p>
                        <h5>
                          What if my contact details have changed since I
                          purchased my policy?
                        </h5>
                        <p>
                          Please contact our Customer Services Team at
                          <a href="tel:0292257599">+ 61 2 9225 7599</a> during
                          9am - 5pm AEST and provide them with your updated
                          contact details. If you are eligible, you will receive
                          an email with the link to the secure portal to your
                          new email address.
                        </p>

                        <h5>Why is W2Cover issuing a refund?</h5>
                        <p>
                          The terms of the discount during the promotional
                          period up until April 2023 lacked clarity for those
                          policies purchased which included a second adult
                          traveler with a medical premium loading. Part of the
                          premium is being refunded to ensure that affected
                          customers receive the discount that they may have
                          understood applied.
                        </p>

                        <h5>How is my refund calculated?</h5>
                        <p>
                          If eligible, you will be refunded the portion of
                          premium you paid, reflecting the discount that you may
                          have understood to have applied, plus interest. This
                          will be set out in our letter we send you.
                        </p>

                        <h5>
                          Who is the insurer and why are they involved in this
                          refund process?
                        </h5>
                        <p>
                          Tokio Marine & Nichido Fire Insurance Co., Ltd (Tokio
                          Marine & Nichido) ABN 80 000 438 291, AFSL 246548 is
                          the insurer and issuer of World2Cover travel insurance
                          product and owns the ‘World2Cover’ brand. The insurer
                          has conducted a review of any pricing or discount
                          promises made in relation to its products. As a
                          result, where applicable, they have undertaken a
                          ‘Pricing Promises remediation’ and is issuing a refund
                          to the eligible customers, to make things right for
                          its customers, and to ensure it provides financial
                          services efficiently, honestly and fairly according to
                          the relevant laws and regulations. The insurer will be
                          managing the refunds process in their system.
                        </p>

                        <h5>How long will it take to receive the refund?</h5>
                        <p>
                          We aim to process your refund payment into your
                          nominated account within 30 days of you completing the
                          online refund form, using the secure link provided.
                        </p>

                        <h5>
                          Is it secure to provide bank account details requested
                          in the email from W2Cover?
                        </h5>
                        <p>
                          For your security you will be provide with a secure
                          link to access the online refund form. The Customer
                          Services Team will not be able to take your bank
                          account details over the phone or via email and you
                          will be required to enter your account details within
                          this secure portal, using the secure link, to the
                          online refund form sent in your email. Only those
                          details provided in this online refund form will be
                          used.
                        </p>

                        <h5>
                          What if I have submitted incorrect bank account
                          details on my secure refund form?
                        </h5>
                        <p>
                          Please contact our Customer Services Team as soon as
                          possible on
                          <a href="tel:0292257599">+ 61 2 9225 7599</a> during
                          9am - 5pm AEST and advise them of this error.
                        </p>

                        <h5>I still have questions, who can I contact?</h5>
                        <p>
                          Please contact our Customer Services Team at
                          <a href="tel:0292257599">+ 61 2 9225 7599</a> during
                          8am – 9pm Mon – Fri, 9am – 6pm Sat & Sun AEDT
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_03"
                      (click)="toggleCollapse($event, 'faqsContent_03')"
                    >
                      Why should I consider travel insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Travel insurance helps protect you from the financial
                          burden of unforeseen circumstances that occur from the
                          moment you purchase your policy up to the point you
                          come back home and of course while travelling overseas
                          or domestically. Travel insurance can provide cover
                          for unexpected expenses of a wide range of unforeseen,
                          unintended, and unexpected incidents or injury caused
                          by an identifiable external event on your trip.
                        </p>
                        <p>
                          For instance, travel insurance may cover you for
                          cancellation fees and lost deposits<sup>#</sup>
                          related to illness and accidents, as well as
                          unforeseen circumstances beyond your control such as
                          cancelled flights due to cyclones<sup>#</sup>. You
                          could also be reimbursed for travel agent’s
                          cancellation charges or your lost frequent flyer
                          points<sup>~</sup>.
                        </p>
                        <p>
                          At World2Cover, we want to ensure we can help during
                          these events and make your holiday as stress-free as
                          possible, particularly when you are overseas.
                        </p>
                        <p>
                          For example, our team will help you in an overseas
                          emergency medical situation by keeping you in touch
                          with your family and colleagues. We can also help you
                          locate embassies and consulates around the world for
                          other overseas emergency situations.
                        </p>
                        <p>
                          To find out more about the benefits of travel
                          insurance, visit the
                          <a
                            rel="noopener noreferrer"
                            href="https://www.dfat.gov.au/"
                            target="_blank"
                            >Department of Foreign Affairs and Trade website</a
                          >.
                        </p>
                        <p>
                          For full details, including the terms, conditions,
                          limits and exclusion that apply please read the
                          <a target="_blank" href="/pds"
                            >World2Cover Product Disclosure Statement</a
                          >.
                        </p>
                        <p>
                          <small
                            ># terms and conditions, exclusions, limits and
                            sub-limits apply <br />
                            ~ Policy criteria and conditions apply</small
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_04"
                      (click)="toggleCollapse($event, 'faqsContent_04')"
                    >
                      What does travel insurance cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          Travel insurance can provide you with added peace of
                          mind when you are travelling. Some of the benefits
                          provided by World2Cover travel insurance are:
                        </p>
                        <ul>
                          <li>
                            Overseas medical and hospital expenses –
                            $unlimited<sup>**</sup> cover for medical and
                            hospital costs due to an injury which occurred
                            during your trip
                          </li>
                          <li>
                            Cancellation fees and lost deposits<sup>#</sup> –
                            the cost of pre-paid travel and accommodation
                            arrangements when your travel is cancelled or cut
                            short due to events beyond your control and covered
                            by your policy.
                          </li>
                          <li>
                            Luggage<sup>#</sup> and personal money – repair
                            costs of damaged items, as well as replacing or
                            reimbursing your belongings (including cash) if they
                            are lost, stolen or damaged (sub-limits and
                            conditions apply).
                          </li>
                          <li>
                            Travel delay<sup>#</sup> – additional accommodation,
                            meal, and travel expenses if your scheduled
                            transport is delayed for at least 6 hours outside
                            your control (policy conditions limit and sub limits
                            applied).
                          </li>
                        </ul>
                        <p>
                          Please refer to the table of benefits in the
                          <a target="_blank" href="/pds"
                            >Product Disclosure Statement</a
                          >
                          for further details, including the terms, conditions,
                          limits and exclusions that apply.
                        </p>
                        <p>
                          All World2Cover overseas policy options provide
                          worldwide emergency medical assistance 24-hour/7 days
                          a week, and $unlimited<sup>**</sup> overseas medical
                          expenses (conditions, sub-limits and exclusions
                          apply). We offer several policy options with varying
                          benefits and limits for international travel and a
                          policy for domestic travel including rental car
                          excess<sup>#</sup> cover.
                        </p>
                        <p>
                          When travelling with your dependent children,
                          grandchildren, step-children and foster children, we
                          cover them at no additional cost (unless they have a
                          declared existing medical condition), provided they
                          are travelling with you 100% of the time, are up to
                          the age of 25, are financially dependent on their
                          parents, are not working full time, and do not require
                          a medical assessment.
                        </p>
                        <p>
                          We offer levels of cover that each come with their own
                          set of benefits. For example, if you choose to
                          purchase the Top Cover plan, domestic pets^<sup
                            >#</sup
                          >
                          are also covered under your policy<sup>~</sup>. With
                          certain restrictions<sup>∆</sup>, top cover travel
                          insurance covers up to $650 if your pet suffers an
                          injury during your journey and requires veterinary
                          treatment (provided that at the time of injury your
                          pet was in the care of a relative, friend or boarding
                          kennel or cattery. Yes, that means your furry little
                          friend is protected as well.
                        </p>
                        <p class="desclaimer">
                          <small>
                            ** $unlimited means that generally there is no cap
                            on the maximum dollar amount which may be paid out
                            of this benefit, subject to the specific terms and
                            conditions, sub-limits and exclusion apply to this
                            benefit. This benefit covers reasonable overseas
                            medical and hospital costs as a result of an injury
                            (including arising from a terrorist act) or illness
                            occurring which first shows itself during your
                            period of insurance. Benefits may be paid up to 12
                            months from the time you received treatment for the
                            injury or illness, but only for reasonable expenses
                            incurred during that time. All medical treatments
                            must be provided by your treating doctor or our
                            consulting medical officer. You must notify us as
                            soon as practicable of your admittance to hospital.
                            <br />
                            ^ This cover is per policy
                            <br />
                            ~ Policy criteria and conditions apply
                            <br/>
                            # terms and conditions, exclusions, limits and sub-limits apply
                          </small>
                          <br />
                          <small>
                            ∆ We will not cover you for Any boarding kennel or
                            cattery fees incurred outside of Australia, any pets
                            located outside of Australia and all General
                            Exclusions apply, please refer to our
                            <a target="_blank" href="/pds"
                              >Product Disclosure Statement</a
                            >
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_05"
                      (click)="toggleCollapse($event, 'faqsContent_05')"
                    >
                      What does travel insurance not cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-3">
                        <p>
                          World2Cover travel insurance does not cover every
                          event and circumstance and there may also be limits to
                          those we do cover. We have listed exclusions that
                          apply to all our travel insurance options in either
                          the specific section and/or the
                          <a target="_blank" href="/pds"
                            >General Exclusion section of our Product Disclosure
                            Statement</a
                          >.
                        </p>
                        <p>
                          There is a Benefits table for each of the specific
                          levels of cover as well as for the policy options and
                          add-ons.
                        </p>
                        <p>
                          For the full list of exclusions and the benefit limits
                          and sublimits, please read the
                          <a target="_blank" href="/pds"
                            >Product Disclosure Statement</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_06"
                      (click)="toggleCollapse($event, 'faqsContent_06')"
                    >
                      Who is the insurer for World2Cover travel insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          World2Cover Travel Insurance is issued by Tokio Marine
                          &amp; Nichido Fire Insurance Co., Ltd (Tokio Marine
                          &amp; Nichido). ABN 80 000 438 291, AFSL 246548 is the
                          insurer and issuer of this policy and PDS. We also
                          have an APRA authorisation to conduct general
                          insurance business in Australia.
                        </p>
                        <p>
                          Our managing agent and representative, Tokio Marine
                          Management Australia Pty. Ltd. ABN 69 001 488 455
                          (TMMA), is authorised under a binder and managing
                          agent agreement to act on our behalf to issue our
                          policies and handle and settle claims in relation to
                          those policies, subject to the terms of the authority.
                        </p>
                        <p>
                          As well as Travel Insurance, Tokio Marine &amp;
                          Nichido also work with the general insurance market
                          through insurance brokers and provide insurance for
                          commercial and corporate businesses in Australia since
                          1963. Tokio Marine &amp; Nichido was founded in 1879
                          in Japan, operates in multiple countries and employees
                          thousands of people worldwide.
                        </p>
                        <p>
                          Our Australian Head Office is based in Sydney as per
                          our Call Centre, Sales and Claims department.
                          World2Cover is an awarded travel insurance provider by
                          Canstar in 2022 and from 2016 to 2019 for Outstanding
                          Value International Travel Insurance and by Mozo for
                          Travel Insurance of the Year in 2018 and 2019.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_07"
                      (click)="toggleCollapse($event, 'faqsContent_07')"
                    >
                      How can I contact World2Cover to enquire about travel
                      insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_07"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        For all customer service enquiries including existing
                        medical assessments you can easily go on our website
                        world2cover.com.au or call us on
                        <a href="tel:0292257599">02 9225 7599</a> - Our hours
                        are 8am to 9pm Monday to Friday & 9am to 6pm Saturday
                        and Sunday.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_08"
                      (click)="toggleCollapse($event, 'faqsContent_08')"
                    >
                      Who can get insured?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_08"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p><b>Cover is available to:</b></p>
                        <p>
                          Australian residents up to 75 years of age, provided:
                        </p>
                        <ul>
                          <li>
                            You purchase Your policy before You begin your trip;
                            and
                          </li>
                          <li>
                            for International cover Your trip begins and ends in
                            Australia; or
                          </li>
                          <li>
                            for Domestic cover Your trip must be wholly within
                            Australia.
                          </li>
                        </ul>
                        <p>
                          Temporary residents up to 75 years of age, provided:
                        </p>
                        <ul>
                          <li>
                            You hold a current Australian Visa (not a tourist,
                            study or working holiday visa) that will remain
                            valid beyond the period of Your return from Your
                            trip; and
                          </li>
                          <li>You hold a return ticket; and</li>
                          <li>
                            You have a primary place of residence in Australia
                            that You intend to return to; and
                          </li>
                          <li>
                            You purchase Your policy before You begin Your Trip;
                            and
                          </li>
                          <li>
                            for International cover Your trip begins and ends in
                            Australia; or
                          </li>
                          <li>
                            for Domestic cover Your trip must be wholly within
                            Australia.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_09"
                      (click)="toggleCollapse($event, 'faqsContent_09')"
                    >
                      What is a medical assessment?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_09"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p class="text-gray">
                        This is an online set of medical questions (assessment)
                        which you will need to complete if you wish to apply for
                        travel insurance but have Existing Medical Conditions
                        which are not automatically covered under the policy.
                        You can complete this as part of your travel insurance
                        quote at
                        <a
                          target="_blank"
                          href="https://www.world2cover.com.au/"
                          >www.world2cover.com.au</a
                        >
                        or call <a href="tel:0292257599">02 9225 7599</a>
                        Monday to Friday 8am-9pm and Saturday & Sunday 9am-6pm
                        for additional assistance. Upon completion of this
                        assessment we will inform you if the Existing Medical
                        Condition is covered and any additional premium payable.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_10"
                      (click)="toggleCollapse($event, 'faqsContent_10')"
                    >
                      What is an Existing Medical Condition?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_10"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          An Existing Medical Condition means any medical or
                          physical condition, disorder, disease, disability or
                          illness, including any
                          <strong><em>Mental Illness</em></strong
                          >, which <strong><em>You</em></strong> at the
                          <strong><em>Relevant Time</em></strong
                          >, were aware of, or a reasonable person in the
                          circumstances could be expected to have been aware of,
                          and the <strong><em>Relevant Time</em></strong
                          >:
                        </p>
                        <ol>
                          <li>
                            is chronic, ongoing, terminal, or has affected or
                            involved one or more of the following:
                            <ol type="a">
                              <li>
                                heart, circulatory system, lungs or respiratory
                                system, brain, kidneys, liver, or cancer;
                              </li>
                              <li>
                                surgery involving the back, neck, joints, or
                                abdomen; or
                              </li>
                            </ol>
                          </li>
                          <li>
                            In the last 12 months had:
                            <ol type="a">
                              <li>
                                presented symptoms which would have caused an
                                ordinarily prudent person to seek medical
                                opinion or treatment;
                              </li>
                              <li>become exacerbated or complicated; or</li>
                              <li>
                                been diagnosed, treated or treatment was
                                recommended by a
                                <em><strong>Treating Doctor</strong></em
                                >.
                              </li>
                            </ol>
                          </li>
                        </ol>
                        <p>
                          This definition applies to You, Your Travelling
                          Companion, a relative or any other person. If you
                          would like more information in relation to Existing
                          Medical Conditions, please read the PDS or call
                          <a href="tel:0292257599">02 9225 7599</a>.
                        </p>
                        <p>
                          Capitalised terms used above are defined in the PDS
                          which can be viewed
                          <a target="_blank" href="/pds">here</a>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_11"
                      (click)="toggleCollapse($event, 'faqsContent_11')"
                    >
                      Which existing medical conditions are covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_11"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          This section outlines those
                          <i><strong>Existing Medical Conditions</strong></i>
                          automatically included, where
                          <i><strong>You</strong></i
                          >, at the <i><strong>Relevant Time</strong></i
                          >:
                        </p>
                        <ul>
                          <li>
                            have not required hospitalisation or treatment<sup>♠</sup> by
                            any
                            <strong><i>Treating Doctor</i></strong> within the
                            last 12 months (unless a different time-period is
                            specifically listed in the list below) for the
                            <strong><i>Existing Medical Condition</i></strong
                            >;
                          </li>
                          <li>
                            are not awaiting the outcome of any investigation,
                            tests, surgery or other treatment for the
                            <strong><i>Existing Medical Condition</i></strong
                            >; and
                          </li>
                          <li>
                            meet any additional criteria set out in the
                            <i
                              >Existing Medical Conditions we automatically
                              cover</i
                            >
                            list below.
                          </li>
                        </ul>
                        <p class="desclaimer">
                          <small>
                            ♠ for the purposes of existing medical conditions
                            automatically covered “treatment” does not include
                            an annual or routine medical check-up, blood testing
                            or a visit to a medical practitioner to obtain a
                            regular prescription.
                          </small>
                        </p>
                        <p>
                          <strong
                            >Please also read the “General Exclusions” section
                            of the PDS which can be viewed
                            <a target="_blank" href="/pds">here</a>.</strong
                          >
                        </p>
                        <p>
                          If the criteria above are satisfied, cover is
                          automatically included for the following
                          <strong><i>Existing Medical Conditions</i></strong
                          >:
                        </p>
                        <ol class="text-gray">
                          <li>Acne</li>
                          <li>
                            Allergies limited to Rhinitis, Chronic Sinusitis,
                            Eczema, Food Intolerance, Hay Fever, however this
                            excludes any Anaphylaxis as part of any such
                            condition
                          </li>
                          <li>
                            Asthma providing You:
                            <ol type="a">
                              <li>have no other lung disease; and</li>
                              <li>
                                are less than 60 years of age at the time You
                                purchased the policy
                              </li>
                            </ol>
                          </li>
                          <li>Bell’s Palsy</li>
                          <li>Benign Positional Vertigo</li>
                          <li>Bunions</li>
                          <li>Carpal Tunnel Syndrome</li>
                          <li>Cataracts</li>
                          <li>Coeliac Disease</li>
                          <li>Congenital Blindness</li>
                          <li>Congenital Deafness</li>
                          <li>
                            Diabetes Mellitus (Type 1) providing You:
                            <ol type="a">
                              <li>were diagnosed over 12 months ago; and</li>
                              <li>
                                have no eye, kidney, nerve or vascular
                                complications; and
                              </li>
                              <li>
                                do not also suffer from a known cardiovascular
                                disease, Hypertension or Hypercholesterolaemia;
                                and
                              </li>
                              <li>
                                are under 50 years of age at the date of policy
                                purchase
                              </li>
                            </ol>
                          </li>
                          <li>
                            Diabetes Mellitus (Type 2) providing You:
                            <ol type="a">
                              <li>were diagnosed over 12 months ago; and</li>
                              <li>
                                have no eye, kidney, nerve or vascular
                                complications; and
                              </li>
                              <li>
                                do not also suffer from a known cardiovascular
                                disease, Hypertension or Hypercholesterolaemia;
                                and
                              </li>
                              <li>
                                are under 50 years of age at the date of policy
                                purchase
                              </li>
                            </ol>
                          </li>
                          <li>Dry Eye Syndrome</li>
                          <li>
                            Epilepsy providing:
                            <ol type="a">
                              <li>
                                there has been no change to Your medication
                                regime in the past 12 months and
                              </li>
                              <li>
                                You are on no more than one anticonvulsant
                                medication
                              </li>
                            </ol>
                          </li>
                          <li>Gastric Reflux</li>
                          <li>Gastric/Peptic Ulcer</li>
                          <li>Glaucoma</li>
                          <li>Gout</li>
                          <li>Graves’ Disease</li>
                          <li>Hiatus Hernia</li>
                          <li>
                            Hip/Knee replacement if performed more than 12
                            months ago but less than 10 years ago
                          </li>
                          <li>
                            Hypercholesterolaemia (High Cholesterol) providing
                            You do not also suffer from a known cardiovascular
                            disease and/or Diabetes
                          </li>
                          <li>
                            Hyperlipidaemia (High Blood Lipids) providing You do
                            not also suffer from a known cardiovascular disease
                            and/or Diabetes
                          </li>
                          <li>
                            Hypertension (High Blood Pressure) providing You do
                            not also suffer from a known cardiovascular disease
                            and/or Diabetes
                          </li>
                          <li>Hypothyroidism, including Hashimoto’s Disease</li>
                          <li>Incontinence</li>
                          <li>Insulin Resistance</li>
                          <li>Macular Degeneration</li>
                          <li>Meniere’s Disease</li>
                          <li>Migraine</li>
                          <li>Nocturnal Cramps</li>
                          <li>Plantar Fasciitis</li>
                          <li>Raynaud’s Disease</li>
                          <li>Sleep Apnoea</li>
                          <li>Solar Keratosis</li>
                          <li>Trigeminal Neuralgia</li>
                          <li>Trigger Finger</li>
                        </ol>

                        <p>
                          If your existing medical condition meets the
                          automatically covered conditions criteria, there is
                          nothing further you need to do in order to be covered
                          for that condition.
                        </p>
                        <p>
                          If your existing medical condition does not meet the
                          automatically covered conditions criteria, and you
                          want cover for this, you must complete a medical
                          assessment to assess whether we can accept this risk
                          and what, if any additional premium is payable by you,
                          to have that condition specified on your policy.
                        </p>
                        <p>
                          <strong
                            >Please also read the “General Exclusions” section
                            of the PDS which can be viewed
                            <a target="_blank" href="/pds">here</a>.</strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_39"
                      (click)="toggleCollapse($event, 'faqsContent_39')"
                    >
                      I have a condition that is automatically covered and I
                      have recently visited my doctor so I’m not sure what you
                      mean by treatment. What do you consider treatment in this
                      case?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_39"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          If you have a condition that is on the list of
                          existing medical conditions we automatically cover and
                          you meet the criteria for the condition, we do not
                          consider 'treatment' to include things such as an
                          annual or routine check-up with your medical
                          practitioner, blood testing or a visit to a medical
                          practitioner to obtain a regular prescription.
                        </p>
                        <p>
                          Treatment is therefore anything that falls outside of
                          these things or where there has been a deterioration
                          in your condition or a requirement for any further
                          investigation.
                        </p>
                        <p>
                          Please refer to any specific criteria for coverage for
                          an automatically covered existing condition and ensure
                          you meet all of these to obtain cover.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_12"
                      (click)="toggleCollapse($event, 'faqsContent_12')"
                    >
                      I have an existing medical condition that isn’t
                      automatically covered or does not meet the criteria. Can I
                      still get cover?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_12"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="page-content-info mb-0">
                        <p>
                          If <strong><i>You</i></strong> have an
                          <strong><i>Existing Medical Condition</i></strong>
                          that is not automatically covered above and
                          <strong><i>You</i></strong> want cover for this
                          <strong
                            ><i>Existing Medical Condition, You</i></strong
                          >
                          will need to complete
                          <strong><i>Our</i></strong> online medical assessment
                          so that <strong><i>We</i></strong> can assess whether:
                        </p>
                        <ol type="a">
                          <li>
                            <strong><i>We</i></strong> can cover the
                            <strong><i>Existing Medical Condition</i></strong> –
                            in which case additional
                            <i><strong>Premium</strong></i> may be payable and
                            the
                            <strong><i>Existing Medical Condition</i></strong>
                            will be listed on
                            <strong><i>Your Certificate of Insurance</i></strong
                            >; or
                          </li>
                          <li>
                            <strong><i>We</i></strong> can’t cover the
                            <strong><i>Existing Medical Condition</i></strong> –
                            in which case, the
                            <strong><i>Existing Medical Condition</i></strong>
                            that has not been accepted will be listed on
                            <strong><i>Your Certificate of Insurance</i></strong
                            >.
                          </li>
                        </ol>
                        <p>
                          You will need to complete an online medical
                          assessment. You can complete this as part of Your
                          travel insurance quote at
                          <a
                            target="_blank"
                            href="https://www.world2cover.com.au/"
                            >www.world2cover.com.au</a
                          >
                          or call
                          <a href="tel:0292257599">(02) 9225 7599</a> for
                          additional assistance.
                        </p>
                        <p>
                          <strong>
                            Please also read the General Exclusions which apply
                            in addition to any limitations set out above.
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_13"
                      (click)="toggleCollapse($event, 'faqsContent_13')"
                    >
                      Am I covered if I'm pregnant?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_13"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        <strong><i>Our</i></strong> policies provide cover for
                        pregnancy in limited circumstances.
                      </p>
                      <p><strong>What is covered</strong></p>
                      <p>
                        Cover is included automatically up to the end of the
                        25th week of pregnancy for:
                      </p>
                      <ol class="text-gray">
                        <li>Single non-complicated pregnancies;</li>
                        <li>unexpected pregnancy complications; and</li>
                        <li>
                          childbirth which was accelerated by accidental injury
                          in limited circumstances.
                        </li>
                      </ol>
                      <p>Please see further information below.</p>
                      <p>
                        <strong><i>You</i></strong> will need to apply for cover
                        if at the <strong><i>Relevant Time</i></strong
                        >, You know <strong><i>You</i></strong> are pregnant and
                        are aware of, or a reasonable person in the
                        circumstances could be expected to have been aware of,
                        any of the following:
                      </p>
                      <ol class="text-gray" type="i">
                        <li>
                          there have been complications with this or a previous
                          pregnancy,
                        </li>
                        <li>
                          <i><strong>You</strong></i> are expecting a multiple
                          pregnancy (such as triplets or twins), or
                        </li>
                        <li>
                          <i><strong>You</strong></i> have an
                          <i><strong>Existing Medical Condition</strong></i>
                          which could have an adverse impact on
                          <i><strong>Your</strong></i> health.
                        </li>
                      </ol>
                      <p><strong>Pregnancy Complications</strong></p>
                      <p>
                        Pregnancy complications are considered
                        <strong><i>Existing Medical Conditions</i></strong> and
                        need to be disclosed and assessed if
                        <strong><i>You</i></strong> want cover for these
                        conditions whilst on <strong><i>Your Trip</i></strong
                        >. Pregnancy complications include those that occur
                        during pregnancy or may be caused by
                        <strong><i>Existing Medical Conditions</i></strong> that
                        already existed prior to the pregnancy, such as previous
                        high risk of miscarriage, gestational diabetes,
                        hyperemesis (severe morning sickness) or pre-eclampsia.
                        Please refer to the
                        <i>Existing Medical Conditions We need to assess</i>
                        section above.
                      </p>
                      <p><strong>What is not covered</strong></p>
                      <p>There is no cover for:</p>
                      <ul>
                        <li>
                          pregnancy complications occurring from the 26th week
                          of gestation , or as described in the section above
                          (<strong>Pregnancy Complications</strong>) unless such
                          complications are specifically accepted by
                          <i><strong>Us</strong></i> and noted on
                          <i><strong>Your Certificate of Insurance</strong></i
                          >;
                        </li>
                        <li>
                          childbirth at any stage of the pregnancy, other than
                          as a result of an
                          <i><strong>Accident</strong></i> occurring prior to
                          the end of the 25th week of
                          <strong><i>Your</i></strong> pregnancy which causes
                          <strong><i>You</i></strong> to give birth prematurely;
                        </li>
                        <li>
                          the health or care of a newborn child, irrespective of
                          the stage of pregnancy when the child is born; or
                        </li>
                        <li>regular antenatal care.</li>
                      </ul>
                      <p>
                        <strong><i>We</i></strong> recommend that
                        <strong><i>You</i></strong> contact
                        <strong><i>Your</i></strong> Treating Doctor and obtain
                        written confirmation that
                        <strong><i>You</i></strong> are fit to travel before
                        commencing <strong><i>Your</i></strong> planned
                        <strong><i>Trip</i></strong
                        >. Please see the Changes in
                        <strong><i>Your</i></strong> health section below, which
                        outlines conditions to
                        <strong><i>Our</i></strong> cover.
                      </p>
                      <p>
                        If <strong><i>You</i></strong> are unsure whether
                        <strong><i>You</i></strong> need to complete a medical
                        assessment for <strong><i>Your</i></strong> pregnancy,
                        please call
                        <a href="tel:0292257599">02 9225 7599</a> for additional
                        assistance.
                      </p>
                      <p>
                        Please refer to <i>‘General Exclusions’</i> in the PDS
                        which apply to all sections of cover.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_14"
                      (click)="toggleCollapse($event, 'faqsContent_14')"
                    >
                      What happens if I develop a medical condition after I buy
                      travel insurance but before I leave on my trip. Would I
                      still be covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_14"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If before starting
                          <i><strong>Your Trip You</strong></i> become aware of,
                          or a reasonable person in the circumstances could be
                          expected to have become aware of, a change in
                          <i><strong>Your</strong></i> health, that:
                        </p>
                        <ul>
                          <li>
                            has, or is likely to have, an effect on the
                            diagnosis, severity, or management of an
                            <strong><i>Existing Medical Condition</i></strong
                            >, such as a change in treatment, medication or
                            dosage, a new symptom or change of a condition of an
                            <strong>Existing Medical Condition</strong>, that
                            <strong>We</strong> have accepted on Your
                            <strong>Certificate of Insurance</strong>; or
                          </li>
                          <li>
                            An
                            <i><strong>Existing Medical Condition</strong></i>
                            manifests itself, that is not otherwise covered
                            under the ‘Medical Conditions We automatically
                            cover’ section;
                          </li>
                        </ul>
                        <p>
                          <strong><i>You</i></strong> must tell
                          <strong><i>Us</i></strong> as soon as reasonably
                          practicable and prior to starting
                          <strong><i>Your Trip</i></strong
                          >.
                        </p>
                        <p>
                          <strong><i>We</i></strong> will then assess the
                          condition to determine if
                          <strong><i>We</i></strong> can offer
                          <strong><i>You</i></strong> cover, as outlined in the
                          <i>‘Existing Medical Conditions We need to assess’</i>
                          section above. If <strong><i>We</i></strong> consider
                          any change in health no longer satisfies the criteria
                          <strong><i>We</i></strong> offer under the policy
                          because it substantially increases the risk of loss,
                          damage, illness, injury or liability, then
                          <strong><i>We</i></strong> will let you know and
                          decline or withdraw coverage, as applicable, by
                          issuing <strong><i>You</i></strong> an updated
                          <strong><i>Certificate of Insurance</i></strong> with
                          any applicable changes to
                          <strong><i>Existing Medical Conditions</i></strong>
                          that are or are not covered by the policy.
                        </p>
                        <p>
                          If <strong><i>We</i></strong> withdraw or decline
                          cover and <strong><i>You</i></strong> decide to cancel
                          <strong><i>Your Trip</i></strong> as a result, cover
                          may still be available under
                          <i>‘Section 1 Cancellation Fees and Lost Deposits’</i
                          >. If <strong><i>You</i></strong> do not claim and
                          want to alternatively cancel
                          <strong><i>Your</i></strong> policy prior to starting
                          <strong><i>Your Trip</i></strong> as a result of this
                          decision, <strong><i>We</i></strong> will refund your
                          premium in full, even if this is outside the cooling
                          off period.
                        </p>
                        <p>
                          If <strong><i>You</i></strong> fail to tell
                          <strong><i>Us</i></strong> about a change in
                          <strong><i>Your</i></strong> health as set out above,
                          <strong><i>We</i></strong> may refuse coverage or
                          refuse to pay <strong><i>Your</i></strong> claim, in
                          whole or in part, subject to our rights under section
                          54 of the
                          <i>Insurance Contracts Act 1984 (Clth)</i> to the
                          extent that <strong><i>We</i></strong> have been
                          prejudiced as a result of your failure to notify
                          <strong><i>Us. We</i></strong> may also be entitled to
                          cancel <strong><i>Your</i></strong> policy under
                          section 60 of the Insurance Contracts Act (Clth).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_15"
                      (click)="toggleCollapse($event, 'faqsContent_15')"
                    >
                      Will my children be covered by my insurance?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_15"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Cover is included for your dependent children,
                          grandchildren, step-children and foster children, at
                          no additional cost (provided they do not require cover
                          for an existing medical condition), and they meet the
                          following criteria:
                        </p>
                        <ul>
                          <li>
                            aged 25 years or younger at the time You buy Your
                            policy;
                          </li>
                          <li>
                            financially dependent on their parents or
                            grandparents and not working full time;
                          </li>
                          <li>travelling with You for Your entire Trip;</li>
                          <li>
                            listed on the Certificate of Insurance as Your
                            Dependant; and
                          </li>
                          <li>
                            whilst on Your Trip, is dependent on an Adult listed
                            on Your Certificate of Insurance.
                          </li>
                        </ul>
                        <p>
                          If they meet the above criteria and you choose our Ski
                          and Winter Sports option or our Cruise option, they’ll
                          be covered for this as well. If you have omitted to
                          list them, please contact us as soon as possible on
                          <a href="tel:0292257599">02 9225 7599</a>, Mon-Fri 8am
                          to 9pm, Saturday to Sunday 9am to 6pm or email us at
                          <a href="mailto:travelservice@world2cover.com.au"
                            >travelservice&#64;world2cover.com.au</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_16"
                      (click)="toggleCollapse($event, 'faqsContent_16')"
                    >
                      What policy benefits and limits are applicable for my
                      Dependents (children, grandchildren, step-children and
                      foster children)?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_16"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Unless the benefits table show the sum insured per
                          person, the limit payable for your dependents is
                          included in the total combined sum insured for each
                          listed adult. For example, if an adult has Top cover
                          on a single policy and a claim is made under Section
                          13 Travel Delay, the maximum amount that may be paid
                          for one adult and their dependent’s combined is
                          $3,000, while a policy with two adults listed with
                          their dependents would have a total combined limit of
                          $6,000. Please note there are other sections of the
                          policy whereby the limits are itemised per person
                          which includes dependent’s individually e.g. funeral
                          expenses in section 2G. Please refer to the Product
                          Disclosure Statement for further details.
                        </p>
                        <p>
                          Dependent children will be covered for no additional
                          charge if they meet the following criteria:
                        </p>
                        <ul>
                          <li>
                            aged 25 years or younger at the time You buy Your
                            policy;
                          </li>
                          <li>
                            financially dependent on their parents or
                            grandparents and not working full time;
                          </li>
                          <li>travelling with You for Your entire Trip;</li>
                          <li>
                            listed on the Certificate of Insurance as Your
                            Dependant; and
                          </li>
                          <li>
                            whilst on Your Trip, is dependent on an Adult listed
                            on Your Certificate of Insurance.
                          </li>
                        </ul>
                        <p>
                          A dependent can be a child of any Adult listed on Your
                          certificate of Insurance.
                        </p>
                        <p>
                          The limit for any benefit payable for dependents is
                          included in the insured adult's sum insured. For
                          example, if an adult has Top cover on a single policy
                          and a claim is made under Section 13 Travel Delay, the
                          maximum amount that may be paid for 1 adult and their
                          children combined is $3,000. Please note there are
                          other sections of the policy whereby the limits are
                          itemised per person which includes dependents
                          individually e.g. Funeral expenses in section 2G.
                          Please refer to the
                          <a target="_blank" href="/pds"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_17"
                      (click)="toggleCollapse($event, 'faqsContent_17')"
                    >
                      Do I need to list all countries I will be travelling to
                      when I purchase my policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_17"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Yes, you should list all countries to confirm that cover
                        is available for the countries you are travelling to.
                        You should also check
                        <a
                          rel="noopener noreferrer"
                          href="https://www.smartraveller.gov.au/"
                          target="_blank"
                          >www.smarttraveller.gov.au</a
                        >
                        as we do not provide cover for any country or region
                        which is the subject of a “Do Not Travel“ advisory
                        issued by DFAT prior to you purchasing your policy.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_18"
                      (click)="toggleCollapse($event, 'faqsContent_18')"
                    >
                      Which policy do I select if I am going on an ocean cruise?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_18"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Cover for international and domestic river cruising is
                          included automatically in the policy and you do not
                          need to select the Cruise option to be covered.
                        </p>
                        <p>
                          If you are going on an ocean Cruise which stops at
                          other countries, you will need to select International
                          Cover and select each country where the cruise stops.
                        </p>
                        <p>
                          All international cruising will require you to select
                          the Cruise option to be covered while you are on your
                          cruise. An extra premium is payable for cruise cover.
                        </p>
                        <p>
                          If you are going on an Ocean cruise which is only in
                          Australian waters or calling into ports in Australia
                          you still require a policy which includes medical
                          cover whilst you are on board the ship, as many
                          on-board medical providers on cruises are not
                          registered with Medicare (check with your cruise
                          provider). You will need to select Australian Cruises
                          as your destination. This will then provide you
                          medical and evacuation cover whilst at sea, but not if
                          you go to a medical provider whilst in port in
                          Australia. Please refer to the
                          <a target="_blank" href="/pds"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                        <p>
                          Please note that no medical cover is included within
                          our Domestic policy and we cannot cover medical costs
                          in Australia. Therefore, the Domestic policy is not
                          suitable for cruises if you require cover for on board
                          medical treatment and evacuation.
                        </p>
                        <p style="margin-top: 15px">
                          An extra premium is payable for the Cruise cover
                          option.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_19"
                      (click)="toggleCollapse($event, 'faqsContent_19')"
                    >
                      When do I receive my policy documents?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_19"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        When you buy your policy, we will email your policy
                        documents to you. Your documents include your
                        Certificate of Insurance and your
                        <a target="_blank" href="/pds"
                          >World2Cover Travel Insurance Combined Financial
                          Services Guide and Product Disclosure Statement</a
                        >. It’s a good idea to carry a copy of your policy
                        documents with you when you travel. It is also a good
                        idea to keep them in your email inbox so you can access
                        them if you need to.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_20"
                      (click)="toggleCollapse($event, 'faqsContent_20')"
                    >
                      When does my policy start?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_20"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Depending on the level of cover selected, cover
                        commences from the date of purchase for the following
                        benefits (if available): Section 1: Cancellation Fees
                        and Lost deposits and Section 10 Financial Default . All
                        other benefits commence on the date your trip commences,
                        as stated on your certificate of insurance.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_21"
                      (click)="toggleCollapse($event, 'faqsContent_21')"
                    >
                      I made a mistake when purchasing my policy, how do I fix
                      it?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_21"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Please contact us on
                        <a href="tel:0292257599">02 9225 7599</a>, Mon-Fri 8m to
                        9pm, Saturday to Sunday 9am to 6pm as soon as you
                        realise the error so that we can review and arrange the
                        amendments where possible. You can also advise us by
                        email at
                        <a
                          href="mailto:travelservice@world2cover.com.au"
                          style="
                            color: #32b8c9;
                            background-color: #ffffff;
                            border-bottom: 0px;
                          "
                          >travelservice&#64;world2cover.com.au</a
                        >
                        with full details of the error and the correct details
                        as per your policy number.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_22"
                      (click)="toggleCollapse($event, 'faqsContent_22')"
                    >
                      What happens if I change my mind and want to cancel the
                      policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_22"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          <strong
                            >Cancelling within the cooling-off period</strong
                          >
                        </p>
                        <p>
                          You have 21 days from the day You purchase Your policy
                          to decide if the cover is right for You and suits Your
                          needs. If the policy does not meet Your needs You can
                          cancel Your policy within this “cooling-off period”
                          for a full refund, provided You:
                        </p>
                        <ul>
                          <li>Haven’t started Your Trip; and/or</li>
                          <li>Haven’t made a claim; and/or</li>
                          <li>
                            Don’t intend to make a claim or exercise any other
                            rights under Your policy.
                          </li>
                        </ul>
                        <p style="margin-top: 15px">
                          Simply contact Us on
                          <a href="tel:+13 13 29">13 13 29</a> within the
                          cooling-off period and We can arrange this for You.
                        </p>
                        <p>
                          <strong
                            >Cancellation outside the cooling-off period</strong
                          >
                        </p>
                        <p>
                          If You would like to cancel Your policy outside the
                          cooling-off period then You can request Us to consider
                          this, provided You:
                        </p>
                        <ul>
                          <li>Haven’t started Your Trip; and/or</li>
                          <li>Haven’t made a claim; and/or</li>
                          <li>
                            Don’t intend to make a claim or exercise any other
                            rights under Your policy.
                          </li>
                        </ul>
                        <p style="margin-top: 15px">
                          We will consider Your request and may at Our
                          discretion provide You with a pro-rated refund. This
                          refund (and Our decision in providing You with a
                          refund which will not be unreasonably withheld) will
                          be based on numerous factors including:
                        </p>
                        <ul>
                          <li>The level of cover/policy type chosen; and/or</li>
                          <li>
                            The date You purchased Your Policy and the date the
                            Trip would have begun; and/or
                          </li>
                          <li>Any other extenuating circumstances.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_23"
                      (click)="toggleCollapse($event, 'faqsContent_23')"
                    >
                      How do I extend my policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_23"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          You can extend your policy under certain conditions.
                        </p>
                        <p></p>
                        <p>
                          We will extend the term of Your cover for no
                          additional cost if any delay is due to a reason which
                          is covered under Your policy
                        </p>
                        <p>
                          For other reasons you can apply to extend your Single
                          Trip Policy by phoning us on
                          <a href="tel:0292257599">02 9225 7599</a> or sending
                          an email to
                          <a href="mailto:travelservice@world2cover.com.au"
                            >travelservice&#64;world2cover.com.au</a
                          >
                          as soon as practicable prior to your original policy
                          expiry date. Extension of cover needs our written
                          approval and you will need to pay any applicable extra
                          premium, if we agree to extend cover. We will issue a
                          new Certificate of Insurance. The period of insurance
                          on your new Certificate of Insurance combined with
                          your previous period of insurance, cannot be longer
                          than a combined maximum period of 12 months.
                          Applications to extend cover are subject to additional
                          conditions – please refer the
                          <a target="_blank" href="/pds"
                            >Product Disclosure Statement</a
                          >
                          for further details.
                        </p>
                        <p>You cannot extend your cover:</p>
                        <ul>
                          <li>
                            For any existing medical conditions, unless they are
                            listed in the
                            <a target="_blank" href="/pds">PDS</a> and you
                            haven’t been hospitalised (including Day Surgery or
                            Emergency attendance) in the past 12 months; or
                            unless declared, accepted and You have paid the
                            appropriate Premium; or
                          </li>
                          <li>
                            For any condition you suffered during the term of
                            your original policy; and
                          </li>
                          <li>
                            Where you have not advised us of any circumstances
                            that has given (or may give) rise to a claim under
                            your original Policy; or
                          </li>
                          <li>Under our Annual Multi-Trip plan</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_24"
                      (click)="toggleCollapse($event, 'faqsContent_24')"
                    >
                      What is an excess?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_24"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If you make a claim you may be required to pay an
                          excess. An excess is an agreed dollar amount that is
                          subtracted from each and every Insured Event. – see
                          the definitions of ‘Excess’ and ‘Insured Event’ in the
                          PDS for more information. Our standard excess is $200.
                          If you purchase the International Single Trip Top or
                          Essential cover, the Annual Multi-Trip or Domestic
                          Cover policy you can reduced the excess to $100 or $0,
                          for an increased premium at the time of purchase. If
                          you purchase the International Single Trip Basics
                          cover, you can only reduce the excess to $100, for an
                          increased premium at the time of purchase.
                        </p>
                        <p>
                          Your excess will be shown on your certificate of
                          insurance.
                        </p>
                        <p>
                          Please see the applicable
                          <a target="_blank" href="/pds">
                            Product Disclosure Statement</a
                          >
                          for more information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_25"
                      (click)="toggleCollapse($event, 'faqsContent_25')"
                    >
                      What is unattended luggage?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_25"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Unattended means but is not limited to, when an item
                          is not on Your person or under Your control, or the
                          control of Your Travelling Companion, at the time of
                          the loss, theft or damage, or left in a position where
                          it can be taken or damaged without Your or Your
                          Travelling Companion’s knowledge, including on the
                          beach or beside the pool while You swim, in a Public
                          Place or leaving it where You or Your Travelling
                          Companion are unable to prevent it from being
                          unlawfully taken or damaged. Unattended also means
                          leaving an item behind, forgetting the item, walking
                          away from it, or leaving it in a Public Place.
                        </p>
                        <p>
                          For the full list of exclusions, please read the
                          <a target="_blank" href="/pds">
                            Product Disclosure Statement</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_26"
                      (click)="toggleCollapse($event, 'faqsContent_26')"
                    >
                      Is there a maximum trip duration for an Annual Multi-Trip
                      policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_26"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The maximum days allowed per trip is either 30, 45 or 60
                        days, depending on the policy you choose. You must
                        select the appropriate number of days that will cover
                        any single trip you may take during the 12-month period
                        at the time of purchase. The days allowed per trip may
                        be able to be increased to a longer duration during the
                        period of the policy and an additional premium will
                        apply.
                      </p>
                      <p>
                        Please contact us for assistance at
                        <a href="tel:0292257599">02 9225 7599</a> - Our hours
                        are 8am to 9pm Monday to Friday &amp; 9am to 6pm
                        Saturday and Sunday.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_27"
                      (click)="toggleCollapse($event, 'faqsContent_27')"
                    >
                      Is there a limit to the amount of trips I can take on an
                      Annual Multi-Trip policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_27"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        You are covered for an unlimited number of trips over a
                        12-month period, provided that each trip is at least
                        250km from your home and less than the days selected per
                        trip (30, 45 or 60 days).
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_28"
                      (click)="toggleCollapse($event, 'faqsContent_28')"
                    >
                      Does my Annual Multi-Trip policy cover me domestically?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_28"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Your Annual Multi-Trip policy covers you for
                        international trips as well as trips within Australia
                        where the trip is more than 250km from your home up to
                        the maximum trip days selected. If your trip is in
                        Australia, you are not covered for medical, hospital or
                        dental expenses. Other exclusions may also apply. Please
                        read the PDS for further information which can be viewed
                        <a target="_blank" href="/pds"> here</a>.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_29"
                      (click)="toggleCollapse($event, 'faqsContent_29')"
                    >
                      Are all benefits payable per policy or per insured adult
                      on an Annual Multi-Trip policy?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_29"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        Most of your policy benefits are per insured adult,
                        however you should read the
                        <a target="_blank" href="/pds">
                          Product Disclosure Statement</a
                        >
                        for further information. The limits are reinstated after
                        each trip.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_30"
                      (click)="toggleCollapse($event, 'faqsContent_30')"
                    >
                      Can people with a joint Annual Multi-Trip policy travel
                      alone for a period of time and still be covered?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_30"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Insured adults on the policy can travel alone on a
                          trip. Dependents who are covered under the policy must
                          travel with an insured adult for the whole trip.
                        </p>
                        <p>
                          In respect of any Annual Multi-Trip plans means the
                          travel You are undertaking and commences from the time
                          You leave Your Home or place of departure to start
                          Your Trip until You return Home or until the end of
                          the Period of Insurance shown on the Certificate of
                          Insurance, whichever is sooner. The length of any one
                          Trip cannot exceed 30,45 or 60 days (depending on the
                          plan chosen) and must be at least 250km from Your
                          Home.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_31"
                      (click)="toggleCollapse($event, 'faqsContent_31')"
                    >
                      What is the emergency phone number to call while I am
                      travelling?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_31"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If You have an overseas medical emergency, our medical
                          Emergency Assistance team is available 24 hours a day,
                          365 days a year to take your call:
                        </p>
                        <p><strong>While travelling in Japan:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:08008009117">0800-800-9117</a></strong
                          >
                          (toll free) or if mobile access is restricted call
                          <strong
                            ><a href="tel:0362285881">03 6228 5881</a></strong
                          >
                          (local number in Japan)
                        </p>
                        <p><strong>For other overseas destinations:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:+61280551683"
                              >+61 2 8055 1683</a
                            ></strong
                          >
                          (reverse charges accepted from the overseas operator)
                          while travelling in all other countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_32"
                      (click)="toggleCollapse($event, 'faqsContent_32')"
                    >
                      Do you cover medical expenses in Australia?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_32"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <p>
                        No, our policies cover reasonable overseas medical costs
                        only and there is no medical, hospital or dental cover
                        on a Domestic policy or for medical expenses incurred in
                        Australia, or on an International policy following your
                        return to Australia. As a general insurer we are unable
                        to pay medical costs in Australia and this includes any
                        gap payments on any Medicare or private health insurer
                        items.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_33"
                      (click)="toggleCollapse($event, 'faqsContent_33')"
                    >
                      How do I make a claim?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_33"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          Claims can be lodged either online at
                          <a target="_blank" routerLink="/how-to-claim"
                            >world2cover.com.au/how-to-claim</a
                          >
                          under the “how to claim“ tab, by phone, or by email.
                          If lodging by phone or email the applicable claim form
                          will be provided for completion together with details
                          of the documentation that needs to be provided.
                        </p>
                        <p>
                          Providing the information needed helps Us to make a
                          timely and accurate decision about Your claim. You can
                          contact Us either during your trip or once you have
                          returned and we will guide you through the process. We
                          will not be able to process Your claim unless you
                          provide Us with all of the necessary information. Full
                          details should be submitted within 30 days of your
                          return.
                        </p>
                        <p>
                          For all claims, evidence of the medical condition
                          treated, incident or loss must be supported by the
                          relevant documentation e.g. police report, medical
                          report, receipts, proof of ownership etc. If you
                          cannot provide it, then We may reduce or refuse to pay
                          your claim. Any costs or expenses associated with
                          obtaining these items documents will be at Your own
                          cost.
                        </p>
                        <p>
                          <strong>Web: </strong>
                          <a target="_blank" routerLink="/how-to-claim"
                            >world2cover.com.au/how-to-claim</a
                          >
                        </p>
                        <p>
                          <strong>Phone: </strong
                          ><a href="tel:0292257599"> 02 9225 7599</a>
                        </p>
                        <p>
                          <strong>Email: </strong>
                          <a href="mailto:travelclaims@world2cover.com.au"
                            >travelclaims&#64;world2cover.com.au</a
                          >
                        </p>
                        <p>
                          <strong>Mail: </strong><br />
                          World2Cover Claims<br />
                          GPO Box 4616<br />
                          Sydney 2001
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_34"
                      (click)="toggleCollapse($event, 'faqsContent_34')"
                    >
                      What happens if I get sick or injured overseas, but don't
                      feel it's an emergency?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_34"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If you are not hospitalised or you are being treated
                          as an outpatient and the total cost of any
                          consultation or treatment will exceed AUD $2,000, you
                          or a member of your travelling party, should contact
                          us as soon as reasonably <span>practicable</span>. If
                          you do not contact us, and incur costs
                          <span>without </span>our consent, we may limit the
                          amount payable under a claim, to the amount we would
                          have paid towards any expenses (including medical) or
                          for any evacuation/repatriation or airfares that have
                          not been approved or arranged by us..
                        </p>
                        <p>
                          You will need to keep all your medical reports and
                          receipts from the doctors and or hospital. These
                          documents will be needed to support your claim when it
                          is lodged.
                        </p>
                        <p>
                          Our medical emergency team is available 24 hours a
                          day, 365 days a year to take your call.
                        </p>
                        <p><strong>While travelling in Japan:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:08008009117">0800-800-9117</a></strong
                          >
                          (toll free) or if mobile access is restricted call
                          <strong
                            ><a href="tel:0362285881">03 6228 5881</a></strong
                          >
                          (local number in Japan)
                        </p>
                        <p><strong>For other overseas destinations:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:+61280551683"
                              >+61 2 8055 1683</a
                            ></strong
                          >
                          (reverse charges accepted from the overseas operator)
                          while travelling in all other countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_35"
                      (click)="toggleCollapse($event, 'faqsContent_35')"
                    >
                      If I make a claim, what do I need to do when I return
                      home?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_35"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          For all claims You must let Us know of your claim as
                          soon as possible, full details should be submitted
                          within 30 days after your return Home either by:
                        </p>
                        <ul>
                          <li>
                            Online claim form on the
                            <a target="_blank" routerLink="/how-to-claim"
                              >How to Claim</a
                            >
                            tab on our website, or
                          </li>
                          <li>
                            Calling Us on
                            <a href="tel:0292257599">+61 2 9225 7599</a> - Our
                            hours are 8am to 8pm Monday to Friday &amp; 9am to
                            6pm Saturday and Sunday, or,
                          </li>
                          <li>
                            Emailing Us at
                            <a href="mailto:travelclaims@world2cover.com.au"
                              >travelclaims&#64;world2cover.com.au</a
                            >, or,
                          </li>
                          <li>
                            Writing to Us at World2Cover Claims, GPO Box 4616,
                            Sydney NSW 2001.
                          </li>
                        </ul>
                        <p style="margin-top: 10px">
                          Once we have received the notification of your claim,
                          we will advise you what information we require and
                          guide you through the claims process.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_36"
                      (click)="toggleCollapse($event, 'faqsContent_36')"
                    >
                      Who do I contact if I get sick or injured while travelling
                      overseas?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_36"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <p>
                          If you have an overseas medical emergency, you should
                          contact our Emergency Assistance team for help. Our
                          team is available 24 hours a day, 365 days a year to
                          take your call.
                        </p>
                        <p><strong>While travelling in Japan:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:08008009117">0800-800-9117</a></strong
                          >
                          (toll free) or if mobile access is restricted call
                          <strong
                            ><a href="tel:0362285881">03 6228 5881</a></strong
                          >
                          (local number in Japan)
                        </p>
                        <p><strong>For other overseas destinations:</strong></p>
                        <p>
                          <strong
                            >Call
                            <a href="tel:+61280551683"
                              >+61 2 8055 1683</a
                            ></strong
                          >
                          (reverse charges accepted from the overseas operator)
                          while travelling in all other countries.
                        </p>
                        <p>
                          If You are hospitalised, You, or a member of Your
                          travelling party, must contact Us as soon as possible.
                          If You do not, then to the extent permissible by law,
                          We will not pay for any expenses or for any
                          evacuation/repatriation or airfares that have not been
                          approved or arranged by Us.
                        </p>
                        <p>
                          If you are not hospitalised but you are being treated
                          as an outpatient and the total cost of any treatment
                          will exceed AUD $2,000, you must contact us. We will
                          not pay for any expenses that they have not approved.
                        </p>
                        <p>
                          You will need to keep all your medical reports and
                          receipts from the doctors and or hospital. These
                          documents will be needed to support your claim when it
                          is lodged.
                        </p>
                        <p>
                          It is always a good idea to register your details with
                          Smart Traveller before your depart, at
                          <a
                            rel="noopener noreferrer"
                            href="https://www.smartraveller.gov.au/"
                            target="_blank"
                            >www.smarttraveller.gov.au</a
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <div
                      class="accordion-title collapsed"
                      type="button"
                      aria-expanded="false"
                      aria-controls="faqsContent_37"
                      (click)="toggleCollapse($event, 'faqsContent_37')"
                    >
                      Who do I contact if I need an interpreter?
                    </div>
                  </h2>
                  <div
                    id="faqsContent_37"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqsAccordion"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <h4>Interpreting Services</h4>
                        <p>
                          We are pleased to offer assistance in the following
                          ways if you need help understanding any aspect of your
                          travel insurance policy or claim in your language.
                        </p>
                        <h4>Contact us by phone</h4>
                        <p>
                          Our team has access to many languages to assist you
                          directly. Call us on
                          <a href="tel:0292257599">(02) 9225 7599</a>
                        </p>
                        <h4>Contact Translating and Interpreting Services</h4>
                        <p>
                          Translating and Interpreting Services (TIS) are
                          available 24/7 and their interpreters speak over 160
                          languages. They're available over the phone and in
                          person.
                        </p>
                        <p>
                          Book an
                          <a
                            href="https://www.tisnational.gov.au/"
                            target="_blank"
                            >appointment with TIS</a
                          >, or call them on 131 450. This is a free service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
