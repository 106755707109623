import moment from 'moment-timezone';
// import { isDefined } from '@angular/compiler/src/util';
// import { isDate } from 'util';
import { DateService } from '../services/date.service';
import { Moment } from 'moment';

export class Utility {
  private static dateService = new DateService();

  public static promoCodeRegExPattern = /^([ a-zA-Z0-9_@()%$&*|\/{}-]){0,25}$/i;

  public static clearCacheInScItemServiceData = true;

  //khushboo changes.
  public static timeZoneDateFormatter(timeZone: any) {
    // let formattedDate = moment().tz('Australia/Sydney');
    return new Date(moment().tz(timeZone).format('YYYY/MM/DD'));
  }

  public static IsExistInArray(stringArray: any[], matchValue: any): boolean {
    return stringArray.find((c) => c.toUpperCase() == matchValue.toUpperCase());
  }

  public static differenceInDays(firstDate: any, secondDate: any) {
    return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
  }
  public static getBaseUrl() {
    let baseUrl = window.location.origin
      ? window.location.origin + '/'
      : window.location.protocol + '/' + window.location.host + '/';
    return baseUrl;
  }
  public static removeExtraSlash(url: any) {
    return url.replace(/([^:]\/)\/+/g, '$1');
  }

  public static getFullUrl() {
    let baseUrl = window.location.href;
    return baseUrl;
  }

  public static SortOrder(dataArray: any, object: any) {
    return dataArray.sort(
      (a: any, b: any) => object[a.productName] - object[b.productName],
    );
  }

  public static alphaNumeric(val: any): any {
    if (val.pristine) {
      return null;
    }
    const VAL_REGEXP = /^(?=.*[a-z0-9])[a-z0-9!@#$,'"/&*.]{0,}$/i;
    val.markAsTouched();
    if (VAL_REGEXP.test(val.value)) {
      return null;
    }
    return {
      invalidVal: true,
    };
  }

  public static onlyAlphabets(event: any) {
    const pattern = /^[a-zA-Z]+$/i;
    let fieldId = event.target.id;
    let inputChar = String.fromCharCode(event.keyCode);
    let textValue = event.target.value;
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.target.value = textValue.replace(/[^a-zA-Z]/g, '');
      return false;
    }
    return true;
  }

  public static onlyNumberNSlash(event: any) {
    const pattern = /^[0-9-/-]+$/i;
    let fieldId = event.target.id;
    let inputChar = String.fromCharCode(event.keyCode);
    let textValue = event.target.value;
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.target.value = textValue.replace(/[^0-9-/-]/g, '');
      return false;
    }
    return true;
  }

  public static differenceInDaysDate(firstDate: any, secondDate: any) {
    var datePartsF = firstDate.split('/');
    var datePartsS = secondDate.split('/');
    firstDate = new Date(`${datePartsF[1]}/${datePartsF[0]}/${datePartsF[2]}`,);
    secondDate = new Date(`${datePartsS[1]}/${datePartsS[0]}/${datePartsS[2]}`,);
    return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
  }

  public static dateDiffFromNow(incomingDate: any) {
    const parsedDate = this.formatDate(new Date());
    return this.differenceInDaysDate(incomingDate, parsedDate);
  }

  public static formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  public static _keyUp(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public static validateDec(key: any) {
    //getting key code of pressed key
    var keycode = key.which ? key.which : key.keyCode;
    //comparing pressed keycodes
    if (!(keycode == 8 || keycode == 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      // var parts = key.srcElement.value.split('.');
      // if (parts.length > 1 && keycode == 46)
      //   return false;``
      return true;
    }
  }

  public static allowOnly4number(event: any) {
    // return event.charCode >= 48 && event.charCode <= 5;
    const pattern = /^[0-9]{4}$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8) {
      event.preventDefault();
    }
  }

  public static onlyAlphanumeric(event: any) {
    const pattern = /^[a-z0-9]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  public static onlyAlphanumericNSpace(event: any) {
    const pattern = /^[a-z0-9- ]+$/i;
    let fieldId = event.target.id;
    let inputChar = String.fromCharCode(event.keyCode);
    let textValue = event.target.value;
    if (event.keyCode != 8 && !pattern.test(textValue)) {
      event.target.value = textValue.substring(0, textValue.length - 1);
      return false;
    }
    return true;
  }

  public static AlphanumericNSpecialchar(event: any) {
    // along with alphanumeric, a set of special chara allowed: @ $ & / {}()|_-
    const pattern = Utility.promoCodeRegExPattern;
    let fieldId = event.target.id;
    let inputChar = String.fromCharCode(event.keyCode);
    let textValue = event.target.value;
    if (event.keyCode != 8 && !pattern.test(textValue)) {
      event.target.value = textValue.substring(0, textValue.length - 1);
      return false;
    }
    return true;
  }

  public static leapYear(date: any) {
    const convertedDate = this.toDate(date);
    if (convertedDate) {
      let year = convertedDate.getFullYear();
      return (year & 3) == 0 && (year % 25 != 0 || (year & 15) == 0);
    }
    return date;
  }

  public static isDefined<T>(value: T | undefined | null): value is T {
    return <T>value !== undefined && <T>value !== null;
  }

  public static ageFromDateOfBirth(
    dob: Date,
    pricingDate: string | Date | Moment,
  ): number {
    return this.dateService.ageInYears(dob, pricingDate);
  }
  public static ageFromDDMMYYYFormat(dateStr: string) {
    const dateParts = dateStr.split('/');
    const birthDate = new Date(
      `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`,
    );
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  public static random10DigitNumber() {
    return Math.floor(Math.random() * 90000) + 10000;
  }

  public static combineStrings(firstName: any, middleName: any, lastName = '') {
    return `${firstName} ${middleName} ${lastName}`.trim();
  }

  public static getDOBfromAge(age: number) {
    var birthDate = new Date();
    birthDate.setDate(birthDate.getDate() - 365 * 10);
    return birthDate;
  }

  public static getFormattedDate(date: any) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }
  public static IsQueryStringExists() {
    let url = this.getFullUrl();
    let exists = url.split('?').length > 1;
    if (exists) return true;
    return false;
  }

  public static IsComingFromResumeQuote(): boolean {
    let qryString = window.location.search;
    return (
      (qryString &&
        (qryString.indexOf('a=') !== -1 || qryString.indexOf('b=') !== -1)) ||
      false
    );
  }

  // public static GetPolicyTxnIdFromURL() {
  //   var url_string = this.getFullUrl();
  //   var url = new URL(url_string);
  //   var c = url.searchParams.get("PolicyId");
  //   // console.log(c);
  //   return c;
  // }

  public static getQryStringKeyValue(key: string, array: any[]) {
    let result = array.find(
      (element) => element.key.toLowerCase() == key.toLowerCase(),
    );
    return result ? result.value.trim() : '';
  }


  //this is introduce becuase readQueryString is not working
  public static getUrlParameter(name: any) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }


  //it will not work if the value has =. ie. ?a=MTIwNDQyNQ==&b=MQ==
  public static readQueryString() {
    let url = this.getFullUrl();
    let finalArray: any;
    if (url) {
      let queryStringPart = this.DecodeBase64(url.split('?').pop());
      if (queryStringPart) {
        let arrayQueryParam = queryStringPart
          .replace(/&amp;/g, '&')
          .split(/&(?!\s)/g);
        finalArray = arrayQueryParam.map((param: any) => {
          let _param = param.split('=');
          return { key: _param[0], value: _param[1] };
        });
      }
    }
    return finalArray;
  }

  static IsValidGuid(guid: string): boolean {
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(guid);
  }

  static EncodeBase64(str: any) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(('0x' + p1) as any);
      }),
    );
  }

  static DecodeBase64(str: any) {
    try {
      let decoded = decodeURIComponent(
        atob(str)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
      str = decoded;
    } finally {
      return str;
    }
  }

  public static readEncodedQueryString() {
    let url = this.getFullUrl();
    let finalArray: any;
    if (url) {
      let queryStringPart = url.split('?').pop();
      if (queryStringPart) {
        let arrayQueryParam = queryStringPart.split('&');
        finalArray = arrayQueryParam.map((param) => {
          let _index = param.indexOf('=');
          let key = param.substring(0, _index);
          let value = param.substring(_index + 1, param.length);
          return { key: key, value: value };
        });
      }
    }
    return finalArray;
  }

  public static isValidDate(dateString: any) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

    // Parse the date parts to integers
    var parts = dateString.split('/');
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || month < 1 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  public static isTrue(value: string | boolean): boolean {
    if (typeof value === 'string')
      return (value || 'false').toLowerCase() === 'true' ? true : false;

    return value ? true : false;
  }

  public static convertNumberToCurrency(number: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(number); // "$1,000.00"
    // const formatter = new Intl.NumberFormat('it-IT', {
    //   style: 'currency',
    //   currency: 'EUR'
    // })
    //formatter.format(10) // "$10.00"
    //formatter.format(123233000) // "$123,233,000.00"
  }

  public static convertCurrencyToNumber(currency: any) {
    //var currency = "-$4,400.50";
    if (!currency) return currency;
    if (currency === 'NA') {
      return currency;
    }

    currency = `${currency}`;
    return Number(currency.replace(/[^-0-9\.]+/g, ''));
  }
  //Handle Null
  // public static typeSafeHandle(obj: any, props: string, dataType: string = ""): any {
  //   let result: any = this.get(obj, props);
  //   if (isDefined(result)) {
  //     return result;
  //   }
  //   switch (dataType) {
  //     case 'int':
  //       result = 0;
  //       break;
  //     case 'boolean':
  //       result = false;
  //       break;
  //     case 'array':
  //       result = [];
  //       break;
  //     case 'split':
  //       result = ["", "", ""];
  //       break;
  //     default:
  //       result = "";
  //       break;
  //   }
  //   return result;
  // }

  public static get<T, P1 extends keyof NonNullable<T>>(
    obj: T,
    prop1: P1,
  ): NonNullable<T>[P1] | undefined;

  public static get<
    T,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>,
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
  ): NonNullable<NonNullable<T>[P1]>[P2] | undefined;

  public static get<
    T,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>,
    P3 extends keyof NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>,
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
    prop3: P3,
  ): NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3] | undefined;

  // ...and so on...

  public static get(obj: any, props: string): any {
    if (props) {
      return (
        obj &&
        props
          .split('.')
          .reduce(
            (result, prop) => (result == null ? undefined : result[prop]),
            obj,
          )
      );
    } else {
      return '';
    }
  }
  public static isYes(value: any) {
    if (value) {
      if (value.toUpperCase() === 'YES') return true;
    }
    return false;
  }
  public static getFullName(
    firstName: string,
    midName: string,
    lastName: string,
    isCamelCase: boolean = false,
  ) {
    let fullName = '';
    if (!this.isNullOrEmpty(firstName)) {
      fullName = firstName;
    }
    if (!this.isNullOrEmpty(midName)) {
      fullName = fullName != '' ? `${fullName} ${midName}` : `${midName}`;
    }
    if (!this.isNullOrEmpty(lastName)) {
      fullName = fullName != '' ? `${fullName} ${lastName}` : `${lastName}`;
    }
    //alert(this.camelize(fullName));
    return isCamelCase ? this.properCase(fullName) : fullName;
  }

  public static isNullOrEmpty(value: any) {
    if (!value || value === '' || value === undefined || value.length < 0) {
      return true;
    }
    return false;
  }

  public static properCase(str: any, isCamelCase: boolean = false) {
    return str
      .split(' ')
      .map(function (word: any, index: any) {
        // If it is the first word make sure to lowercase all the chars.
        if (isCamelCase && index == 0) {
          return word.toLowerCase();
        }
        // If it is not the first word only upper case the first char and lowercase the rest.
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }

  public static asyncDataStorage = {
    setItem: function (key: any, value: any, storage: any) {
      return Promise.resolve().then(function () {
        storage.setItem(key, value);
      });
    },
    getItem: function (key: any, storage: any) {
      return Promise.resolve().then(function () {
        return storage.getItem(key);
      });
    },
  };

  public static filterFromPolicyElementValue(
    elementName: any,
    elementId: any,
    policyData: any,
  ) {
    let result: any;

    if (!elementName) {
      // console.log(`ElementName is blank`);
      result = null;
    }

    let filterData =
      policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests.find(
        (element: any) =>
          element.ElementName === elementName || element.ElementId == elementId,
      );
    if (!filterData) {
      // console.log(`elementId: ${elementName} of PolicyData not available in: ` + JSON.stringify(policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests));
      result = null;
    } else if (filterData) {
      switch (filterData.ResponseType.trim()) {
        case 'FreeTextResponse':
          result = filterData.FreeTextValue;
          break;
        default:
          break;
      }
      return result;
    }
    return result;
  }

  public static IsMobileScreen(): boolean {
    let mq = window.matchMedia('(max-width: 979px)');
    return mq.matches || false;
  }

  public static utcToAestDate(utcDate: string) {
    return this.dateService.getDateAsOfAESTFromDate(
      this.dateService.getMomentFromDate(utcDate),
    );
  }

  //This is returning in browser timezone
  public static toDate(dateInput: string | Date): Date {
    let convertedDate: any;

    if (!dateInput) throw 'Invalid date.';

    //when we are getting 10 digit string, it has to be "DD/MM/YYYY" format
    if (typeof dateInput === 'string') {
      if ((dateInput as string).length === 10) {
        convertedDate = moment(dateInput, 'DD/MM/YYYY');
      } else {
        convertedDate = new Date(dateInput);
      }
    }
    // else if (isDate(dateInput)) {
    //   convertedDate = new Date(dateInput);
    // }
    else {
      throw 'Invalid date.';
    }

    return convertedDate;
  }

  /**
   * static checkKeyInObject
key, objectData   */
  public static checkKeyInObject(id: string, objectData: object) {
    let result = false;
    for (const key in objectData) {
      if (objectData.hasOwnProperty(key)) {
        if (key && id && key.toLocaleLowerCase() === id.toLocaleLowerCase()) {
          result = true;
        }
      }
    }
    return result;
  }

  public static returnObjectValue(key: any, data: any) {
    let response;
    Object.keys(data).forEach((element) => {
      if (element == key.toLowerCase()) {
        response = data[element];
      } else if (element == key.toUpperCase()) {
        response = data[element];
      }
    });
    return response;
  }

  public static lowerCaseObjectKeys(object: any) {
    // clone the original object so that we don't modify its state
    const newObject = Object.assign({}, object);

    Object.keys(newObject).forEach((key) => {
      // if key is already upper-case, skip it & move on!
      if (key.toUpperCase() === key) {
        return;
      }

      // set a new, upper-cased key in newObject
      newObject[key.toUpperCase()] = newObject[key];
      // delete the original, non-upper-cased key (& value) from newObject
      delete newObject[key];
    });

    return newObject;
  }

  /**
   * name
   */
  public static checkCookiesAccess() {
    if (navigator.cookieEnabled) {
      return true;
    } else {
      console.log('cookies are disabled');
      return false;
    }
  }

  public static getQStringPart() {
    let url = this.getFullUrl();
    let queryStringPart: string = '';
    if (url) {
      queryStringPart = this.DecodeBase64(url.split('?').pop());
    }
    return queryStringPart;
  }

  public static convertToDate(input: string): Date | null {
    // Mapping of month abbreviations to their numeric values
    const monthMap = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    } as any;
    // Split the input by common date separators
    const parts = input.split(/[\s./-]+/);
    let day: string = '',
      month: string = '',
      year: string = '';

    // Determine if any part contains letters (month name)
    const containsMonthName = parts.some((part) => /[a-zA-Z]/.test(part));

    if (containsMonthName) {
      parts.forEach((part) => {
        if (isNaN(+part)) {
          // If part contains letters, treat it as month
          month = monthMap[part] || '01'; // Default to '01' if not found
        } else if (part.length === 4) {
          // If part is four digits, treat it as year
          year = part;
        } else if (part.length === 2 && !year) {
          // If part is two digits and no year is set yet, assume it's yy format
          const currentYear = new Date().getFullYear().toString().substr(0, 2); // Get current century
          year = currentYear + part;
        } else {
          // Otherwise treat it as day
          day = part.padStart(2, '0');
        }
      });
    } else {
      // If no month name, assume format is numeric and use different logic
      if (parts.length === 3) {
        if (parts[0].length === 4) {
          // If first part is year (yyyy/mm/dd)
          year = parts[0];
          month = parts[1].padStart(2, '0');
          day = parts[2].padStart(2, '0');
        } else {
          // Assume dd/mm/yyyy
          day = parts[0].padStart(2, '0');
          month = parts[1].padStart(2, '0');
          year =
            parts[2].length === 4
              ? parts[2]
              : this.convertTwoDigitYearToFourDigit(parts[2]);
        }
      }
    }

    // Handle invalid input gracefully
    if (!day || !month || !year) {
      return null;
    }

    // Return formatted date as dd/mm/yyyy
    return new Date(`${year}-${month}-${day}`);
  }

  static convertTwoDigitYearToFourDigit(twoDigitYear: string): string {
    const currentYear = new Date().getFullYear().toString().substr(0, 2); // Get current century
    const twoDigitYearNum = parseInt(twoDigitYear, 10);
    const currentYearLastTwoDigits = parseInt(currentYear.substr(2, 2), 10);

    // Define a threshold to determine the century (e.g., 10 years in the future)
    const threshold = 10;

    // If the two-digit year is less than the threshold, assume it belongs to the current century
    if (twoDigitYearNum <= currentYearLastTwoDigits + threshold) {
      return currentYear + twoDigitYear;
    }

    // Otherwise, assume it belongs to the current century
    return parseInt(currentYear, 10) + twoDigitYear;
  }
}
