import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-covid-faqs',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './covid-faqs.component.html',
  styleUrl: './covid-faqs.component.scss',
})
export class CovidFaqsComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Covid FAQs');
  }
}
