

<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>
 
  <!-- End Header Part -->
  
  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>   
      <img src={{pageTitleImg}} alt="" class="w-100 page-title-image">
      <div class="page-header-title-caption">
        <h1>{{ pageTitle }}</h1>
        <ng-container *ngIf="pageTitle == 'World2Cover Awards'">
          <p>Australian travellers need quality travel insurance. Learn how you could choose a Multiple Awarded World2Cover travel insurance product.</p>
        </ng-container>
      </div>       
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-content-info" [innerHTML]="pageContent"> 
          </div>
        </div> 
      </div>
    </div>
  </main>
  <!-- Footer Area -->
  <app-footer></app-footer>
</div>