import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';

import { DateService } from '../../services/date.service';
import { TooltipModule } from 'primeng/tooltip';
import { LocalCacheService } from '../../services/localCache.service';

@Component({
  selector: 'app-choose-your-option',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    RadioButtonModule,
    FormsModule,
    OverlayPanelModule,
    ButtonModule,
    CalendarModule,
    TooltipModule,
  ],
  templateUrl: './choose-your-option.component.html',
  styleUrl: './choose-your-option.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChooseYourOptionComponent {
  @Input() quoteEnquiry: any;
  @Output() optionChange = new EventEmitter<string>();
  @Input() selectedCover: any;
  @Input() isCruise: boolean = false;
  @Input() isSki: boolean = true;
  selectedExcessValue: string = '';
  selectedExcessLabel: string = '$0';
  selectedCruiseValue: string = '';
  selectedCruiseLabel: string = '';
  selectedSportsValue: string = '';
  selectedSportsLabel: string = '';

  isTooltipVisible: boolean = false;
  isTooltipVisibleCruise: boolean = false;
  isTooltipVisibleSki: boolean = false;
  isTooltipVisibleExcess: boolean = false;
  skiTooltipMessage: string = `You must purchase the Ski and Winter Sports option in order to be covered
    for Overseas Medical and Hospital expenses that are incurred following an
    Accident that takes place whilst participating in Skiing or a Winter Sport.
    Not all Winter Sports are covered and these are explained in the PDS.`;
  constructor(
    private dateService: DateService,
    private localStorageService: LocalCacheService,
  ) {}

  ngOnInit() {
    this.updateTooltipVisibility();
    const isDomestic =
      this.localStorageService.getSessionStorage('domesticCountry');
    if (isDomestic) {
      this.skiTooltipMessage = `You must purchase the Ski and Winter Sports option to be covered for expenses related to snow ski equipment hire, ski pack, bad weather, avalanche, and lift pass. However, no medical or hospital expenses are covered. Please note that not all Winter Sports are covered, these are explained in the PDS.`;
    }
    if (this.selectedCover) {
      if (this.selectedCover?.selectedPrice?.isCruise) {
        this.selectedCruiseLabel = 'cruise_yes';
      }
      if (!this.selectedCover?.selectedPrice?.isCruise) {
        this.selectedCruiseLabel = 'cruise_no';
      }
      if (this.selectedCover?.selectedPrice?.isSki) {
        this.selectedSportsLabel = 'sports_yes';
      }
      if (!this.selectedCover?.selectedPrice?.isSki) {
        this.selectedSportsLabel = 'sports_no';
      }
      if (this.selectedCover?.selectedPrice?.excess) {
        this.selectedExcessLabel = this.selectedCover?.selectedPrice?.excess;
      }
    }
  }

  onRadioExcessClick(event: any, label: string) {
    this.selectedExcessLabel = label;
    const data: any = {
      optionName: 'excess',
      excess: label,
      isCruise: this.selectedCover?.selectedPrice?.isCruise,
      isSki: this.selectedCover?.selectedPrice?.isSki,
    };
    this.optionChange.emit(data);
  }

  onRadioCruiseClick(event: any, label: string) {
    this.selectedCruiseLabel = label;
    const isCruise = label === 'cruise_yes' ? true : false;
    const isSki = this.selectedCover?.selectedPrice?.isSki;
    const data = this.getSelectedOption('cruise', isCruise, isSki);
    this.optionChange.emit(data);
  }

  onRadioSportsClick(event: any, label: string) {
    this.selectedSportsLabel = label;
    const isCruise = this.selectedCover?.selectedPrice?.isCruise;
    const isSki = label === 'sports_yes' ? true : false;
    const data = this.getSelectedOption('sports', isCruise, isSki);
    this.optionChange.emit(data);
  }
  getSelectedOption(optionName: string, isCruise: boolean, isSki: boolean) {
    const data: any = {
      optionName: optionName,
      excess: this.selectedCover?.selectedPrice?.excess,
      isCruise: isCruise,
      isSki: isSki,
    };
    return data;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateTooltipVisibility();
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  private updateTooltipVisibility() {
    this.isTooltipVisible = window.innerWidth < 768;
    this.isTooltipVisibleSki = window.innerWidth < 768;
    this.isTooltipVisibleCruise = window.innerWidth < 768;
    this.isTooltipVisibleExcess = window.innerWidth < 768;
  }
}
