import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-emergency',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './emergency.component.html',
  styleUrl: './emergency.component.scss',
})
export class EmergencyComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Emergency | World2Cover Travel Insurance');
    this.metaService.updateTag({
      name: 'description',
      content: `At World2Cover you have access 24/7 to our assistance medical and emergency number`,
    });
  }
}
