<section class="faqs pt-5">
  <div class="container">
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-10 p-3 text-center">
        <h2 class="display-5 fw-bold text-left mb-3">FAQs</h2>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-12 col-md-11 p-3">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                aria-expanded="false"
                aria-controls="collapseOne"
                (click)="toggleCollapse($event, 'collapseOne')"
              >
                COVID-19 FAQs
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-left">
                <p>
                  These FAQs should be read in conjunction with the Product
                  Disclosure Statement (PDS). You should consider the
                  <a
                    target="_blank"
                    href="/pds"
                    (click)="triggerSegmentEvent('faqCovidPds')"
                    >PDS</a
                  >
                  for the terms, limits, conditions and exclusions before making
                  a decision about whether to acquire or to continue to hold
                  this insurance.
                </p>
                <p>
                  As a standard exclusion under all policies, the country you
                  are travelling must not be subject to a Do Not Travel advice
                  on the
                  <a target="_blank" href="https://www.smartraveller.gov.au/"
                    >Smartraveller website</a
                  >
                  at the time you purchased the policy. This is regardless of
                  any exemption you may have received to travel and your ability
                  to purchase a policy.
                </p>
                <p>
                  We have outlined the most commons scenarios here. Please
                  contact us if you have any questions or require any further
                  information. Customers always have a right to lodge a claim
                  for consideration against the terms and conditions of the
                  policy and the individual circumstances.
                </p>
                <h6>
                  Am I covered if I need to quarantine once I enter a
                  region/country based on the government or state regulations?
                </h6>
                <p>
                  No Cover is available under any of our policies. All policies
                  have a general exclusion relating to claims directly or
                  indirectly arising from any government or public health
                  authority mandatory quarantine or isolation order imposed on
                  you related to border, region or territory travel in response
                  to COVID-19.
                </p>

                <h6>
                  Am I covered for medical expenses if I am diagnosed with
                  COVID-19 during my trip?
                </h6>
                <ul>
                  <li>
                    Australia – No medical cover is included on this plan.
                  </li>
                  <li>
                    International – Yes, the Top Cover plan includes unlimited*
                    overseas medical expenses, for reasonable costs you incur
                    due to COVID-19. There is no cover under the Essentials
                    Cover or Basic Cover plans.
                  </li>
                  <li>
                    Annual Multi-Trip – No, no COVID-19 benefits are available
                    on this plan.
                  </li>
                </ul>

                <h6>
                  Am I covered for funeral and emergency expenses if I die from
                  COVID-19 during my trip?
                </h6>

                <ul>
                  <li>
                    Australia – There is no medical cover included on this plan.
                  </li>
                  <li>
                    International – Yes Cover is available on the Top cover plan
                    up to a maximum limit of $20,000 per person. There is no
                    cover under the Essentials Cover or Basic Cover plans.
                  </li>
                  <li>
                    Annual Multi-Trip – No, no COVID-19 benefits are available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  What if I am diagnosed with COVID-19 on my trip and I need to
                  quarantine are my emergency/additional expenses covered?
                </h6>

                <ul>
                  <li>Australia – Yes</li>
                  <li>
                    International – Yes only on the Top Cover plan. There is no
                    cover under the Essentials Cover or Basic Cover plans.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  Am I covered whilst on my trip if I am deemed a close contact
                  due to a COVID-19 case and I need to quarantine before
                  continuing on with my trip?
                </h6>
                <ul>
                  <li>Australia – Yes</li>
                  <li>
                    International – Yes, only on the Top Cover plan, up to a
                    total combined limit of $3,500 per listed adult. If you have
                    selected either the Essentials Cover or Basic Cover plans,
                    then no COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>What other emergency expenses do you cover?</h6>
                <ul>
                  <li>
                    Australia – No cover for emergency expenses under Section
                    21. Refer to Section 23 COVID-19 Additional Expenses.
                  </li>
                  <li>
                    International – On the Top Cover plan that indicates covid
                    cover included we cover all reasonable emergency expenses of
                    any insured event that we have accepted cover for under
                    Section 21A. If you have selected either the Essentials
                    Cover or Basic Cover plans, then no COVID-19 benefits are
                    available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  Am I covered for additional expenses and/or cancellation fees
                  apply if my relative or business partner dies from COVID-19
                  and I need to cancel my trip or cut my trip short and come
                  home?
                </h6>
                <ul>
                  <li>
                    Domestic – Yes, there are limited COVID-19 benefits
                    available, up to a total combined limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - Yes, only on the Top Cover plan. There are
                    limited COVID-19 benefits available, up to a total combined
                    limit of $3,500 per listed adult. If you have selected
                    either the Essentials Cover or Basic Cover plans, then no
                    COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  Am I covered for COVID-19 if I am travelling on a multi-night
                  Cruise?
                </h6>
                <p>
                  Limited COVID-19 benefits are available only if you have
                  selected ‘Going on an Ocean Cruise’ option and purchased the Top
                  Cover plan. This cover includes unlimited* benefits for
                  COVID-19 Overseas Medical and Hospital and Emergency
                  Expenses*. Cover is also available for Cancellation Fees and
                  Lost Deposits; Additional Expenses and Special Events; and
                  Travel Delay, to a maximum combined limit of $3,500 per adult
                  (maximum of two adults per policy). Declared dependents are
                  included in the total combined limit for COVID-19 benefits per
                  declared adult.
                </p>
                <h6>
                  Am I covered for cancellation fees and lost deposits apply if
                  I am diagnosed with COVID-19 and I need to cancel my trip or
                  cut my trip short and/or come home?
                </h6>
                <ul>
                  <li>
                    Domestic – Yes, there are limited COVID-19 benefits
                    available, up to a total combined limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - If you have selected the Top Cover plan,
                    there are limited COVID-19 benefits available, up to a total
                    combined limit of $3,500 per listed adult. If you have
                    selected either the Essentials Cover or Basic Cover plans,
                    then no COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  Am I covered if my travelling companion is diagnosed with
                  COVID-19 and I need to cut my trip short and/or come home?
                </h6>
                <ul>
                  <li>
                    Domestic – Yes, there are limited COVID-19 benefits
                    available, up to a total combined limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - If you have selected the Top Cover plan,
                    there are limited COVID-19 benefits available, up to a total
                    combined limit of $3,500 per listed adult. If you have
                    selected either the Essentials Cover or Basic Cover plans,
                    then no COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  Am I covered if my travelling companion is diagnosed with
                  COVID-19 and my trip is cancelled?
                </h6>
                <ul>
                  <li>
                    Domestic – Yes, there are limited COVID-19 benefits
                    available, up to a total combined limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - If you have selected the Top Cover plan,
                    there are limited COVID-19 benefits available, up to a total
                    combined limit of $3,500 per listed adult. If you have
                    selected the Essentials Cover or Basic Cover plans, then no
                    COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>
                <h6>
                  Am I covered if I am not vaccinated and it is a condition of
                  the transport provider that I must be vaccinated before
                  boarding?
                </h6>
                <p>
                  No policies provide cover if you do not comply with the ticket
                  conditions of the transport provider.
                </p>
                <h6>
                  Am I covered if I am not vaccinated and it is a condition of
                  the country that I must be vaccinated before I enter?
                </h6>
                <p>
                  No policies provide cover if you do not comply with the
                  government regulations of the country you are travelling to.
                </p>

                <h6>
                  Do cancellation fees apply if I am a permanent employee of the
                  healthcare industry and my employer cancels my leave due to
                  COVID-19?
                </h6>

                <ul>
                  <li>
                    Domestic – Yes, there are limited COVID-19 benefits
                    available, up to a total combined limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - If you have selected the Top Cover plan,
                    there are limited COVID-19 benefits available, up to a total
                    combined limit of $3,500 per listed adult. If you have
                    selected the Essentials Cover or Basic Cover plans, then no
                    COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>

                <h6>
                  Am I covered under Special Events if my pre-arranged special
                  event is delayed because of COVID-19, which is outside of my
                  control and I need to make alternative arrangements to get to
                  my destination on time?
                </h6>

                <ul>
                  <li>
                    Domestic – Yes, there are limited COVID-19 benefits
                    available, up to a total combined limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - If you have selected the Top Cover plan,
                    there are limited COVID-19 benefits available, up to a total
                    combined limit of $3,500 per listed adult. If you have
                    selected the Essentials Cover or Basic Cover plans, then no
                    COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>

                <h6>
                  Do I have cover under Travel Delay if my scheduled transport
                  is delayed for more than 6 hours due to COVID-19 related
                  delays and I need to pay additional expenses?
                </h6>

                <ul>
                  <li>
                    Domestic – Yes, cover is available with a daily limit of
                    $1,000 per adult and a maximum limit of $3,500 per listed
                    adult.
                  </li>
                  <li>
                    International - If you have selected the Top Cover plan,
                    cover is available with a daily limit of $1,000 per adult
                    and a maximum limit of $3,500 per listed adult. If you have
                    selected the Essentials Cover or Basic Cover plans, then no
                    COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>

                <h6>
                  Are there additional COVID-19 benefits available if my trip
                  destination is within Australia or New Zealand?
                </h6>

                <ul>
                  <li>
                    Domestic – Yes, as outlined below, to the total combined
                    limit of $3,500 per listed adult.
                  </li>
                  <li>
                    International - Yes, if you have selected the Top Cover
                    plan, there are additional COVID-19 benefits, as outlined
                    below, to the total combined limit of $3,500 per listed
                    adult. If you have selected the Essentials Cover or Basic
                    Cover plans, then no COVID-19 benefits are available.
                  </li>
                  <li>
                    Annual Multi-Trip – There are no COVID-19 benefits available
                    on this plan.
                  </li>
                </ul>

                <p><b>Cancellation Fees and Lost Deposits</b></p>
                <p>
                  If the person you’re due to stay with in Australia or New
                  Zealand is diagnosed with COVID-19, or directed by a local
                  public health authority into a period of quarantine and you
                  are unable to stay with them.
                </p>

                <p><b>Additional Expenses</b></p>
                <p>
                  If you can no longer stay with the person you planned to stay
                  within Australia or New Zealand because they are diagnosed
                  with COVID-19 and directed to enter a period of quarantine; or
                </p>
                <p>
                  If your pre-paid accommodation in Australia or New Zealand is
                  shutdown or closes as a result of COVID-19.
                </p>
                <p style="font-size: 10px">
                  *unlimited means that generally there is no cap on the maximum
                  dollar amount which may be paid out of this benefit, subject
                  to the specific terms and conditions, sub-limits and exclusion
                  apply to this benefit. This benefit covers reasonable overseas
                  medical and hospital costs as a result of an injury (including
                  arising from a terrorist act) or illness occurring which first
                  shows itself during your period of insurance. Benefits may be
                  paid up to 12 months from the time you received treatment for
                  the injury or illness, but only for reasonable expenses
                  incurred during that time. All medical treatments must be
                  provided by your treating doctor or our consulting medical
                  officer. You must notify us as soon as practicable of your
                  admittance to hospital.
                  <br />
                  ^This cover is per policy
                  <br />
                  &amp;Policy criteria and conditions apply
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                aria-expanded="false"
                aria-controls="collapseTwo"
                (click)="toggleCollapse($event, 'collapseTwo')"
              >
                World2Cover Travel Insurance Refund Scheme
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <h6>
                  <b
                    >Refunds for travel insurance policies impacted by
                    COVID-19</b
                  >
                </h6>
                <p>
                  <i
                    >These FAQs are for customers who have received an email in
                    relation to a travel insurance policy for travel between
                    31/01/2020 and 31/10/2021 dates only. If you have not
                    received an email and are wishing to enquire about a refund
                    or any other aspect of your policy, please see our general
                    FAQs or contact us on
                    <a href="tel:0292257599"> 02 9225 7599</a>.</i
                  >
                </p>
                <h6><b>Why did I receive this email?</b></h6>
                <p>
                  The COVID-19 pandemic caused widespread disruption to domestic
                  and international travel. This included border closures and Do
                  Not Travel directives from governments that prevented
                  travellers from embarking on trips they had planned. These
                  disruptions could have meant that you did not require coverage
                  under the travel insurance policy you purchased for part or
                  all of your travel.
                </p>
                <p>
                  Customers who purchased a travel insurance product distributed
                  by World2Cover but were unable to travel due to a travel ban
                  imposed by the Australian Government or border closures by
                  State or Territory Governments, may be eligible for a full or
                  partial refund of their premium. This is in line with
                  expectations issued by the Australian Securities and
                  Investments Commission (ASIC).
                </p>
                <p>
                  World2Cover and Tokio Marine encourage all customers who
                  purchased a travel policy that included coverage during a
                  travel ban period and may not have received the benefit of the
                  policy, to check their eligibility for a premium refund.
                </p>

                <h6>
                  <b>
                    Who is Tokio Marine and why are they involved in this
                    process?
                  </b>
                </h6>
                <p>
                  World2Cover Travel insurance is issued by Tokio Marine &amp;
                  Nichido Fire Insurance Co. Ltd (Tokio Marine) ABN 80 000 438
                  291, AFSL 246548
                </p>
                <p>
                  Tokio Marine underwrites World2Cover Travel Insurance and
                  provides the global emergency assistance and claims handling
                  services offered in our policies and as detailed in the
                  accompanying PDS. Tokio Marine will be managing the refunds
                  process in their system and processing any refunds to
                  World2Cover customers.
                </p>

                <h6><b>Am I eligible for a refund?</b></h6>
                <p>To find out if you are eligible for a refund:</p>
                <ol>
                  <li>
                    Click the link in the email you were sent to take you to the
                    fully secured refund calculation portal where you will be
                    prompted to complete an identification check.
                  </li>
                  <li>
                    Answer a series of questions to determine if you are
                    eligible for a refund; and
                  </li>
                  <li>
                    If you are eligible for any kind of refund, you will be
                    advised on the portal and requested to provide your bank
                    details so that payment can be processed (you will need your
                    BSB and Account Number) within 3-6 weeks.
                  </li>
                </ol>

                <h6 class="pt-4"><b>Frequently Asked Questions</b></h6>
                <dl class="ps-3 pt-3">
                  <dt>Q: How do I know if I am eligible?</dt>
                  <dd>
                    <b>A:</b> This offer is only available if you purchased a
                    World2Cover Travel Insurance product underwritten by Tokio
                    Marine (World2Cover Travel Insurance Policy) and were unable
                    to travel due to your travel dates falling into the
                    international ‘do not travel ban’ period, issued by the
                    Australian government, or for domestic border closures
                    imposed by state or territory governments.
                  </dd>

                  <dt>Q: What if I have travelled?</dt>
                  <dd>
                    <b>A:</b> If you have not been prevented from travelling due
                    to an Australian government issued travel ban, you will not
                    be entitled to a refund as you would have had the benefit of
                    the travel policy, subject to terms and conditions.
                  </dd>

                  <dt>
                    Q: I already made a claim and have been successful, can I
                    still apply for a refund?
                  </dt>
                  <dd>
                    <b>A:</b> If you have made a claim under your World2Cover
                    Travel Insurance Policy and have been successful, you will
                    not be entitled to a refund.
                  </dd>

                  <dt>Q: What if I need to make a claim under the Policy?</dt>
                  <dd>
                    <b>A:</b> If you intend to make a claim under your
                    World2Cover Travel Insurance Policy, you will not be
                    entitled to a refund unless it is determined that your
                    policy was not effective on the basis of the travel ban. In
                    other words, if you have a claim made on a valid policy in
                    operation, you should not apply for a premium refund as you
                    had the benefit of the policy.
                  </dd>

                  <dt>Q: How is my refund calculated?</dt>
                  <dd>
                    <b>A:</b> The amount of your refund will be calculated based
                    on the duration for which you did not have the benefit of
                    your World2Cover Travel Insurance Policy via the eligibility
                    question set. If you have any queries or concerns with your
                    assessment, please email us at
                    <a href="mailto:travelservice@world2cover.com.au"
                      >travelservice&#64;world2cover.com.au</a
                    >
                    we will forward your questions to Tokio Marine for response.
                  </dd>

                  <dt>Q: How long will the refund take?</dt>
                  <dd>
                    <b>A:</b> Payments will be processed within 3-6 weeks where
                    possible of you completing the refund request and will be
                    deposited into your nominated bank account. Your bank
                    statement will show the deposit from Tokio Marine.
                  </dd>

                  <dt>Q: I still have questions, who can I contact?</dt>
                  <dd>
                    <b>A:</b> If you still have questions, please contact our
                    team by emailing
                    <a href="mailto:travelservice@world2cover.com.au"
                      >travelservice&#64;world2cover.com.au</a
                    >
                    and we will reply as quickly as possible.
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                aria-expanded="false"
                aria-controls="collapseThree"
                (click)="toggleCollapse($event, 'collapseThree')"
              >
                What does travel insurance cover?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  Travel insurance can provide you with added peace of mind when
                  you are travelling. Some of the benefits provided by
                  World2Cover travel insurance are:
                </p>
                <ul>
                  <li>
                    Overseas medical and hospital expenses – $unlimited<sup
                      >**</sup
                    >
                    cover for medical and hospital costs due to an injury which
                    occurred during your trip
                  </li>
                  <li>
                    Cancellation fees and lost deposits<sup>#</sup> – the cost
                    of pre-paid travel and accommodation arrangements when your
                    travel is cancelled or cut short due to events beyond your
                    control and covered by your policy.
                  </li>
                  <li>
                    Luggage<sup>#</sup> and personal money – repair costs of
                    damaged items, as well as replacing or reimbursing your
                    belongings (including cash) if they are lost, stolen or
                    damaged (sub-limits and conditions apply).
                  </li>
                  <li>
                    Travel delay<sup>#</sup> – additional accommodation, meal,
                    and travel expenses if your scheduled transport is delayed
                    for at least 6 hours outside your control (policy conditions
                    limit and sub limits applied).
                  </li>
                </ul>
                <p>
                  Please refer to the table of benefits in the
                  <a
                    target="_blank"
                    href="/pds"
                    (click)="triggerSegmentEvent('faqInsuranceCoverPds1')"
                    >Product Disclosure Statement</a
                  >
                  for further details, including the terms, conditions, limits
                  and exclusions that apply.
                </p>
                <p>
                  All World2Cover overseas policy options provide worldwide
                  emergency medical assistance 24-hour/7 days a week, and
                  $unlimited<sup>**</sup> overseas medical expenses (conditions,
                  sub-limits and exclusions apply). We offer several policy
                  options with varying benefits and limits for international
                  travel and a policy for domestic travel including rental car
                  excess# cover.
                </p>
                <p>
                  When travelling with your dependent children, grandchildren,
                  step-children and foster children, we cover them at no
                  additional cost (unless they have a declared existing medical
                  condition), provided they are travelling with you 100% of the
                  time, are up to the age of 25, are financially dependent on
                  their parents, are not working full time, and do not require a
                  medical assessment.
                </p>
                <p>
                  We offer levels of cover that each come with their own set of
                  benefits. For example, if you choose to purchase the Top Cover
                  plan, domestic pets^<sup>#</sup>
                  are also covered under your policy<sup>~</sup>. With certain
                  restrictions<sup>∆</sup>, top cover travel insurance covers up
                  to $650 if your pet suffers an injury during your journey and
                  requires veterinary treatment (provided that at the time of
                  injury your pet was in the care of a relative, friend or
                  boarding kennel or cattery. Yes, that means your furry little
                  friend is protected as well.
                </p>
                <p style="font-size: 10px">
                  ∆ We will not cover you for Any boarding kennel or cattery
                  fees incurred outside of Australia, any pets located outside
                  of Australia and all General Exclusions apply, please refer to
                  our
                  <a
                    target="_blank"
                    href="/pds"
                    (click)="triggerSegmentEvent('faqInsuranceCoverPds2')"
                    >Product Disclosure Statement</a
                  >
                  <br />
                  ** $unlimited means that generally there is no cap on the
                  maximum dollar amount which may be paid out of this benefit,
                  subject to the specific terms and conditions, sub-limits and
                  exclusion apply to this benefit. This benefit covers
                  reasonable overseas medical and hospital costs as a result of
                  an injury (including arising from a terrorist act) or illness
                  occurring which first shows itself during your period of
                  insurance. Benefits may be paid up to 12 months from the time
                  you received treatment for the injury or illness, but only for
                  reasonable expenses incurred during that time. All medical
                  treatments must be provided by your treating doctor or our
                  consulting medical officer. You must notify us as soon as
                  possible of your admittance to hospital.
                  <br />
                  ^ This cover is per policy
                  <br />
                  # terms and conditions, exclusions, limits and sub-limits
                  apply
                  <br />
                  ~ Policy criteria and conditions apply
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFour"
                (click)="toggleCollapse($event, 'collapseFour')"
              >
                What does travel insurance not cover?
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  World2Cover travel insurance does not cover every event and
                  circumstance and there may also be limits to those we do
                  cover. We have listed exclusions that apply to all our travel
                  insurance options in either the specific section and/or the
                  <a
                    target="_blank"
                    href="/pds"
                    (click)="triggerSegmentEvent('faqInsuranceNotCoverPds1')"
                    >General Exclusion section of our Product Disclosure
                    Statement</a
                  >.
                </p>
                <p>
                  There is a Benefits table for each of the specific levels of
                  cover as well as for the policy options and add-ons.
                </p>
                <p>
                  For the full list of exclusions and the benefit limits and
                  sublimits, please read the
                  <a
                    target="_blank"
                    href="/pds"
                    (click)="triggerSegmentEvent('faqInsuranceNotCoverPds2')"
                    >Product Disclosure Statement</a
                  >.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                aria-expanded="false"
                aria-controls="collapseFive"
                (click)="toggleCollapse($event, 'collapseFive')"
              >
                Who is the insurer for World2Cover travel insurance?
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  World2Cover Travel Insurance is issued by Tokio Marine &amp;
                  Nichido Fire Insurance Co., Ltd (Tokio Marine &amp; Nichido).
                  ABN 80 000 438 291, AFSL 246548 is the insurer and issuer of
                  this policy and PDS. We also have an APRA authorisation to
                  conduct general insurance business in Australia.
                </p>
                <p>
                  Our managing agent and representative, Tokio Marine Management
                  Australia Pty. Ltd. ABN 69 001 488 455 (TMMA), is authorised
                  under a binder and managing agent agreement to act on our
                  behalf to issue our policies and handle and settle claims in
                  relation to those policies, subject to the terms of the
                  authority.
                </p>
                <p>
                  As well as Travel Insurance, Tokio Marine &amp; Nichido also
                  work with the general insurance market through insurance
                  brokers and provide insurance for commercial and corporate
                  businesses in Australia since 1963. Tokio Marine &amp; Nichido
                  was founded in 1879 in Japan, operates in multiple countries
                  and employees thousands of people worldwide.
                </p>
                <p>
                  Our Australian Head Office is based in Sydney as per our Call
                  Centre, Sales and Claims department. World2Cover is an awarded
                  travel insurance provider by Canstar in 2022 and from 2016 to
                  2019 for Outstanding Value International Travel Insurance and
                  by Mozo for Travel Insurance of the Year in 2018 and 2019.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                aria-expanded="false"
                aria-controls="collapseSix"
                (click)="toggleCollapse($event, 'collapseSix')"
              >
                How can I contact World2Cover to enquire about travel insurance?
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  For all customer service enquiries including existing medical
                  assessments you can easily go on our website
                  <a target="_blank" href="https://www.world2cover.com.au/">
                    world2cover.com.au</a
                  >
                  or call us on <a href="tel:0292257599"> 02 9225 7599</a> - Our
                  hours are 8am to 9pm Monday to Friday & 9am to 6pm Saturday
                  and Sunday.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="text-center mt-4 mb-4">
          <a
            routerLink="/faqs"
            class="faqall text-primary fw-normal mb-1 text-decoration-none"
            >View all FAQs <img src="assets/icons/w2c/chevron_right_color.png"
          /></a>
        </p>
      </div>
    </div>
  </div>

  <!-- <section class="newsletter">
    <div class="container">
      <div class="row rounded-20 shadow mb-5 mx-1 mx-sm-auto">
        <div class="col-md-6 col-12 newsletter-left"></div>
        <div
          class="col-lg-6 col-md-12 col-12 px-md-5 px-4 py-5 bg-white newsletter-right"
        >
          <h2 class="display-6 fw-bold text-left mb-3">
            Want travel tips & offers?<br />Join our newsletter!
          </h2>
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Email ID</label
            >
            <input
              type="email"
              class="form-control form-control-lg"
              id="exampleFormControlInput1"
              placeholder="Enter your email"
              required
            />
            <button
              type="submit"
              class="btn btn-outline-info fw-bold border-2 btn-lg mb-3 mt-4 ps-5 pe-5 pt-2 pb-2 btnlightfull"
            >
              Subscribe
            </button>
          </form>
          <p>
            See our
            <a
              href="/privacy-policy"
              class="text-primary fw-normal mb-1 text-decoration-underline"
              >privacy policy</a
            >
            for information on how we use your information
          </p>
        </div>
      </div>
    </div>
  </section> -->

  <app-footer></app-footer>
</section>
