<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'TravelInsurance'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/ltx-cruise-travel-insurance-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Cruise Travel Insurance</h1>
      <p>
        Cruise insurance can provide added peace of mind to travellers in
        covering a variety of unforeseen circumstances.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3 class="icon">
              International Cruise Travel Insurance for Ocean Cruising and River Cruising
            </h3>
            <p>
              Whether you’re cruising through the Caribbean or touring the 
              rivers of Thailand, the last thing on your mind is the possibility 
              of something going wrong. An unexpected Illness or injury or even 
              a delay or cancellation could be costly not to mention confusing 
              if you are in another country.  An International cruise insurance 
              policy can help to relieve some of that burden for you and your 
              family if an unforeseen or emergency situation were to arise with 
              coverage for a variety of situations as well as a 24/7 emergency 
              helpline.  A World2Cover cruise insurance policy can help provide 
              added peace of mind for you, your friends or family.
            </p>
            <p>
              There are three World2Cover international policies that provide 
              cover for cruises. Each one has different benefits as well as 
              limits and sub-limits within these benefits.  The type of coverage 
              and the price that is best suited for you will be based on your 
              required needs. Please refer to the <a target="_blank" href="/pds">PDS</a> 
              and our <a target="_blank" href="{{ tmdLink }}">TMD's</a> to understand 
              whether this product suits your requirements and which level of cover 
              is most appropriate as well as any exclusions that may apply. If you 
              have questions about any of the features of the product do not hesitate 
              to call our team! Our customer service team is available on 
              <a href="tel:0292257599">(02) 9225 7599</a>, Monday to Friday 8am to 9pm 
              (AEST) and Saturday/Sunday 9am to 6pm (AEST).
            </p>
            <p>
              Cover for international river cruising is included automatically in 
              our single and annual multi trip policies and you do not need to select the
              Cruise option to be covered for river cruising.Medical evacuation and medical 
              costs will be covered with all river cruising.  All other international 
              cruising will require you to select the Cruise option on your preferred policy 
              level to have the cover while you are on your cruise. 
            </p>
            <p>
              Our definition of Cruise means an ocean voyage on a commercially operated 
              vessel for more than one night. If you are travelling for only one night 
              you will automatically be covered under the policy without selecting the 
              Cruise Option.
            </p>
            <p>
              All other international ocean cruising will require you to select the 
              Cruise option to be covered while you are on your cruise. and adding 
              all countries being visited to the destination list when quoting for 
              a policy.
            </p>
            <p>
              Medical evacuation and medical costs will be covered with all ocean cruising. 
            </p>
            <p>
              There are currently no restrictions on the size of the vessel or on 
              the medical facilities required onboard. 
            </p>
            <p>
              However, please note that there is an exclusion on any Open Water Sailing 
              which means sailing more than 12 nautical miles from any landmass.
            </p>
            <p>
              This applies to a vessel using the wind as its means of propulsion and for 
              these type of vessels no cover under cruise cover can be provided unless the 
              vessel stays within 12 nautical miles of any landmass. For example, a customer 
              wishing to sail in a commercially operated sailing vessel along the coast of 
              Croatia and always staying within 12 nautical miles of the landmass of Croatia 
              can be covered via the Cruise Option. Conversely, a customer wishing to sail the 
              Mediterranean in open water more than 12 nautical miles from any landmass at any 
              time cannot be covered via the Cruise option and is specifically excluded under 
              our activities.
            </p>
            <p>
              Once you’ve made your decision, our online purchase journey is designed to get 
              you on your way smoothly so that you can spend more time looking forward to your 
              cruise. Select your chosen cover type, complete the details of all travellers to 
              be listed and any medical declarations. And all that’s left to do is to enjoy 
              your cruise! 
            </p>
            <p class="desclaimer mt-2" style="line-height: 10px">
              <small>Policy criteria and conditions apply</small>
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Which policy might be right for me?</h3>
            <p>
              All World2Cover overseas policies provide 24-hour emergency 
              medical assistance and unlimited overseas medical coverage **. 
              Our goal is to provide quality cost effective coverage. The 
              conditions, benefits and policy limits vary between our three 
              policies. Take the time to read the 
              <a target="_blank" href="/pds">PDS</a> to ensure you have the 
              cover that is right for you and your trip and you are aware of 
              any exclusions or limitations.
            </p>
            <p class="desclaimer mt-2" style="line-height: 10px">
              <small>
                ** $unlimited means that generally there is no cap on the maximum 
                dollar amount which may be paid out of this benefit, subject to 
                the specific terms and conditions, sub-limits and exclusion that 
                apply to this benefit. This benefit covers reasonable overseas 
                medical and hospital costs as a result of an injury (including 
                arising from a terrorist act) or illness occurring which first 
                shows itself during your period of insurance. Benefits may be 
                paid up to 12 months from the time you received treatment for 
                the injury or illness, but only for reasonable expenses incurred 
                during that time. All medical treatments must be provided by your 
                treating doctor or our consulting medical officer. You must notify 
                us as soon as possible of your admittance to hospital.
              </small>
            </p>
            <p class="desclaimer mt-2" style="line-height: 10px">
              <small>Policy criteria and conditions apply</small>
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Why should I consider cruise travel insurance?</h3>
            <p>
              Travel insurance for cruises offers additional peace of mind while 
              you travel whether onboard ship or spending a day onshore in another 
              country. World2Cover has your back for those unexpected events so 
              you can relax and enjoy your holiday.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">
              Travel Insurance for domestic river cruising
            </h3>
            <p>
              Cover for domestic cruising is included automatically in the policy 
              and you do not need to select the Cruise option to be covered. Please 
              select Australia as the destination of travel. 
            </p>
            <p>
              However, it is important to understand that medical evacuation and medical 
              costs are not covered for any domestic travel as medical costs are covered 
              by Medicare while any medical evacuations costs will need to be covered by 
              you.
            </p>
            <p>
              There are no restrictions on vessel size or whether medical facilities and 
              medically trained staff are onboard.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Travel Insurance for cruising around Australia</h3>
            <div>
              <h5 class="icon">Cruising from Australia to International ports</h5>
              <p>
                Many cruises depart from Australian ports to visit international ports 
                of destination such as countries in the South Pacific or South East Asia.
              </p>
              <p>
                For these types of cruises, please select Australian Cruise and add all 
                countries being visited in the destination list when quoting for a policy - 
                this will ensure you have cover for any medical evacuation  and medical costs 
                while overseas or onboard but please note, any medical treatment received in 
                Australia is not covered given this is excluded and provided by Medicare.
              </p>
            </div>
  
            <div>
              <h5 class="icon">Cruising to Australian ports or offshore islands with medical 
                facilities and trained medical staff onboard and commercially operated</h5>
              <p>
                Many cruises depart from Australian ports to visit local Australian ports only 
                such as major city based ports, regional ports or inland river systems such as 
                the Murray River.
              </p>
              <p>
                If your cruise has medical facilities and trained medical staff onboard and 
                the cruise is commercially operated, please select Australian Cruise while 
                quoting for a policy - this will ensure you have cover for any medical evacuation 
                and medical costs while onboard but please note, any medical treatment received 
                in Australia is not covered given this is excluded provided by Medicare.
              </p>
            </div>
  
            <div>
              <h5 class="icon">Cruising to Australian ports or offshore islands with NO medical 
                facilities and trained medical staff onboard or chartered for self-operation</h5>
              <p>
                Many cruises depart from Australian ports to visit local Australian ports only such 
                as major city based ports, regional ports or inland river systems such as the Murray River.
              </p>
              <p>
                If these cruises have no medical facilities and staff or are chartered for self-operation, 
                please select  Australia – Domestic cover, while quoting for a policy.
              </p>
              <p>
                Please note that no cover is provided for medical treatment received onboard or for medical 
                evacuation. Any medical treatment received in Australia is not covered given this is excluded 
                and provided by Medicare.
              </p>
              <h5 class="icon">Open Water Sailing</h5>
              <p>
                Please note there is an exclusion on any Open Water Sailing which means sailing more than 12 nautical miles from any landmass. This applies to a vessel using the wind as its means of propulsion and for these type of vessels no cover can be provided unless the vessel stays within 12 nautical miles of any landmass.
              </p>
              <p>
                If you have questions on the features of our products and cruising in Australia do not hesitate 
                to call our team! Our customer service team is available on <a href="tel:0292257599">(02) 9225 7599</a>,
                Monday to Friday 8am to 9pm (AEST) and Saturday/Sunday 9am to 6pm (AEST).
              </p>
            </div>
          </div>

          <!--  -->
          <div class="py-0">
            <p class="desclaimer">
              <small
                >Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438
                291 AFSL 246 548) is the issuer of World2Cover Travel Insurance.
                The 'World2Cover Travel Insurance - Combined Financial Services
                Guide and Product Disclosure Statement' (PDS) which includes the
                policy wording, is available
                <a target="_blank" href="/pds">here</a>. Any financial advice
                set out above is general in nature only, and does not take into
                account your objectives, financial situation or needs. Because
                of this, you should, before acting on the advice, consider the
                appropriateness of the advice, having regards to your
                objectives, financial situation and needs. You should consider
                the PDS in deciding whether to acquire, or to continue to hold
                the policies.</small
              >
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
