<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'Emergency'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/emergency-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Emergency</h1>
      <p>
        With World2Cover, you get 24/7 access to worldwide medical and emergency
        assistance.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3 class="mb-3">In a medical emergency (24/7)</h3>
            <!--  -->
            <div class="card mb-3 rounded-10">
              <div class="card-body">
                <h5 class="fw-semibold">From Japan:</h5>
                <p>
                  <span class="fw-semibold">Call</span>
                  <a href="tel:08008009117" class="font-20 mx-2 fw-semibold"
                    >0800-800-9117</a
                  >
                  (toll free) or if mobile access is restricted call
                  <a href="tel:0362285881" class="font-20 mx-2 fw-semibold"
                    >03 6228-5881</a
                  >
                  (local number in Japan)
                </p>
              </div>
            </div>

            <!-- All other countries  -->
            <div class="card mb-3 rounded-10">
              <div class="card-body">
                <h5 class="fw-semibold">All other countries:</h5>
                <p>
                  <span class="fw-semibold">Call</span>
                  <a href="tel:+61280551683" class="font-20 mx-2 fw-semibold"
                    >+61 2 8055 1683</a
                  >(reverse charges accepted from the overseas operator) while
                  travelling in all other countries.
                </p>
              </div>
            </div>

            <!--  -->
            <div class="card mb-3 rounded-10">
              <div class="card-body">
                <h5 class="fw-semibold">Whilst Overseas:</h5>
                <p>
                  <span class="fw-semibold">For all other enquiries call</span>
                  <a href="tel:+61292257599" class="font-20 mx-2 fw-semibold"
                    >+61 2 9225 7599</a
                  >
                  (Monday to Friday, 8am – 9pm, Saturday &amp; Sunday 9am - 6pm,
                  Sydney time).
                </p>
              </div>
            </div>

            <!-- Within Australia  -->
            <div class="card mb-3 rounded-10">
              <div class="card-body">
                <h5 class="fw-semibold">Within Australia:</h5>
                <p>
                  <span class="fw-semibold">For claims:</span>
                  <a href="tel:0292257599" class="font-20 mx-2 fw-semibold"
                    >02 9225 7599</a
                  >
                  (toll free) or email us at
                  <a
                    href="mailto:travelclaims@world2cover.com.au"
                    class="font-20 mx-2 fw-semibold"
                    >travelclaims&#64;world2cover.com.au</a
                  >
                </p>
                <p>
                  <span class="fw-semibold">Mail:</span> World2Cover Claims, GPO
                  Box 4616, Sydney 2001
                </p>
                <p>
                  <span class="fw-semibold">For service:</span>
                  <a href="tel:0292257599" class="font-20 mx-2 fw-semibold"
                    >02 9225 7599</a
                  >
                </p>
              </div>
            </div>

            <!--  -->
            <div class="card mb-3 rounded-10">
              <div class="card-body">
                <h5 class="fw-semibold">
                  Tokio Marine Management (Australasia) Pty Ltd:
                </h5>
                <address>
                  Level 17, 60 Margaret Street <br />
                  Sydney NSW 2000 <br />
                  Australia
                </address>
              </div>
            </div>
          </div>
        </div>
        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
