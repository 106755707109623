import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { PDSService } from '../../../services/pds.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { LocalCacheService } from '../../../services/localCache.service';
@Component({
  selector: 'app-travel-insurance',
  standalone: true,
  imports: [
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
    CommonModule,
  ],
  templateUrl: './travel-insurance.component.html',
  styleUrl: './travel-insurance.component.scss',
})
export class TravelInsuranceComponent implements OnInit {
  tmdLink: string = '';
  constructor(
    private pdsService: PDSService,
    private sanitizer: DomSanitizer,
    private titleSerive: Title,
    private metaService: Meta,
    private localCacheService: LocalCacheService,
  ) {
    this.titleSerive.setTitle(
      'What is travel insurance? | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content:
        'Travel insurance is a form of insurance that can cover travellers when unforeseen events like flight cancellations, lost luggage, inclement weather and medical emergencies result in financial loss. Learn more about the protections travel insurance offers.',
    });
  }
  sanitizedHtml: SafeHtml | null = null;
  ngOnInit(): void {
    let href: string = '';
    const currentLatestTMD =
      this.localCacheService.getSessionStorage('lastestTMD');
    if (!currentLatestTMD) {
      this.pdsService.getLatestTMD().subscribe((data) => {
        if (data && data.tmd && data.tmd.length > 0) {
          data.tmd.forEach((obj: any) => {
            if (obj.isActive) {
              this.tmdLink = obj.url;
            }
          });
        }
      });
    } else {
      this.tmdLink = currentLatestTMD?.url;
    }
    switch (environment.autoClub.toUpperCase()) {
      case 'W2C':
        href = `<a target="_blank" href='/pds'>here</a>`;
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(href);
        break;
      case 'RACV':
      case 'RACQ':
      case 'RACT':
      case 'RAA':
      case 'RAC':
      case 'AANT':
        this.loadPdsLinkForOtherClubs();
        break;
    }
  }

  loadPdsLinkForOtherClubs() {
    this.pdsService.getLatestPDS().subscribe((data) => {
      const href = `<a rel="noopener noreferrer" target="_blank" href="${
        data && data.pds && data.pds.length > 0 ? data.pds[0].previewUrl : '#'
      }">here</a>`;
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(href);
    });
  }
}
