<div class="container-lg my-5">
  <div class="row justify-content-center">
    <div class="col-md-10 p-0">
      <h2 class="display-5 fw-bold section-title mb-3 mt-5 text-center">
        Why World2Cover?
      </h2>
      <p class="text-center h4 fw-normal lh-base whyw2c p-sml">
        World2Cover specialises in superior customer service, travel insurance
        packages, and instant quotes. You have many places to go, people to see,
        and adventures to experience. Take advantage of our packages because, as
        we know, you have the world to cover.
      </p>
    </div>
  </div>
</div>
<div class="container-xxl container-xl container my-5">
  <div
    class="row justify-content-center border rounded-5 border-info mobnoboder"
  >
    <div
      class="col-md-6 why whyone p-5 border-end border-bottom border-info mobwhyborder"
    >
      <div class="d-flex align-items-center flex-column text-center">
        <img class="whyicon" src="assets/images/w2c/medicalicon.png" />
        <h2 class="display-6 fw-bold text-dark mb-3">
          <a
            href="/travel-medical-insurance"
            class="text-dark text-decoration-none"
            >** $unlimited Medical</a
          >
        </h2>
        <p class="lead mb-4 mt-2 ps-3 pe-3">
          You have ** $unlimited medical benefit when travelling overseas.
        </p>
      </div>
    </div>
    <div class="col-md-6 why whytwo p-5 border-bottom border-info mobwhyborder">
      <div class="d-flex align-items-center flex-column text-center">
        <img class="whyicon" src="assets/images/w2c/24hourcontact.png" />
        <h2 class="display-6 fw-bold text-dark mb-3">
          <a href="/emergency" class="text-dark text-decoration-none"
            >24/7 Medical Coverage</a
          >
        </h2>
        <p class="lead mb-4 mt-2 ps-3 pe-3">
          Our Worldwide medical assistance team is available 24 hours a day - 7
          days a week.
        </p>
      </div>
    </div>
    <div class="col-md-6 why whythree p-5 border-end border-info mobwhyborder">
      <div class="d-flex align-items-center flex-column text-center">
        <img class="whyicon" src="assets/images/w2c/dependentcoverage.png" />
        <h2 class="display-6 fw-bold text-dark mb-3">
          <a
            href="/family-travel-insurance"
            class="text-dark text-decoration-none"
            >Dependent Coverage</a
          >
        </h2>
        <p class="lead mb-4 mt-2 ps-3 pe-3">
          Dependent children aged 25 or under can be added to your policy when
          they are travelling with you.
        </p>
      </div>
    </div>
    <div class="col-md-6 why whyfour p-5 mobwhyborder">
      <div class="d-flex align-items-center flex-column text-center">
        <img class="whyicon" src="assets/images/w2c/sportscoverage.png" />
        <h2 class="display-6 fw-bold text-dark mb-3">
          <a href="/ski-cover" class="text-dark text-decoration-none"
            >Ski & Winter Sports Cover</a
          >
        </h2>
        <p class="lead mb-4 mt-2 ps-3 pe-3">
          We offer great winter adventure cover for skiing and snowboarding,
          check us out.
        </p>
      </div>
    </div>
  </div> 
  <div class="mt-3">
    <p style="font-size: 10px">
      ** $unlimited means that generally there is no cap on the maximum dollar
      amount which may be paid out of this benefit, subject to the specific terms
      and conditions, sub-limits and exclusion apply to this benefit. This benefit
      covers reasonable overseas medical and hospital costs as a result of an
      injury (including arising from a terrorist act) or illness occurring which
      first shows itself during your period of insurance. Benefits may be paid up
      to 12 months from the time you received treatment for the injury or illness,
      but only for reasonable expenses incurred during that time. All medical
      treatments must be provided by your treating doctor or our consulting
      medical officer. You must notify us as soon as possible of your admittance
      to hospital.
    </p>
  </div> 
</div>

