import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-top-cover',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './top-cover.component.html',
  styleUrl: './top-cover.component.scss',
})
export class TopCoverComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle(
      'Comprehensive Travel Insurance - Top Cover | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content: `World2Cover comprehensive travel insurance is built for the unexpected. International coverage for medical expenses, lost luggage, valuables, cancellation fees, and 24/7 overseas emergency assistance.`,
    });
  }
}
