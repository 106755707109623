import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';

@Component({
  selector: 'app-ski-cover',
  standalone: true,
  imports: [FooterComponent, StaticSearchComponent, CommonHeaderComponent],
  templateUrl: './ski-cover.component.html',
  styleUrl: './ski-cover.component.scss',
})
export class SkiCoverComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle(
      'Ski, Snowboard & Winter Sports Cover | World2Cover Australia',
    );
    this.metaService.updateTag({
      name: 'description',
      content: `World2Cover’s optional ski and winter sports cover allows you to enter the world of winter sports with confidence.`,
    });
  }
}
