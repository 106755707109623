import { Component } from '@angular/core';

@Component({
  selector: 'app-get-quote-panel',
  standalone: true,
  imports: [],
  templateUrl: './get-quote-panel.component.html',
  styleUrl: './get-quote-panel.component.scss'
})
export class GetQuotePanelComponent {

}
