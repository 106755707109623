<section class="coverage">
  <div class="container">
    <div class="row">
      <h2 class="display-5 fw-bold section-title mb-5 text-center">
        Coverage for Every Vacation Type
      </h2>
    </div>
    <div class="row g-3">
      <div class="col-6 col-lg-3">
        <a
          href="/travel-insurance"
          class="text-white text-decoration-none"
        >
          <div
            class="first p-2 flex-column align-items-start justify-content-end"
          >
            <h2 class="h4 fw-bold text-light text-left cvty">
              International Cover
            </h2>
            <p class="text-light text-left cvty">Single or Annual Multi Trip</p>
          </div>
        </a>
      </div>

      <div class="col-6 col-lg-3">
        <a
          href="/domestic-cover"
          class="text-white text-decoration-none"
        >
          <div
            class="second p-2 flex-column align-items-start justify-content-end"
          >
            <h2 class="h4 fw-bold text-light text-left cvty">Domestic Cover</h2>
            <p class="text-light text-left cvty">Single Trip</p>
          </div></a
        >
      </div>
      <div class="col-6 col-lg-3">
        <a
          href="/ski-cover"
          class="text-white text-decoration-none"
        >
          <div
            class="third p-2 flex-column align-items-start justify-content-end"
          >
            <h2 class="h4 fw-bold text-light text-left cvty">
              Ski & Winter Sports Cover
            </h2>
            <p class="text-light text-left cvty">Option</p>
          </div></a
        >
      </div>
      <div class="col-6 col-lg-3">
        <a
          href="/existing-medical"
          class="text-white text-decoration-none"
        >
          <div
            class="fourth p-2 flex-column align-items-start justify-content-end"
          >
            <h2 class="h4 fw-bold text-light text-left cvty">
              Existing Medical
            </h2>
            <p class="text-light text-left cvty">
              Free Online Medical Assessment
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
