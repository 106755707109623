<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>
  <!-- End Header Part -->
  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/ltx-international-travel-insurance-travel-insurance-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Insurance</h1>
      <p>
        From an accident on the ski slopes or losing your luggage, to making
        sure you are looked after in an emergency, our cover is there for you.
      </p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3 class="icon">What is travel insurance?</h3>
            <p>
              Travel insurance can provide policyholders with reimbursement for
              medical expenses, lost or damaged luggage, trip cancellation fees
              and more while travelling<sup>#</sup>. To claim, there must be
              evidence of an unexpected event occurring that is covered by the
              travel insurance policy, which resulted in a financial loss for
              the covered travellers.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">How we develop travel insurance?</h3>
            <p class="mb-0">
              Tokio Marine & Nichido Fire Insurance Co. Ltd, as the issuer for
              World2Cover Travel Insurance, aims to develop and distribute
              travel insurance that is suitable for its customers in a more
              targeted way.
            </p>
            <p>
              The
              <a href="{{ tmdLink }}" target="_blank"
                >Target Market Determination (TMDs)</a
              >
              outline the intended class of customers that comprise the target
              market for the travel insurance products along with matters
              relevant to the distribution review of these products.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">What does travel insurance cover?</h3>
            <p>
              Our World2Cover policies cover up to 2 adults and their declared
              dependents (up to age 25, financially dependent and not working
              full time and travelling with you the entire trip). We offer
              levels of cover that each come with their own set of features and
              benefits. For example, if you choose to purchase
              <a target="_blank" href="/top-cover">top cover</a>, domestic
              pets<sup>^#</sup> can also be covered for up to $650 if they
              suffer an injury during your trip and require veterinary treatment
              (provided that at the time of injury your pet was in the care of a
              relative, friend or boarding kennel or cattery). From the 17
              November 2021, World2Cover International Single Trip Top cover and
              Single Trip Domestic cover includes Limited COVID-19
              Benefits<sup>~</sup>.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Why consider getting travel insurance?</h3>
            <p>
              Travel insurance is for those times when life gives you lemons. It
              can help to provide you with peace of mind by assisting to help
              take the worry out of whether or not you will be reimbursed for
              your valuables. As we said before, travel insurance can assist
              with those unexpected times that nobody can anticipate.
            </p>
          </div>
          <div class="page-content-info">
            <h3 class="icon">Why consider World2Cover?</h3>
            <p>
              World2Cover strive to provide superior customer service, travel
              insurance packages, and instant quotes. You have heaps of places
              to go, people to see, and adventures to experience. Take advantage
              of our packages because, as we know, you have the world to cover.
              Give us a call at <a href="tel:0292257599">02 9225 7599</a> or get
              an instant online quote today!
            </p>
          </div>

          <!--  -->
          <div class="py-3">
            <p class="desclaimer">
              <small
                >Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438
                291 AFSL 246 548) is the issuer of World2Cover Travel Insurance.
                The 'World2Cover Travel Insurance - Combined Financial Services
                Guide and Product Disclosure Statement' (PDS) which includes the
                policy wording, is available
                <span *ngIf="sanitizedHtml" [innerHTML]="sanitizedHtml"></span>.
                Any financial advice set out above is general in nature only,
                and does not take into account your objectives, financial
                situation or needs. Because of this, you should, before acting
                on the advice, consider the appropriateness of the advice,
                having regards to your objectives, financial situation and
                needs. You should consider the PDS in deciding whether to
                acquire, or to continue to hold the policies.</small
              >
            </p>
            <p class="desclaimer" style="line-height: 10px">
              <small
                ># terms and conditions, exclusions, limits and sub-limits
                apply<br />
                ^ This cover is per policy<br />
                ~ Policy criteria and conditions apply
              </small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>

  <app-footer></app-footer>
</div>
