import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('About Us | World2Cover Australia');
    this.metaService.updateTag({
      name: 'description',
      content: `World2Cover is an award-winning online travel insurance provider in Australia dedicated to domestic and international travel for singles, couples, families, and friends. Learn how a World2Cover travel insurance policy is built to suit who you are, the way you travel, and your budget.`,
    });
  }
}
