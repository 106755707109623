import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalCacheService } from '../../services/localCache.service';
import { MomentPipe } from '../../utils/moment.pipe';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PDSService } from '../../services/pds.service';
import { EmailQuoteComponent } from '../email-quote/email-quote.component';
import {
  TravelerFormValidator,
  TravelerValidatorService,
} from '../../services/traveler-validator.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-price-breakdown',
  standalone: true,
  imports: [CommonModule, MomentPipe, EmailQuoteComponent],
  templateUrl: './footer-price-breakdown.component.html',
  styleUrl: './footer-price-breakdown.component.scss',
})
export class FooterPriceBreakdownComponent implements OnInit, OnDestroy {
  @Input() selectedCover: any;
  @Input() quoteEnquiry: any;
  @Input() quoteDetail: any;
  @Input() step: any;
  @Input() submitBtnLabel: string = 'Proceed';
  @Input() enableSubmitBtn: boolean = true;
  @Output() onSubmitBtnClick: EventEmitter<any> = new EventEmitter();
  travellersAge: string = '';
  selectedCoverDetail$!: Observable<any>;
  autoClubName = environment.autoClub;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private localCacheService: LocalCacheService,
    private pdsService: PDSService,
    private travelerValidatorService: TravelerValidatorService,
    private router: Router,
  ) {}

  @ViewChild('saveAndSendEmail')
  saveAndSendEmail!: EmailQuoteComponent;

  openSaveAndSendModal() {
    const pathSegment = this.router.url;
    if (pathSegment === '/quotes-steps-two') {
      this.travelerValidatorService.triggerFormValidation();
    } else {
      this.saveAndSendEmail?.openModal();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.travelerValidatorService.validateFormSubject$.subscribe(
        (validatorFlag: TravelerFormValidator) => {
          if (!validatorFlag.IsValid) {
            this.saveAndSendEmail?.openModal(true);
          } else {
            this.saveAndSendEmail?.openModal(false);
          }
        },
      ),
    );
  }

  get countryNames(): string {
    return this.quoteEnquiry?.travelDestination
      .map((country: any) => country.countryName)
      .join(', ');
  }

  get travelDays() {
    const depDate = moment(this.quoteEnquiry?.return);
    const rtnDate = moment(this.quoteEnquiry?.depart);
    const days = depDate.diff(rtnDate, 'days');
    return days;
  }

  get displayTravellersAge(): string {
    this.travellersAge = this.quoteEnquiry?.age1;
    if (this.quoteEnquiry?.age2) {
      this.travellersAge = this.travellersAge + ',' + this.quoteEnquiry.age2;
    }
    return this.travellersAge;
  }

  get getDependentsNum() {
    return Number(this.quoteEnquiry?.dependentsCount);
  }

  downloadPDS() {
    this.pdsService.downloadLatestPDSPDF();
  }

  onSubmitClick() {
    this.onSubmitBtnClick.emit();
  }
}
