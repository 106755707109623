import { ExtraOptions, Routes } from '@angular/router';

import { SearchCountryComponent } from '../search-country/search-country.component';
import { StepOneComponent } from '../step-one-product-select/step-one.component';
import { StepThreeComponent } from '../step-three/step-three.component';
import { StepFourComponent } from '../step-four/step-four.component';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { StepTwoTravelerDetailsComponent } from '../step-two-traveler-details/step-two-traveler-details.component';
import { TravelInsuranceComponent } from '../staticpages/travel-insurance/travel-insurance.component';
import { FamilyTravelInsuranceComponent } from '../staticpages/family-travel-insurance/family-travel-insurance.component';
import { TravelMedicalInsuranceComponent } from '../staticpages/travel-medical-insurance/travel-medical-insurance.component';
import { CruiseTravelInsuranceComponent } from '../staticpages/cruise-travel-insurance/cruise-travel-insurance.component';
import { WhatsCoveredComponent } from '../staticpages/whats-covered/whats-covered.component';
import { TopCoverComponent } from '../staticpages/top-cover/top-cover.component';
import { EssentialsCoverComponent } from '../staticpages/essentials-cover/essentials-cover.component';
import { BasicsCoverComponent } from '../staticpages/basics-cover/basics-cover.component';
import { DomesticCoverComponent } from '../staticpages/domestic-cover/domestic-cover.component';
import { AnnualMultiTripCoverComponent } from '../staticpages/annual-multi-trip-cover/annual-multi-trip-cover.component';
import { SkiCoverComponent } from '../staticpages/ski-cover/ski-cover.component';
import { ExistingMedicalComponent } from '../staticpages/existing-medical/existing-medical.component';
import { FaqsComponent } from '../staticpages/faqs/faqs.component';
import { EmergencyComponent } from '../staticpages/emergency/emergency.component';
import { HowToClaimComponent } from '../staticpages/how-to-claim/how-to-claim.component';
import { TravelAlertsComponent } from '../staticpages/travel-alerts/travel-alerts.component';
import { CovidFaqsComponent } from '../staticpages/covid-faqs/covid-faqs.component';
import { IsraelComponent } from '../staticpages/israel/israel.component';
import { IsraelFaqsComponent } from '../staticpages/israel-faqs/israel-faqs.component';
import { AboutUsComponent } from '../staticpages/about-us/about-us.component';
import { RefundsComponent } from '../staticpages/refunds/refunds.component';
import { TermsAndConditionsComponent } from '../staticpages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../staticpages/privacy-policy/privacy-policy.component';
import { PdsComponent } from '../staticpages/pds/pds.component';
import { PaymentConfirmationComponent } from '../payment-confirmation/payment-confirmation.component';
import { InformationTemplateComponent } from '../staticpages/information-template/information-template.component';
import { AwardsComponent } from '../../components/homepage/awards/awards.component';
import { ContactUsComponent } from '../staticpages/contact-us/contact-us.component';
import { ReviewPolicyComponent } from '../staticpages/review-policy/review-policy.component';
import { ExpiredQuoteComponent } from '../expired-quote/expired-quote.component';

export const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top', // Add this line to automatically scroll to top on navigation
  anchorScrolling: 'enabled', // Optional, enables scrolling to anchor elements
  scrollOffset: [0, 64], // Optional, [x, y] - Adjust if you have a fixed header and the anchor is covered by the header
};

export const routes: Routes = [
  { path: '', component: SearchCountryComponent },
  { path: 'product', redirectTo: 'quotes-steps-one' },
  { path: 'quotes-steps-one', component: StepOneComponent },
  { path: 'quotes-steps-two', component: StepTwoTravelerDetailsComponent },
  { path: 'quotes-steps-three', component: StepThreeComponent },
  { path: 'quotes-steps-four', component: StepFourComponent },
  { path: 'payment-confirmation', component: PaymentConfirmationComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'information', component: InformationTemplateComponent },
  { path: 'quote-expired', component: ExpiredQuoteComponent },

  { path: 'travel-insurance', component: TravelInsuranceComponent },
  {
    path: 'family-travel-insurance',
    component: FamilyTravelInsuranceComponent,
  },
  {
    path: 'travel-medical-insurance',
    component: TravelMedicalInsuranceComponent,
  },
  {
    path: 'cruise-travel-insurance',
    component: CruiseTravelInsuranceComponent,
  },
  { path: 'whats-covered', component: WhatsCoveredComponent },
  { path: 'top-cover', component: TopCoverComponent },
  { path: 'essentials-cover', component: EssentialsCoverComponent },
  { path: 'basics-cover', component: BasicsCoverComponent },
  {
    path: 'domestic-cover',
    component: DomesticCoverComponent,
  },
  { path: 'annual-multi-trip-cover', component: AnnualMultiTripCoverComponent },
  { path: 'ski-cover', component: SkiCoverComponent },
  { path: 'existing-medical', component: ExistingMedicalComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'emergency', component: EmergencyComponent },
  { path: 'how-to-claim', component: HowToClaimComponent },
  { path: 'travel-alerts', component: TravelAlertsComponent },
  { path: 'covid-faqs', component: CovidFaqsComponent },
  { path: 'israel', component: IsraelComponent },
  { path: 'israelfaqs', component: IsraelFaqsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'refunds', component: RefundsComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'review-policy', component: ReviewPolicyComponent },
  { path: 'pds', component: PdsComponent },
  { path: '**', redirectTo: 'error' },
];
