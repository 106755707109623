import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StepBarComponent } from '../../components/step-bar/step-bar.component';
import { BraintreeWidgetComponent } from '../../components/braintree-widget/braintree-widget.component';
import { LocalCacheService } from '../../services/localCache.service';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { MomentPipe } from '../../utils/moment.pipe';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-step-four',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    CommonModule,
    FooterComponent,
    HeaderComponent,
    ReactiveFormsModule,
    RadioButtonModule,
    StepBarComponent,
    BraintreeWidgetComponent,
    MomentPipe,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './step-four.component.html',
  styleUrl: './step-four.component.scss',
})
export class StepFourComponent implements OnInit, OnDestroy {
  policyDetails: any = {};
  selectedCover: any;
  quoteEnquiry: any;
  quoteDetail: any;
  activatedClub: string = '';

  constructor(
    private localCacheService: LocalCacheService,
    private router: Router,
    private globalService: GlobalService,
    private viewportScroller: ViewportScroller,
  ) {}

  ngOnInit(): void {
    if (!this.globalService.checkAndValidatePage(4, false)) {
      this.router.navigate(['/error']);
      return;
    }
    this.activatedClub = environment.autoClub;
    this.loadData();
    if (!this.quoteDetail) {
      this.router.navigate(['/']);
      return;
    }
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {}

  loadData() {
    this.quoteDetail =
      this.localCacheService.getSessionStorage('quoteDetail') || {};
    this.quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
    this.policyDetails = {
      quoteNumber: this.quoteDetail.quoteNumber,
      destination: this.countryNames,
      isSingleTrip: this.quoteEnquiry.isSingleTrip,
      promoCode: this.quoteDetail.promoCode,
      departDate: moment(this.quoteEnquiry.depart).format("DD MMMM' YYYY"),
      returnDate: moment(this.quoteEnquiry.return).format("DD MMMM' YYYY"),
      duration: this.calculateDaysBetweenDates(),
      ageOfTravellers: this.displayTravellersAge(),
      nameOfTravelers: this.displayTravelersName(),
      noOfDependents: this.quoteDetail.dependentsCount,
      productType: this.quoteDetail.productType,
      productAlias: this.quoteDetail.productAlias,
      totalCoverPolicy: this.quoteDetail.premiums.product,
      isCruise: this.quoteDetail.isCruise === true ? 'Yes' : 'No',
      isSki: this.quoteDetail.isSking === true ? 'Yes' : 'No',
      policyExcess: this.quoteDetail.premiums.excess,
      cruiseExpense: this.quoteDetail.premiums.cruise,
      skiExpense: this.quoteDetail.premiums.skiing,
      promoCodeDisc: this.quoteDetail.premiums.promoCodeDiscount,
      memberDisc: this.quoteDetail.premiums.membershipDiscount,
      additionalMedicalExpense: this.quoteDetail.premiums.medical,
      gst: this.quoteDetail.premiums.gst,
      stampDuty: this.quoteDetail.premiums.stampDuty,
      totalExpense: this.quoteDetail.premiums.totalCost,
    };
  }

  get countryNames(): string {
    return this.quoteEnquiry.travelDestination
      .map((country: any) => country.countryName)
      .join(', ');
  }

  calculateDaysBetweenDates() {
    const depDate = moment(this.quoteEnquiry.return);
    const rtnDate = moment(this.quoteEnquiry.depart);
    return depDate.diff(rtnDate, 'days');
  }

  displayTravellersAge() {
    if (this.quoteEnquiry.age2?.length > 0) {
      return this.quoteEnquiry.age1 + ',' + this.quoteEnquiry.age2;
    } else {
      return this.quoteEnquiry.age1;
    }
  }
  displayTravelersName() {
    if (this.quoteDetail?.travelers && this.quoteDetail.travelers.length > 0) {
      const travelerNames: string[] = [];
      const dependentNames: string[] = [];
      this.quoteDetail?.travelers.forEach((element: any) => {
        if (element.role != 'dependent') {
          travelerNames.push(`${element.firstName} ${element.lastName}`);
        } else {
          dependentNames.push(`${element.firstName} ${element.lastName}`);
        }
      });
      const names = `${travelerNames.join(', ')}\n${dependentNames.join(', ')}`;
      return names;
    }
    return '';
  }
}
